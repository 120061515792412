import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import "./ExploreWrapper.css"
import ProviderCards from "../cards/providerCards"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useSearchSourcesQuery } from "../../features/featSeekerDashboard/seekerDashboardApi"
import { useSearchParams } from "react-router-dom"
import BannerSearchSection from "../pages/LandingPage/bannerSearchSection/BannerSearchSection"
import { FilterButtonsCard } from "./FilterButtonsCard"
import {
  AnimatePresence,
  motion,
  useAnimate,
} from "framer-motion"
import { IoArrowUp } from "react-icons/io5"
import ExplorePageSkeleton from "../skeletonLoaders/ExplorePageSkeleton"
import { InitialFilterState } from "../../constants"
import { FilterOptions } from "../../Types"
import NoResultsFound from "../cards/commonCard/NoResultsFound/NoResultsFound"
import { locationState, re_setSearchText } from "../../features/featSearchAdvts/SearchAdvtSlice"
import { RootState } from "../../app/store"
import { clearData, currentSearchData } from "../../features/search/searchSlice"
export interface searchParamsType {
  Location: string
  VedicSubject: string
  teacherType: string
  sortBy: string
  sortOrder: string
  pageNo: string
  modeOfClass: string
}

const ExploreWrapper: React.FC = () => {
  const [filterOptions, setFilters] =
    useState<FilterOptions>(InitialFilterState)
  const [searchParams, setSearchParams] = useSearchParams()
  const targetRef = useRef<HTMLDivElement>(null)
  const [scoop] = useAnimate()
  const [isIntersecting, setIntersecting] = useState<boolean>(false)
  const [isFetching, setFetching] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | any | null>(null)
  // const [dataItems, setDataItems] = useState<Object[]>([])
  const { data, error, isSuccess, isLoading } = useSearchSourcesQuery(
    Object.fromEntries([...searchParams]),
    { refetchOnMountOrArgChange: true },
  )
  const { selectedLocation} = useAppSelector(locationState)
  const { data: dataItems } = useAppSelector(currentSearchData) // Access data and pageData from Redux
  const dispatch = useAppDispatch()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [pageData, setPageData] = useState<{
    currentPage: number
    totalPages: number
  }>({ currentPage: 1, totalPages: 1 })

  const sub = searchParams.get("vedicSubject")
  const loc = searchParams.get("aroundMe")
  const moc = searchParams.get("classType")
  const priceRange = searchParams.get("priceRange")
  const batch = searchParams.get("groupOrPrivate")
  const sortOrder = searchParams.get("sortOrder")
  const firstClassFree = searchParams.get('firstClassFree')
  const sortBy = searchParams.get("sortBy")

  const scrollToResults = async () => {
    scoop.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    // setDataItems([])
    dispatch(clearData(Object.fromEntries([...searchParams])));
    setPageData({ currentPage: 0, totalPages: data?.totalPages || 1 })
    if (sub)
      setSearchParams(() => {
        setFilters(InitialFilterState)
        const newSearchParams = new URLSearchParams()
        if(loc)newSearchParams.set("aroundMe", loc)
        if(moc && selectedLocation && selectedLocation==='Online') newSearchParams.set("classType", moc)
        newSearchParams.set("vedicSubject", sub)
        return newSearchParams
      })
    scrollToResults()
  }, [sub])

  useEffect(() => {
    // setDataItems([])
    dispatch(clearData(Object.fromEntries([...searchParams])));
    setPageData({ currentPage: 0, totalPages: data?.totalPages || 1 })
    scrollToResults()
  }, [loc, moc, priceRange, batch, sortOrder, firstClassFree, sortBy])

  useEffect(() => {
    if (error) {
      if ((error as any)?.data?.data?.length > 0) {
        // setDataItems((error as any).data.data);
        setErrorMessage(<NoResultsFound handleReset={handleReset} />);
      } else {
        setErrorMessage((error as any)?.data?.message);
      }
      setFetching(false);
    } else if (data) {
      setFetching(true);
      setErrorMessage(null);
      setPageData({
        currentPage: data?.currentPage,
        totalPages: data?.totalPages,
      });
      if (data.data) {
        // setDataItems((prev) => {
        //   if (prev.length > 0) {
        //     const newData = data.data;
        //     return [...prev, ...newData];
        //   } else {
        //     if (pageData.currentPage === 1) scrollToResults();
        //     const newData = data.data;
        //     return newData;
        //   }
        // });
      }
      setFetching(false);
    } else {
      setErrorMessage(null);
    }
  }, [error, data]);
  

  const setCurrentPage = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const currentPage = pageData.currentPage
    const newPage = currentPage + 1
    if (newPage <= data?.totalPages) {
      setFetching(true)
      searchParams.set("pageNo", newPage.toString())
      setSearchParams(searchParams)
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  let debounceTimer: any
  const handleScroll = () => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setCurrentPage()
    }, 200)
  }

  useEffect(() => {
    if (isIntersecting && !isLoading) {
      handleScroll()
    }
  }, [isIntersecting, isLoading])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        root: null, // viewport
        rootMargin: "0px", // no margin
        threshold: 0.3, // 10% of the target is visible
      },
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined
    searchParams.set("pageNo", "1")

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsVisible(scrollTop > 550)

      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setIsVisible(false)
      }, 1400) // Adjust the timeout duration as needed
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      clearTimeout(timeoutId)
      dispatch(re_setSearchText())
    }
  }, [])

  const handleReset = () => {
    const resetParam = new URLSearchParams()
    resetParam.set("pageNo", "1")
    setSearchParams(resetParam)
    setFilters(InitialFilterState)
  }

  return (
    <>
      <section className="exploreMoreSection pt-100">
        <div className={`explore-wrapper`}>
          <Container className="cards-container">
            <div className="searchbannersection">
              <BannerSearchSection />
            </div>
            <section
              id="heading-explore"
              ref={scoop}
              className="d-lg-flex justify-content-between align-items-start mb-lg-0 mb-3"
            >
              <div className="heading-explore-main">
                <h2 className="heading-explore user-select-none">
                  Your <span>best</span> sources are right here...   
                </h2>
              </div>
            </section>
            <FilterButtonsCard
              filterOptions={filterOptions}
              setFilters={setFilters}
            />
            {errorMessage && (
              <div className="w-100 d-inline-flex flex-column justify-content-center align-items-center">
                <div className={` pb-2 `}>
                  {errorMessage}
                  {/* <span className="refresh-link " onClick={handleReset}>retry</span> */}
                </div>
                <div className="ms-4 ms-lg-0 similarTextStart mb-2 w-100 heading-explore user-select-none ">
                  Here are some Similar <span className="d-block d-sm-inline"> Results</span>
                </div>
              </div>
            )}
              <AnimatePresence mode="sync">
            <div className="cards-wrapper">
                <ProviderCards
                  data={dataItems}
                  modeOfClass={moc}
                  batchType={batch}
                />
                {/* {(isLoading || isFetching) && !error && <ExplorePageSkeleton />} */}
            </div>
            <div className="cards-wrapper mb-4" ref={targetRef}>
                {!error && !(data?.currentPage === data?.totalPages) && 
                <ExplorePageSkeleton />
                }
                </div>
              </AnimatePresence>
          </Container>
        </div>
        {/* <div
          ref={targetRef}
          className="d-inline-flex w-100 justify-content-center align-items-center h-15vh"
        /> */}
        {(data?.currentPage === data?.totalPages && dataItems?.length>0 ) && (
          <motion.div
            initial={{ opacity: 1 }}
            whileInView={{ display: "none", opacity: 0 }}
            transition={{ duration: 0.4, delay: 2 }}
            className="w-100 d-inline-flex justify-content-center align-items-center reached-btn mb-3"
          >
            You have reached the end of the page
          </motion.div>
        )}
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.1, easing: "easeOut", delay: 0.3 }}
          onClick={scrollToTop}
          className="scroll-button d-flex ratio-1x1 rounded-circle position-fixed bottom-0 end-0 me-4 mb-4"
        >
          <IoArrowUp />
        </motion.button>
      </section>
    </>
  )
}

export default ExploreWrapper
