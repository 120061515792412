export function extractFilename(url: string, uid: string) {
    // Find the position of the UID in the URL
    const uidIndex = url.indexOf(uid);
    if (uidIndex === -1) {
      throw new Error("UID not found in the URL");
    }
    
    // Extract the part of the URL after the UID
    const urlAfterUid = url.substring(uidIndex + uid.length);
    
    // Find the position of the last '/' after the UID
    const lastSlashIndex = urlAfterUid.lastIndexOf('/');
    
    // Extract the filename
    const filename = urlAfterUid.substring(lastSlashIndex + 1);
    
    return decodeURIComponent(filename);
  }
  