import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
import { createSelector } from "@reduxjs/toolkit"
import { APP_ENV } from "../../constants"
const BASE_URL = APP_ENV === 'development'
  ? import.meta.env.VITE_REACT_Dev_API_URL
  : ['testing'].includes(APP_ENV as string)
  ? import.meta.env.VITE_REACT_TEST_API_URL
  : import.meta.env.VITE_REACT_APP_API_URL;

export const providerDashboardApi = createApi({
  reducerPath: "providerDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    "providerAdvertisement",
    "providerRequests",
    "providerAccountDetails",
    "getPaymentsInvoices",
    "tutorReviews",
    "providerFAQS",
  ],
  endpoints: (builder) => ({
    providerlogin: builder.mutation<any, any>({
      query: (credentials: any) => ({
        url: "provider/login",
        method: "POST",
        body: credentials,
      }),
      onQueryStarted(arg, { dispatch }) {
        dispatch(
          providerDashboardApi.util.invalidateTags([
            "providerAdvertisement",
            "getPaymentsInvoices",
            "providerRequests",
            "providerAccountDetails",
          ]),
        )
      },
    }),
    getPaymentsInvoices: builder.query({
      query: (args: { pageNo: number; pageLimit?: number }) => ({
        url: `seeker/payment/get-payment?pageNo=${args.pageNo}&pageLimit=${args.pageLimit}`,
        params: args,
      }),
      providesTags: ["getPaymentsInvoices"],
    }),
    providerAccountDetails: builder.query({
      query: (args: null) => ({

        url: "/provider/get-provider-account-details",
        method: "GET",
      }),
      providesTags: ["providerAccountDetails"],
    }),
    providerAdvertisement: builder.query({
      query: (args: any) => ({
        url: `/provider/get-provider-ad${args?.adId ? `?adId=${args.adId}` : ''}`,
        method: "Get",
      }),
      providesTags: ["providerAdvertisement"],
      invalidatesTags: ["providerlogin"] as any,
    }),
    providerRequests: builder.query({
      query: (args: void) => ({
        url: "/provider/get-provider-requests",
        method: "GET",
      }),
      providesTags: ["providerRequests"],
    }),
    providerRecievedReviews: builder.query({
      query: (args: void) => "/provider/get-provider-reviews",
      providesTags: ["providerAdvertisement", 'tutorReviews'],
    }),
    providerRequestSorting: builder.mutation<any, any>({
      query: (sort) => ({
        url: `/provider/sort-requests?sortOrder=${sort}`,
      }),
    }),
    updateAccount: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/update-provider-account-details`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["providerAccountDetails", "providerAdvertisement"],
    }),
    changePassword: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/change-pass-provider-account`,
        method: "POST",
        body: data,
      }),
    }),
    deleteAccount: builder.mutation({
      query: (args: void) => ({
        url: `provider/delete-account`,
        method: "POST",
      }),
    }),
    sendReviewReply: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/send-review-reply`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['tutorReviews']
    }),
    addDeatils: builder.mutation<any, any>({
      query: (adddeatils) => ({
        url: `provider/create-update-ad`,
        method: "POST",
        body: adddeatils,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    updateAdvertisement: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/create-update-ad?adId=${data.id}`,
        method: "POST",
        body: data.servicedata,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            providerDashboardApi.util.invalidateTags(['providerAdvertisement'])
          );
        } catch (error) {
          console.error('Failed to update advertisement:', error);
        }
      },
    }),
    acceptDeleteRequest: builder.mutation<any, any>({
      query: (action) => ({
        url: `provider/accept-delete-request`,
        method: "POST",
        body: action,
      }),
      invalidatesTags: ["providerRequests"],
    }),
    providerSearch: builder.mutation<any, any>({
      query: (data) => ({
        url: `/provider/search-sort-filter-requests?searchOption=${data.search}&requestStatus=${data.status}&sortByDate=${data.sort}`,
      }),
      transformResponse: (rawData) =>
        transformProviderSearchRequestResponse(rawData),
    }),
    getRequests: builder.query<any, any>({
      query: (data) => ({
        url: `/provider/search-sort-filter-requests?sortByDate=${data.sort || -1}${data.requestStatus ? `&requestStatus=${data.requestStatus}` : ''}`,
        method: "GET",
        // searchOption=${data.search}&requestStatus=${data.status}&
      }),
      transformResponse: (rawData) =>
        transformProviderSearchRequestResponse(rawData),
    }),
    // chat Application
    chatWrite: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/chat-write`,
        method: "POST",
        body: data,
      }),
    }),
    uploadProfilepic: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/upload-profile-pic`,
        method: "POST",
        body: data,
      }),
      onQueryStarted(arg, { dispatch }) {
        dispatch(
          providerDashboardApi.util.invalidateTags([
            'providerAccountDetails'
          ]))
      },
      invalidatesTags: ["providerAccountDetails"],
    }),
    uploadAdProfileDetails: builder.mutation<any, any>({
      query: ({ id, adData }) => ({
        url: `provider/upload-ad-media?adId=${id}`,
        method: "POST",
        body: adData,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    uploadProfileDocuments: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/upload-docs?${data.delKey || ''}`,
        method: "POST",
        body: data?.data,
      }),
      invalidatesTags: ["providerAccountDetails"],
    }),
    updateAdStatus: builder.mutation<any, any>({
      query: (id) => ({
        url: `provider/update-ad-status`,
        method: "POST",
        body: id,
      }),

      invalidatesTags: ["providerAdvertisement"],
    }),
    DeleteAd: builder.mutation<any, any>({
      query: (id) => ({
        url: `provider/delete-advertisement`,
        method: "delete",
        body: id,
      }),
      invalidatesTags: ["providerAdvertisement"],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            providerDashboardApi.util.invalidateTags(['providerAdvertisement'])
          );
        } catch (error) {
          console.error('Failed to delete advertisement:', (error as any).error.status);
          if ((error as any).error.status === 400) {
            dispatch(
              providerDashboardApi.util.invalidateTags(['providerAdvertisement'])
            );
          }
        }
      },
    }),
    AskFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/ask-faq`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['providerFAQS']
    }),
    getFAQForProvider: builder.query<any, any>({
      query: (data) => ({
        url: `provider/get-ad-faq's?adId=${data?.adId}${data?.page ? '&pageNo=' + data?.page : ''}&pageLimit=${data?.pageLimit || '10'}`,
        method: "GET",
        providesTags: ['providerFAQS']
      }),
    }),
    ReplyFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/reply-update-ad-faq's`,
        method: "POST",
        body: data,
      }),
    }),
    AcceptRejectRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/accept-delete-request`,
        method: "POST",
        body: data,
      }),
    }),
    getRejectedRequests: builder.query({
      query: (args: void) => ({
        url: "provider/get-rejected-requests",
        method: "GET",
      }),
    }),
    getProviderStatus: builder.query({
      query: (args: void) => ({
        url: "provider/get-exp-status",
        method: "GET",
      }),
    }),
    updateProviderStatus: builder.query({
      query: (args: void) => ({
        url: "provider/update-exp-status",
        method: "GET",
      }),
    }),
    providerPhoneVerification: builder.mutation({
      query: (args: void) => ({
        url: "provider/send-mobile-otp",
        method: "GET",
      }),
    }),

    providerVerifyPhoneOtp: builder.mutation({
      query: (data) => ({
        url: "provider/verify-mobile",
        method: "POST",
        body: data,
      }),
    }),
    getLocationSuggestion: builder.mutation({
      query: (search: any) => ({
        url: `static-data/get-location-suggestion?search=${search.value}`,
        method: "GET",
      }),
    }),
    providerTermsAndCondition: builder.mutation({
      query: (arg: any) => ({
        url: '/provider/accept-terms',
        method: "POST",
        body: arg,
      }),
      invalidatesTags: ["providerAccountDetails"],
    }),
    providerReportChat: builder.mutation({
      query: (arg: any) => ({
        url: '/provider/report-chat',
        method: "POST",
        body: arg,
      }),
    }),
    autoCompliteWithAI: builder.mutation({
      query: (arg: { vedicSubject: string; subSkill: string; section: string }) => ({
        url: '/static-data/autocomplete-with-google',
        method: 'POST',
        body: arg,
      }),
      transformResponse: (rawData: any) => transformAutofillResponse(rawData),
      transformErrorResponse: (rawData: any) => {
        if (rawData) {
          console.log(rawData);
          if (rawData?.originalStatus === 504) return { status: 504, data: { message: "Error timeout" } }
          return rawData
        }
        return null
      }
    }),
    removeProfileImageProvider: builder.mutation<any, void>({
      query: () => ({
        url: '/provider/remove-profile-pic',
        method: 'DELETE',
      }),
      invalidatesTags: ['providerAccountDetails'],
    })
  }),
})

export const {
  useProviderloginMutation,
  useGetPaymentsInvoicesQuery,
  useProviderAdvertisementQuery,
  useProviderRequestsQuery,
  useProviderRecievedReviewsQuery,
  useProviderRequestSortingMutation,
  useUpdateAccountMutation,
  useProviderAccountDetailsQuery,
  useChangePasswordMutation,
  useSendReviewReplyMutation,
  useUpdateAdvertisementMutation,
  useAcceptDeleteRequestMutation,
  useProviderSearchMutation,
  useGetRequestsQuery,
  useChatWriteMutation,
  useDeleteAccountMutation,
  useUploadProfilepicMutation,
  useUploadAdProfileDetailsMutation,
  useUploadProfileDocumentsMutation,
  useUpdateAdStatusMutation,
  useDeleteAdMutation,
  useAskFAQMutation,
  useGetFAQForProviderQuery,
  useReplyFAQMutation,
  useAcceptRejectRequestMutation,
  useGetRejectedRequestsQuery,
  useGetProviderStatusQuery,
  useProviderPhoneVerificationMutation,
  useProviderVerifyPhoneOtpMutation,
  useUpdateProviderStatusQuery,
  useAddDeatilsMutation,
  useGetLocationSuggestionMutation,
  useProviderTermsAndConditionMutation,
  useProviderReportChatMutation,
  useAutoCompliteWithAIMutation,
  useRemoveProfileImageProviderMutation
} = providerDashboardApi

const transformProviderSearchRequestResponse = (rawData: any) => {
  if (!rawData || !rawData.data) {
    return { data: [] }
  }
  return {
    message: rawData.message,
    totalRequests: rawData.totalRequests,
    data: rawData.data.map((item: any) => ({
      _id: item._id,
      date: item.updatedAt,

      adId: {
        _id: item.adId,
        vedicSubject: item.adData[0]?.vedicSubject || null,
      },
      sessionPreference: item.sessionPreference,
      requestMessage: item.requestMessage,
      requestStatus: item.requestStatus,
      updatedAt: item.updatedAt,
      seekerId: {
        firstName: item.seekerData[0]?.firstName || null,
        lastName: item.seekerData[0]?.lastName || null,
        profilePic: item.seekerData[0]?.profilePic || null,
      },
    })),
  }
}

const transformAutofillResponse = (response: { success: boolean; message: string; data: string }) => {
  try {
    // Parse the JSON string to an array
    const jsonData: any = JSON.parse(response.data);
    const dataArray: any[] = jsonData?.options

    // Ensure that dataArray is an array
    if (!Array.isArray(dataArray)) {
      throw new Error('Data is not an array');
    }
    // Extract only string values from the array
    const stringData = dataArray.reduce<string[]>((acc, item) => {
      if (typeof item === 'string') {
        acc.push(item);
      } else if (typeof item === 'object') {
        // If the item is an object, extract string values from it
        for (const key in item) {
          if (typeof item[key] === 'string') {
            acc.push(item[key]);
          }
        }
      }
      return acc;
    }, []);

    return {
      success: response.success,
      message: response.message,
      data: stringData,
    };
  } catch (error) {
    // Handle JSON parsing or processing error if needed
    console.error('Failed to parse or process JSON:', error);
    return {
      success: response.success,
      message: response.message,
      data: [],
    };
  }
};
