import { useEffect, useRef, useState } from "react"
import { Modal, Button, Form, Spinner } from "react-bootstrap"
import mailicon from "../../../assets/img/mail-icon.svg"
import passwordicon from "../../../../assets/img/password-icon.svg"
import facebookimage from "../../../assets/img/facebook-icon.png"
import googleimage from "../../../assets/img/google-icon.png"
import backicon from "../../../assets/img/back-icon.svg"
import keyicon from "../../../../assets/img/key-icon.svg"
import "../authComponent.css"
import { useAppSelector } from "../../../../app/hooks"
import { toast } from "react-toastify"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import {
  useForgetPasswordMutation,
  useProviderforgetPasswordMutation,
  useProviderresetPasswordMutation,
  useProviderresetPasswordOtpVerifyMutation,
  useResendOtpMutation,
  useResendOtpProviderMutation,
  useSeekerresetPasswordOtpVerifyMutation,
} from "../../../../features/Auth/authApi"
import {
  updateProviderToken,
  updateSeekerToken,
} from "../../../../features/Auth/authSlice"
import FormInput from "../../../form/FormInput"
import { useResetPasswordMutation } from "../../../../features/Auth/authApi"
import { useAppDispatch } from "../../../../app/store"
import { MODALNAME } from "../../../../constants"
import {
  closeModal,
  setModalName,
} from "../../../../features/Auth/ModalHandler/modalSlice"
import { useNavigate } from "react-router-dom"
import { resetPasswordSchema } from "../../../../utils/yupSchema/Schema"
import { maskCredential } from "../../../../utils/commonFunctions/CommonFunctions"
interface CardProps {}
interface ResettUser {
  password: string
  confirmPassword: string
}
const Reset = ({}: CardProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [otperror, setOtperror] = useState(null)
  const [expired, setExpired] = useState(false)
  const [forgetPassEmail, setForgetPassEmail] = useState<string>("")
  const [currentTime, setCurrentTime] = useState(0)
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const auth = useAppSelector((state: any) => state.auth)
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const inputRefs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ]

  // provider reset password

  const [resetPassword, { isError, error: tutorResetError, data }] =
    useProviderresetPasswordMutation()

  // provider otp veryfy
  const [
    resetPasswordOtpVerify,
    {
      error: resetPasswordOtpVerifyError,
      data: resetPasswordOtpVerifyData,
      isSuccess: resetPasswordOtpVerifySuccess,
      isLoading: providerOtpisLoading,
    },
  ] = useProviderresetPasswordOtpVerifyMutation()

  // seeker reset password

  const [
    resetSeekerPassword,
    { isError: seekerisError, error: seekerError, data: seekerData },
  ] = useResetPasswordMutation()

  // seeker otp verify
  const [
    resetSeekerPasswordOtpVerify,
    {
      isError: resetSeekerPasswordOtpVerifyIsError,
      error: resetSeekerPasswordOtpVerifyError,
      isSuccess: resetSeekerPasswordOtpVerifySuccess,
      data: resetSeekerPasswordOtpVerifyData,
      isLoading: seekerOtpisLoading,
    },
  ] = useSeekerresetPasswordOtpVerifyMutation()

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<ResettUser>({
    resolver: resetPasswordSchema,
  })
  const password = watch("password")
  const confirmPassword = watch("confirmPassword")
  const onSubmit = async (values: ResettUser) => {
    if (modalName === MODALNAME.RESET_PASSWORD_TUTOR) {
      await resetPassword({
        token: resetPasswordOtpVerifyData?.token,
        password: {
          password: values.password,
        },
      })
    } else {
      await resetSeekerPassword({
        token: resetSeekerPasswordOtpVerifyData?.token,
        password: {
          password: values.password,
        },
      })
    }
  }
  const OtpSubmit = async () => {
    const payload = {
      email: auth?.forgotPassEmail, //need to confirm the key for it 
      otp: otp.join(""),
    }
    try {
      if (modalName === MODALNAME.RESET_PASSWORD_OTP_PROVIDER) {
        await resetPasswordOtpVerify(payload)
      } else {
        await resetSeekerPasswordOtpVerify(payload)
      }
    } catch (error) {
      console.error(error)
    }
  }
  // otp input change
  const handleInputChange = (index: number, event: any) => {
    const value = event.target.value
    setOtperror(null)
    if (isNaN(Number(value))) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, event: any) => {
    if (event.key === "Enter") {
      OtpSubmit()
    }
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current?.focus()
    }
  }
  const handleClosemodal = () => {
    setOtperror(null)
    setOtp(["", "", "", "", "", ""])
    dispatch(closeModal())
    setCurrentTime(0)
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`
  }
  const [
    forgetEmail,
    { isLoading: loading, error: resendError, isSuccess: resendsuccess },
  ] = useForgetPasswordMutation()

  const [
    forgetProviderEmail,
    {
      isLoading: resendOTPproviderloading,
      error: resendOTPproviderError,
      isSuccess: resendOTPproviderSuccess,
    },
  ] = useProviderforgetPasswordMutation()
  const handleResendOtp = () => {
    // dispatch(
    //   userType == USERTYPE.SEEKER
    //     ? setModalName(MODALNAME.FORGET_PASSWORD_SEEKER)
    //     : setModalName(MODALNAME.FORGET_PASSWORD_TUTOR),
    // )
    setOtp(["", "", "", "", "", ""])
    if (modalName === MODALNAME.RESET_PASSWORD_OTP_PROVIDER) {
      forgetProviderEmail({ email: auth?.forgotPassEmail || auth?.user?.data?.email })
    } else {
      forgetEmail({ email: auth?.forgotPassEmail || auth?.user?.data?.email })
    }
  }

  useEffect(() => {
    if (resetPasswordOtpVerifyError || resetSeekerPasswordOtpVerifyError) {
      setOtperror(
        (resetSeekerPasswordOtpVerifyError as any)?.data?.message ||
        `${(resetPasswordOtpVerifyError as any)?.data?.message.split(' ')[0].toString()}   ${ (resetPasswordOtpVerifyError as any)?.data?.message.split(' ')[1].toString().toUpperCase()}`,
      )
    }
    if (auth?.forgotPassEmail) {
      setForgetPassEmail(auth?.forgotPassEmail)
    }
    if(auth?.user?.data?.email){
      setForgetPassEmail(auth?.user?.data?.email)
    }
  }, [
    resetSeekerPasswordOtpVerifyError,
    resetPasswordOtpVerifyError,
    auth,
  ])
  useEffect(() => {
    if (data || seekerData) {
      if(auth.providerToken || auth.seekerToken) handleClosemodal()
      else dispatch(setModalName(MODALNAME.LOGIN)) // make it optional
      reset()
      toast.success("Password Reset Successfully")
    }
    if(tutorResetError || seekerError) toast.error((tutorResetError as any)?.data?.message || (seekerError as any)?.data?.message)
  }, [data, seekerData, tutorResetError, seekerError])

  useEffect(() => {
    if (
      modalName === MODALNAME.RESET_PASSWORD_OTP_PROVIDER ||
      modalName === MODALNAME.RESET_PASSWORD_OTP_SEEKER ||
      resendOTPproviderSuccess ||
      resendsuccess
    ) {
      setCurrentTime(90) // set timer to 90 seconds
      reset()
      const timerId = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerId)
            setExpired(true)
            return 0
          } else {
            return prevTime - 1
          }
        })
      }, 1000)

      return () => clearInterval(timerId)
    } else {
      setCurrentTime(0)
    }
  }, [modalName, resendsuccess, resendOTPproviderSuccess])

  useEffect(() => {
    if ( resetPasswordOtpVerifyData ) {
      dispatch(setModalName(MODALNAME.RESET_PASSWORD_TUTOR))
      reset()
      setOtp(["", "", "", "", "", ""])
      toast.success(resetPasswordOtpVerifyData?.message)
    } else if ( resetSeekerPasswordOtpVerifyData ) {
      dispatch(setModalName(MODALNAME.RESET_PASSWORD_SEEKER))
      reset()
      setOtp(["", "", "", "", "", ""])
      toast.success(resetSeekerPasswordOtpVerifyData?.message)
    }
    else if(resendError || resendOTPproviderError) toast.error((resendError as any)?.data?.message || (resendOTPproviderError as any)?.data?.message)
  }, [resetPasswordOtpVerifyData, resetSeekerPasswordOtpVerifyData, resendError, resendOTPproviderError])

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text').trim();
    // Ceck for numeric OTP 
    if(!(isNaN(parseInt(pastedData)))) {
      const newOtp = pastedData.split('').slice(0, otp.length); 
      setOtp(newOtp);
      inputRefs[5]?.current?.focus();
    }
  };
  return (
    <>
    {/* This modal here is to reset password after user enters the otp from generated forgot password*/}
      <Modal
        centered
        animation
        show={
          modalName === MODALNAME.RESET_PASSWORD_TUTOR ||
          modalName === MODALNAME.RESET_PASSWORD_SEEKER 
        }
        className="login-modal"
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0">
              <Modal.Title
                className="popup-heading d-flex"
                id="exampleModalToggleLabel"
              >
                Reset Password
              </Modal.Title>
              <Button
                variant="secondary"
                className="btn-close"
                onClick={handleClosemodal}
              ></Button>
            </Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <p className="text-common mb-3 primary-text">Create new password</p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <>
                  <div className="mb-3">
                  <div className="popup-field-wrapper reset-form-box">
                    <img src={passwordicon} alt="Password Icon" />
                    <FormInput
                      name="password"
                      type="password"
                      placeholder="Password"
                      register={register}
                      className={`reset-password`}
                      watch={password}
                    />
                  </div>
                  <span className="text-danger ps-1">
                    {errors?.password?.message}
                  </span>
                  </div>
                  <div className="mb-3">
                  <div className="popup-field-wrapper reset-form-box">
                    <img src={passwordicon} alt="Password Icon" />

                    <FormInput
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      register={register}
                      className="reset-password"
                      watch={confirmPassword}
                    />
                  </div>
                    <span className="text-danger ps-1">
                      {errors?.confirmPassword?.message}
                    </span>
                    </div>
                  <Button type="submit" className="common-btn auth-btn my-4">
                    Submit
                  </Button>
                </>
              </Form>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>

{/* This modal bellow here is for otp varification while reseting password by forget password */}
      <Modal
        show={
          modalName === MODALNAME.RESET_PASSWORD_OTP_PROVIDER ||
          modalName === MODALNAME.RESET_PASSWORD_OTP_SEEKER
        }
      >
        <Modal.Header className="popup-header border-0 text-center ">
          <Modal.Title
            className="popup-heading d-flex "
            id="exampleModalToggleLabel"
          >
            Reset Password
          </Modal.Title>
          <Button
            variant="secondary"
            className="btn-close"
            onClick={handleClosemodal}
          ></Button>
        </Modal.Header>
        <Modal.Body className="popup-body login-tab-wrapper border-0">
          <p className="get-started-text text-center font-14 fw-bold mb-3">
            {`Enter the 6 digit OTP sent to ${auth?.forgotPassEmail ? maskCredential(auth?.forgotPassEmail): 'you'}`}
          </p>

          <>
            <div className="input-field mb-3 d-flex align-items-center justify-content-center ">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(event) => handleInputChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                  ref={inputRefs[index] as any}
                  className="ms-1 ms-md-3"
                  onPaste={(event) => handlePaste(event)}
                />
              ))}
            </div>
            {(resetSeekerPasswordOtpVerifyError ||
              resetPasswordOtpVerifyError) && (
              <span className="error mb-2 ms-3 ps-2 d-flex align-items-center justify-content-center text-danger font-14">
                {otp.join("").length == 6 && otperror}
              </span>
            )}
            <div className="d-flex flex-column gap-3 align-items-center mb-4 ">
              <p className="fw-medium mb-0 font-16">
                {(modalName !== MODALNAME.RESET_PASSWORD_TUTOR ||
                  modalName !== MODALNAME.RESET_PASSWORD_SEEKER) &&
                currentTime === 0 ? (
                  <span className="text-danger">
                    Time's up! Click Resend for new OTP
                  </span>
                ) : (
                  `Time remaining: ${formatTime(currentTime)}`
                )}
              </p>
              {currentTime === 0 &&
                (<p className="resend-otp mb-0 font=16 w-100" onClick={handleResendOtp}>
                  {currentTime === 0 && "Resend OTP"}{" "}
                  {(loading || resendOTPproviderloading) && (
                    <span>
                      <Spinner className="spinner-md" />
                    </span>
                  )}
                </p>)}
            </div>

            <Button 
              type="submit"
              className="common-btn auth-btn mb-4"
              onClick={OtpSubmit}
              disabled={otp.join("").length !== 6}
            >
              Submit
            </Button>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Reset
