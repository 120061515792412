import { useOutletContext, useSearchParams } from "react-router-dom";
import { ItemWithISODate, chatProps, dashProps } from "../../Types";
import { useState, useEffect } from 'react';
import { DeviceType } from "../../constants";

export function useUserData() {
    return useOutletContext<dashProps>()
  }


export function useChatData() {
  return useOutletContext<chatProps>()
} 


export function sortArrayByISODate(items: ItemWithISODate[], status:string): ItemWithISODate[] {
  const sorted = [...items].sort((a, b) => {
    // Prioritize 'chatLock' status
    if (b.chatLock === a.chatLock || status === "Accept" ) {
      // If both items have the same 'chatLock' status, sort by time
      return new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime();
    } else {
      // If 'chatLock' status differs, 'true' should come before 'false'
      return !!b.chatLock  ? 1 : -1;
    }
  });
  // const sorted = [...items].sort((a, b) => new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime());
  const uniqueItemsMap = new Map<string, ItemWithISODate>();
  sorted.forEach((item:ItemWithISODate) => {
    uniqueItemsMap.set(item.requestId, item);
  });
  return Array.from(uniqueItemsMap.values());
}  // Create a new array for sorting to avoid mutating the original array


export const useWindowWidth = (): number => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

export function maskCredential(credentialInput: string | number | undefined): string {
  if (credentialInput) {
    const credential = credentialInput.toString();
    const length = credential.length;

    if (credential.includes('@')) {
      const atIndex = credential.indexOf('@');
      const partBeforeAt = credential.slice(0, atIndex);
      const partAfterAt = credential.slice(atIndex);

      // Calculate lengths
      const keepLength = Math.ceil(partBeforeAt.length * 0.3);
      const maskLength = partBeforeAt.length - keepLength;

      // Determine the parts
      const keptPart = partBeforeAt.slice(-keepLength);
      const maskedPart = 'x'.repeat(maskLength);

      return maskedPart + keptPart + partAfterAt;
    } else {
      // Calculate lengths
      const keepLength = Math.ceil(length * 0.3);
      const maskLength = length - keepLength;

      // Determine the parts
      const keptPart = credential.slice(-keepLength);
      const maskedPart = 'x'.repeat(maskLength);

      return maskedPart + keptPart;
    }
  }
  return ' ';
}

export const sanitizePhoneNumber = (phoneNumber?: string): string => {
  if (phoneNumber) {
    if (phoneNumber?.startsWith('+')) {
      return phoneNumber.slice(1);
    }
    return phoneNumber;
  }
  return ''
};

export function detectDeviceType(): DeviceType {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Check if user agent string indicates a mobile device
  if (/Mobile|Android|iP(hone|od|ad)|IEMobile|Opera Mini|BlackBerry/i.test(userAgent)) {
    return DeviceType.Mobile;
  }

  // If it's not a mobile device, assume it's a desktop
  return DeviceType.Desktop;
}

export const adjustHeight = (element: React.ChangeEvent<any>, maxRows: number = 5) => {
  if (element?.target) {
    // Reset height to auto to correctly calculate scrollHeight
    element.target.style.height = 'auto';
    
    // Get the scrollHeight (total height based on content)
    const scrollHeight = element.target.scrollHeight;
    
    // Calculate the maximum height based on the row height and maxRows
    const rowHeight = 24; // Average row height, adjust as needed
    const maxHeight = rowHeight * maxRows;

    // Set the height based on scrollHeight, but limit it to the maxHeight
    element.target.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
  }
};
