import { USERTYPE } from "./constants"

export interface dashProps {
  userType: USERTYPE
  seekerProfileData?: any
  sourceProfileData?: any
  chatUserData?:any
  setSelectedChatData?:React.Dispatch<React.SetStateAction<any>>  
  selectedChatData?:any
  isLoading: boolean
  authUser: any
}

export interface chatProps {
  liveChatData:  ItemWithISODate[]
  pendingChatData: ItemWithISODate[]
}

export type ItemWithISODate = {
  id: string;
  date: string;
  lastMessageTime:string;
  requestId:string
  [key: string]: any;
new?:boolean;
modified?:boolean;
deleted?:boolean
unreadSourceCount?:Array<string>
unreadSeekerCount?:Array<string>
};

export interface FilterOption {
  key: string;
  label: string;
  value: boolean | number | string;
  paramsKey?: string;
}
export interface FilterRange {
  [key: string]: { value: number }
  minimum: { value: number }
  maximum: { value: number }
}

export interface optionsIterface {
  [key: string]: FilterOption
};

export interface FilterOptions {
  [key: string]: {
      order: number
      type: string
      key: string | string []
      label: string
      sortType?: string
      range?: FilterRange
      selectedValue: null | string
      options: {
          [key: string]: FilterOption
      };
      subOptions?: Array<optionsIterface>;
  };
}

export interface youtubevideoState {
  url: string;
  isValid: boolean;
}

export type NotificationTokenType = string | null | false;

export interface NotificationTokenSuccess {
  data: string;
}

export interface NotificationTokenError {
    message?: string;
    data: NotificationTokenType;
}

export type NotificationTokenResponse = NotificationTokenSuccess;

type ProviderData = {
  _id: string;
  firstName: string;
  lastName: string;
  isEmailVerified: boolean;
  isMobileNoVerified: boolean;
  rating: number;
  reviewCount: number;
  isCredsVerified: boolean;
  isPremium: boolean;
  rank: string;
  referralCount: number;
  myReferral: string;
  termsAccepted: boolean;
  isOnline: boolean;
};

type Prices = {
  realTime: {
      group: {
          hourlyFee: number | null;
          firstClassFree: boolean;
      };
      private: {
          hourlyFee: number | null;
          firstClassFree: boolean;
      };
  };
  virtual: {
      group: {
          hourlyFee: number | null;
          firstClassFree: boolean;
      };
      private: {
          hourlyFee: number | null;
          firstClassFree: boolean;
      };
  };
};

export type Provider = {
  _id: string;
  services: string[];
  vedicSubject: string;
  skillSet: string[];
  title: string;
  aboutClass: string;
  aboutYou: string;
  phoneNo: string;
  revealPhoneNo: boolean;
  language: string[];
  location: string;
  city: string;
  status: string;
  prices: Prices;
  isFavourite: boolean;
  youtubeLink: string;
  responseTime: string;
  recordedLect: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  profileImage: string;
  providerData: ProviderData;
  avgPrice: number;
  isOnline: boolean;
  isHybrid: boolean;
};

export type SearchResponseType = {
  message: string;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  data: Provider[];
};

export interface SearchRequest {
  teacherType?: string;         // Type of teacher, e.g., "source"
  vedicSubject?: string;        // Specific Vedic subject for filtering
  pageNo?: number;              // Page number for pagination
  sortOrder?: string;           // Sort order, e.g., "asc" or "desc"
  firstClassFree?: string;     // Whether the first class is free
  classType?: string;           // Type of class, e.g., "realTime" or "any"
  groupOrPrivate?: string;      // Class format, e.g., "group" or "private"
  priceRange?: string;          // Price range for filtering results
  aroundMe?: string;          // List of location-based filtering options
  sortBy?: string;               // Sort by field, e.g., "rating", "reviewCount", "avgPrice"
}

export enum serviceTypeValue {
  null = "null",
  Source = "Source",
  Consultance = "Consultance",
  Others = "Others",
}