import React, { useEffect, useRef, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { IoAddOutline } from 'react-icons/io5';
import styles from './MultiStepForm.module.css';
import CroperModal from '../modals/imageCroper/CroperModal';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { useUploadProfilepicMutation } from '../../features/featProviderDashboard/FeatProviderApi';
import { AiOutlineCamera } from 'react-icons/ai';
import { IoIosRemoveCircle } from 'react-icons/io';
import { Spinner } from 'react-bootstrap';
import { generateRandomFileName } from '../../utils/commonFunctions/randomFilenameGenerator';
import { toast } from 'react-toastify';
import { useUpdateSeekerProfileMutation } from '../../features/featSeekerDashboard/seekerDashboardApi';
interface CertificationFile {
  name: string
  file: File
  localOnly: boolean
  url?: string
}
interface FormData {
  image: File | null
  bio: string
  certifications: CertificationFile[]
  socialMedia: {
    twitter: string
    linkedin: string
    github: string
  }
}
const Step1ProfilePicture = ({ onSuccess, isComplete, stepKey }: any) => {
  const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)
  const inputProfilePictureRef = useRef<HTMLInputElement>(null)
  const [profileImage, setProfileImage] = useState<any>(null)
  const [profilePreviewImage, setProfilePreviewImage] = useState<any>(null)
  const [dragActive, setDragActive] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    image: null,
    bio: "",
    certifications: [],
    socialMedia: {
      twitter: "",
      linkedin: "",
      github: "",
    },
  })
  const [mousePosition, setMousePosition] = useState<boolean>(false)
  function handleMouseEnter(): void { setMousePosition(true) }
  function handleMouseLeave(): void { setMousePosition(false) }
  const [updateProfilePic,
    { isLoading: isUploadingPP, error: profilepicError },
  ] = providerToken ? useUploadProfilepicMutation() : useUpdateSeekerProfileMutation();
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setFormData({ ...formData, image: file })
      const reader = new FileReader()
      reader.onloadend = () => {
        // setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
      setProfileImage(file)
    }
  }

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }
  function handleInitProfilePictureAdd(): void {
    if (inputProfilePictureRef?.current) {
      inputProfilePictureRef.current.click()
    }
  }

  function closeCroper() {
    setProfileImage(null)
    if (inputProfilePictureRef.current) {
      inputProfilePictureRef.current.value = '';
    }
  }
  function handleImageUploadClick() {
    const profilePic = new FormData
    toast.dismiss()
    if (formData?.image) profilePic.append("profilePic", formData.image, generateRandomFileName('jpeg'))
    updateProfilePic(profilePic).unwrap()
      .then((result: any) => {
        setProfilePreviewImage(null)
        toast.success(result ? result?.message : "Your picture updates successfully")
        if (onSuccess) onSuccess(2)
      })
      .catch(() => {
        toast.error('Sorry! we are unable to update your profile image this time')
      });
  }

  useEffect(() => {
    if(user?.profilePic) isComplete(stepKey, true)
    else isComplete(stepKey, false)
  },[user])
  return (
    <div className={styles.formStep}>
      <div className={styles.formStep}>
        <div className="d-flex align-items-center">
          <div className="w-50 mx-auto ">
            <div
              className={`${styles.uploadArea} position-relative ${dragActive ? styles.dragActive : ""
                }`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onClick={handleInitProfilePictureAdd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {mousePosition &&
                <>
                  <div className={`${styles.bgOverlay} position-absolute top-0 start-0 h-100 w-100 d-flex justify-content-center align-items-center`}>
                    {(user?.profilePic || profilePreviewImage) ? <FiEdit2 size={48} className=" text-black-50" /> : <IoAddOutline size={48} className=" text-black-50" />}
                  </div>
                </>}
              {profilePreviewImage ?
                (
                  // <div className={styles.uploadContent}>
                  <img
                    className=" object-fit-contain h-100"
                    src={URL.createObjectURL(profilePreviewImage)} />

                ) : user?.profilePic ? (
                  <img
                    className="object-fit-contain h-100"
                    src={user.profilePic}
                    alt={`${user.name}'s profile`}
                  />
                )
                  : (
                    <div className={styles.imagePreviewContainer}>
                      <AiOutlineCamera className={styles.uploadIcon} />

                    </div>
                  )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className={"d-none"}
                ref={inputProfilePictureRef}
              />
            </div>
            {profilePreviewImage ? <button
              type="button"
              onClick={() => {
                setFormData({ ...formData, image: null })
                setProfileImage(null)
                setProfilePreviewImage(null)
              }}
              className={styles.addEditButton}
            >
              <IoIosRemoveCircle />
            </button>
              : <button
                type="button"
                onClick={() => {
                  if (profilePreviewImage) {
                    setFormData({ ...formData, image: null })
                    setProfileImage(null)
                    setProfilePreviewImage(null)
                  }
                  else {
                    handleInitProfilePictureAdd()
                  }
                }}
                className={`${styles.addEditButton}`}
              >
                {user?.profilePic ? <FiEdit2 size={18} /> : <IoAddOutline size={18} />}
              </button>}
          </div>
        </div>
        <h2 className="text-center my-2">
          Upload Your Profile Picture
        </h2>
        <p className={styles.subtitle}>
          Add a profile picture to make your profile stand out
        </p>

        <div className={styles.buttonGroup}>
          <p></p>
          <button
            type="button"
            onClick={() => {
              if (profilePreviewImage) handleImageUploadClick()
              else if (onSuccess) onSuccess(2)
            }
            }
            className={`${styles.nextButton} ${!formData.image ? styles.disabled : ""
              }`}
          > {isUploadingPP && <Spinner size="sm" className="me-2" />}
            {profilePreviewImage ? 'Save & Continue'
              : user?.profilePic ? 'Continue'
                : 'Skip for now'}
          </button>
        </div>
      </div>
      <CroperModal
        showModal={profileImage}
        handleClose={closeCroper}
        image={profileImage}
        setImage={setProfileImage}
        setResults={setProfilePreviewImage}
        extension={"jpeg"}
      />
    </div>
  );
};

export default Step1ProfilePicture;
