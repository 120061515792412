import React, { useEffect, useState } from 'react';
import styles from './MultiStepForm.module.css';
import { adjustHeight } from '../../utils/commonFunctions/CommonFunctions';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { Spinner } from 'react-bootstrap';
import { useUpdateAccountMutation } from '../../features/featProviderDashboard/FeatProviderApi';
import { toast } from 'react-toastify';
import { useUpdateSeekerProfileMutation } from '../../features/featSeekerDashboard/seekerDashboardApi';
interface CertificationFile {
    name: string
    file: File
    localOnly: boolean
    url?: string
}
interface FormData {
    image: File | null
    bio: string
    certifications: CertificationFile[]
    socialMedia: {
        twitter: string
        linkedin: string
        github: string
    }
}
const MAX_BIO_CHAR_LENGTH = 200;
const Step2Bio = ({ onSuccess, isComplete, stepKey }: any) => {
    const { user, providerToken } = useAppSelector(selectCurrentUser)
    const [
        updateAccount,
        { isLoading: formloading, error: bioError },
    ] = providerToken ? useUpdateAccountMutation(): useUpdateSeekerProfileMutation();

    const [formData, setFormData] = useState<FormData>({
        image: null,
        bio: "",
        certifications: [],
        socialMedia: {
            twitter: "",
            linkedin: "",
            github: "",
        },
    })
    const buttonTextFromBio = (user?.bio === formData.bio.trim()) ? 'Continue' : (formData.bio.trim().length > 0) ? 'Save & Continue' : 'Continue';

      const handleBioUpdate = (values: any) => {
        const trimmedValue: string = values.trim()
        const payload = {
          bio: trimmedValue,
        }
        updateAccount(payload).unwrap()
          .then((result: any) => {
            onSuccess(3)
            toast.success("Your bio updated Successfully")
          })
          .catch((err: any) => {
    
          })
    
      }
    const handleNextStepFromBio = () => {
        if (user?.bio === formData.bio.trim()) {
            onSuccess(3);
        } else if (formData.bio.trim().length > 0) {
            handleBioUpdate(formData.bio);
        } else {
            onSuccess(3);
        }
    }
    useEffect(() => {
        if (user?.bio) {
          setFormData((prev) => ({ ...prev, bio: user?.bio }))
          isComplete(stepKey, true)
        }
        else isComplete(stepKey, false)
      }, [user]) 
    return (
        <div className={styles.formStep}>
            <h2>Tell Us About Yourself</h2>
            <p className={styles.subtitle}>
                Write a brief bio to let others know who you are
            </p>

            <div className={styles.bioContainer}>
                <textarea
                    value={formData?.bio}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setFormData({ ...formData, bio: newValue });
                        adjustHeight(e);
                    }}
                    className={styles.textareaInput}
                    placeholder="Share your experience, skills, and what makes you unique..."
                    maxLength={MAX_BIO_CHAR_LENGTH}
                />
                <span className={styles.characterCount}>
                    {formData.bio.length}/{MAX_BIO_CHAR_LENGTH}
                </span>
            </div>

            <div className={styles.buttonGroup}>
                <button
                    type="button"
                    onClick={() => onSuccess(1)}
                    className={styles.backButton}
                >
                    Back
                </button>
                <button
                    type="button"
                    onClick={handleNextStepFromBio}
                    className={styles.nextButton}
                >
                    {formloading && <Spinner size="sm" className="me-2" />}
                    {buttonTextFromBio}
                </button>
            </div>
        </div>
    );
};

export default Step2Bio;
