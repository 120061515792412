import React from 'react';
import styles from './ModernProfileMeter.module.css';
import { useAppDispatch } from '../../app/store';
import { setModalName } from '../../features/Auth/ModalHandler/modalSlice';
import { MODALNAME } from '../../constants';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';

interface ProfileMeterProps {
  percentage: number;
}

const ModernProfileMeter: React.FC<ProfileMeterProps> = ({
  percentage = 65
}) => {
  // Circle calculations
  const radius = 92;
  const strokeWidth = 8;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  const dispatch = useAppDispatch()
  function onCompleteClick() {
    dispatch(setModalName(MODALNAME.COMPLETE_PROFILE))
  }
  const {user} = useAppSelector(selectCurrentUser)
  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.meterWrapper}>
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>Profile Completion</h2>

          <div className={styles.meterContainer}>
            <div className={styles.backgroundGlow} />

            {/* Pulse Rings */}
            <div className={styles.pulseRing} />
            <div className={styles.pulseRing} />
            <div className={styles.pulseRing} />

            <div className={styles.meter}>
              <svg
                height={radius * 2}
                width={radius * 2}
                className={styles.svg}
              >
                <defs>
                  <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#F97316" />
                    <stop offset="100%" stopColor="#FB923C" />
                  </linearGradient>
                </defs>

                <circle
                  className={styles.trackCircle}
                  stroke="#FFF5F5"
                  fill="none"
                  strokeWidth={strokeWidth}
                  r={normalizedRadius}
                  cx={radius}
                  cy={radius}
                />

                <circle
                  className={styles.progressCircle}
                  stroke="url(#progressGradient)"
                  fill="none"
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  strokeDasharray={`${circumference} ${circumference}`}
                  style={{ strokeDashoffset }}
                  r={normalizedRadius}
                  cx={radius}
                  cy={radius}
                />
              </svg>

              <div className={styles.centerContent}>
                <div className={styles.percentage}>{percentage}%</div>
                <div className={styles.label}>Completed</div>
              </div>
            </div>
          </div>

          <div className={styles.progressBarContainer}>
            <div
              className={styles.progressBar}
              style={{ width: `${percentage}%` }}
            />
          </div>
          <p className={styles.statusMessage}>
            {
              percentage > 0 && percentage < 50 ? "Keep going! Add more details to improve your profile" :
                percentage >= 50 && percentage < 90 ? "Almost there! Just a few more steps" :
                  percentage >= 90 && percentage < 100 ? "So close! Final touches needed" :
                    percentage === 100 ? "Perfect! Your profile is complete" : ""
            }
          </p>
          <button
            className={styles.actionButton}
            onClick={onCompleteClick}
          >
            <span className={styles.buttonText}>Complete Your Profile</span>
          </button>

          {(user?.skills && user?.skills?.length < 1)&&<Link to="/journey-source">
            <button
              className={styles.actionButton2}
            // onClick={onCompleteClick}
            >
              <span className={styles.buttonText}>Create Your First Ad</span>
            </button>

          </Link>}



        </div>
      </div>
    </div>
  );
};

export default ModernProfileMeter;