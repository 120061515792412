import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useAppSelector } from '../../../app/hooks'
import { MODALNAME } from '../../../constants'
import { useAppDispatch } from '../../../app/store'
import { closeModal } from '../../../features/Auth/ModalHandler/modalSlice'
import TeachersShareCards from '../TeachersShareCards/TeachersShareCards'
import { useSearchParams } from 'react-router-dom'
import PaymentSuccessfullyModal from '../PaymentSuccessfully/PaymentSuccessfullyModal'
import TermsAndCModal from '../termsModal/TermsAndCModal'
import AdCreatedModal from '../adCreationSuccessModal/AdCreatedModal'
import AuthCollection from '../../../features/Auth/AuthCollection'
import REquestPaymentModal from '../requestPaymentModal'
import RefferalUserModal from '../refferalUserModal'
import LogoutConfirmationModal from '../logoutConfirmationModal'
import ReportAdModal from '../reportAdvtModal/ReportAdModal'
import UserConectedCheck from '../userInternerStatusModal'
import PlatformShareModal from '../sharePlatformModal'
import CompleteProfileFormModal from '../../promotionPageMeeter'

const ModalControl = () => {
    const {modalName, data} = useAppSelector(state=> state.authModal)
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    function handleClose () { dispatch(closeModal())}
    
    const showPaymentModal = searchParams.get('payment') === 'success';
  return (
    <>
    <PaymentSuccessfullyModal show={showPaymentModal}/>
    <TeachersShareCards show={modalName==MODALNAME.SHARE_MODAL} handleClose={handleClose} data={data} />
    <PlatformShareModal show={modalName==MODALNAME.SHARE_PLATFORM_MODAL} handleClose={handleClose} data={data} />
    <TermsAndCModal/>
    <AdCreatedModal show={modalName==MODALNAME.AD_CREATED} handleClose={handleClose} data={data}/>
    <AuthCollection />
    <REquestPaymentModal show={modalName==MODALNAME.REQUEST_PAYMENT} handleClose={handleClose} data={data}/>
    <RefferalUserModal show={modalName==MODALNAME.REFFERAL_ENTRY} handleClose={handleClose} data={data}/>
    <LogoutConfirmationModal show={modalName==MODALNAME.CONFIRM_LOGOUT} handleClose={handleClose} data={data}/>
    <ReportAdModal />
    <UserConectedCheck />
    <CompleteProfileFormModal showModal={modalName==MODALNAME.COMPLETE_PROFILE} handleClose={handleClose}/>
    </>
  )
}

export default ModalControl