import React, { useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "../../../../../components/Verification/Number/Enter.css"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { useAppDispatch } from "../../../../../app/store"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../../../app/hooks"
import { MODALNAME, USERTYPE } from "../../../../../constants"
import {
  closeModal,
  setModalName,
} from "../../../../../features/Auth/ModalHandler/modalSlice"
import Verified from "../../../../../components/Verification/Number/Verified"
import {
  useEmailVerifyMutation,
  useProviderEmailverifyMutation,
  useResendOtpMutation,
  useResendOtpProviderMutation,
} from "../../../../../features/Auth/authApi"
import { toast } from "react-toastify"
import { selectCurrentUser } from "../../../../../features/Auth/authSlice"
import {
  useSeekerPhoneVerificationMutation,
  useSeekerVerifyPhoneOtpMutation,
} from "../../../../../features/featSeekerDashboard/seekerDashboardApi"
import {
  useProviderPhoneVerificationMutation,
  useProviderVerifyPhoneOtpMutation,
} from "../../../../../features/featProviderDashboard/FeatProviderApi"

const VerificationModal = () => {
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const [otpError, setOtpError] = useState(null)
  const [resentMessage, setResentMessage] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState(0)
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const auth = useAppSelector((state: any) => state.auth)

  const [
    resendOtp,
    {
      isLoading: loading,
      error: resendError,
      isSuccess: resendsuccess,
      data: resendOtpData,
    },
  ] = useResendOtpMutation()

  const [
    resendOtpProvider,
    {
      isLoading: resendOTPproviderloading,
      error: resendOTPproviderError,
      isSuccess: resendOTPproviderSuccess,
      data: resendProviderOtpData,
    },
  ] = useResendOtpProviderMutation()

  // Phone verification
  const [
    seekerVerifyPhoneOtp,
    {
      data: SeekerVerifyPhoneOtpData,
      error: SeekerVerifyPhoneOtpError,
      isSuccess: SeekerVerifyPhoneOtpSuccess,
      isLoading: SeekerVerifyPhoneOtpLoading,
    },
  ] = useSeekerVerifyPhoneOtpMutation()
  const [
    providerVerifyPhoneOtp,
    {
      data: providerVerifyPhoneOtpData,
      error: providerVerifyPhoneOtpError,
      isSuccess: providerVerifyPhoneOtpSuccess,
      isLoading: providerVerifyPhoneOtpLoading,
    },
  ] = useProviderVerifyPhoneOtpMutation()
  // phone resent otp
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
      error: phoneVerificationError,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
      error: seekerphoneVerificationError,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification
  const [verifyEmail, { data, isError, isLoading, isSuccess, error }] =
    useEmailVerifyMutation()
  const [
    providerEmailVerify,
    {
      data: providerData,
      error: providererror,
      isLoading: peoviderloading,
      isSuccess: providerverifySuccess,
    },
  ] = useProviderEmailverifyMutation()

  const inputRefs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ]
  const [valerror, setValError] = useState<string>()

  const handleInputChange = (index: number, event: any) => {
    setResentMessage(true as any)
    const value = event.target.value
    setOtpError(null)
    if (isNaN(Number(value))) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, event: any) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current?.focus()
    }
  }
  const handleEmailVerify = () => {
    const OTP_JOINED = otp.join("");
  
    if (!OTP_JOINED) {
      setValError("Please enter the OTP shared");
      return;
    }
  
    const handleSuccess = () => {
      setValError("");
      dispatch(setModalName(MODALNAME.CLOSED));
      toast.dismiss();
      toast.success("Verified Successfully");
    };
  
    const handleError = (error: any) => {
      setOtpError(error?.data?.message);
    };
  
    const verify = () => {
      if (auth?.currentUserRole === USERTYPE.SEEKER) {
        if (modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL) {
          verifyEmail({ otp: OTP_JOINED, email: auth?.user?.email })
            .unwrap()
            .then(handleSuccess)
            .catch(handleError);
        } else if (modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE) {
          seekerVerifyPhoneOtp({ otp: OTP_JOINED })
            .unwrap()
            .then(handleSuccess)
            .catch(handleError);
        }
      } else {
        if (modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL) {
          providerEmailVerify({ otp: OTP_JOINED, email: auth?.user?.email })
            .unwrap()
            .then(handleSuccess)
            .catch(handleError);
        } else if (modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE) {
          providerVerifyPhoneOtp({ otp: OTP_JOINED })
            .unwrap()
            .then(handleSuccess)
            .catch(handleError);
        }
      }
    };
  
    verify();
  };
  
  // const handleEmailVerify = () => {
  //   const OTP_JOINED = otp.join("")
  //   if(OTP_JOINED === "") {
  //     setValError("Plese enter the OTP shared")
  //     return
  //   }
  //   if (auth?.currentUserRole === USERTYPE.SEEKER) {
  //       if ( modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL) 
  //         {
  //         verifyEmail({otp: OTP_JOINED, email: auth?.user?.email}).unwrap()
  //         .then((res: any)=>{
  //           setValError("")
  //           dispatch(setModalName(MODALNAME.CLOSED))
  //           toast.dismiss()
  //           toast.success("Verified Successfully")
  //         })
  //         .catch((error: any)=>{
  //           setOtpError(error?.data?.message)
  //         })
  //       } 
  //       else if ( modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE) {
  //           seekerVerifyPhoneOtp({ otp: OTP_JOINED }).unwrap()
  //           .then(()=>{
  //             setValError("")
  //             dispatch(setModalName(MODALNAME.CLOSED))
  //             toast.dismiss()
  //             toast.success("Verified Successfully")
  //           })
  //           .catch((error: any)=>{
  //             setOtpError(error?.data?.message)
  //           });
  //         }
  //       }
  //     else {
  //       if ( modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL) {
  //         const res = await providerEmailVerify({
  //           otp: OTP_JOINED,
  //           email: auth?.user?.email,
  //         })
  //         setValError("")
  //         if ((res as any)?.data?.data) {
  //           dispatch(setModalName(MODALNAME.CLOSED))
  //           toast.dismiss()
  //           toast.success("Verified Successfully")
  //         } else {
  //           if ((res as any)?.error?.status === 401) {
  //             setOtpError((res as any)?.error?.data?.message)
  //           }
  //         }
  //       } else {
  //         if (
  //           modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE
  //         ) {
  //           const res = await providerVerifyPhoneOtp({ otp: OTP_JOINED })
  //           setValError("")
  //           if ((res as any)?.data?.success) {
  //             dispatch(setModalName(MODALNAME.CLOSED))
  //             toast.dismiss()
  //             toast.success("Verified Successfully")
  //           } else {
  //             if ((res as any)?.error?.status === 401) {
  //               setOtpError((res as any)?.error?.data?.message)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  const handleResendOtp = () => {
    setValError("")
    setOtpError(null)
    setOtp(["", "", "", "", "", ""])

    if (modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE) {
      if (auth?.currentUserRole === USERTYPE.SEEKER) {
        seekerphoneVerify()
        // resendOtp({mobileNo: auth?.user?.mobileNo})
      } else {
        phoneVerify()
        // resendOtpProvider({mobileNo: auth?.user?.mobileNo})
      }
    } else {
      if (auth?.currentUserRole === USERTYPE.SEEKER) {
        resendOtp({email: auth?.user?.email})
      } else {
        resendOtpProvider({email: auth?.user?.email})
      }
    }
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`
  }

  useEffect(() => {
    if (
      modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE ||
      modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL ||
      resendsuccess ||
      resendOTPproviderSuccess ||
      seekerphoneVerificationSuccess ||
      phoneVerificationSuccess
    ) {
      setCurrentTime(60)

      const timerId = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerId)
            return 0
          } else {
            return prevTime - 1
          }
        })
      }, 1000)

      return () => clearInterval(timerId)
    } else {
      setCurrentTime(0)
    }
  }, [
    modalName,
    resendsuccess,
    resendOTPproviderSuccess,
    seekerphoneVerificationSuccess,
    phoneVerificationSuccess,
  ])

  useEffect(() => {
    if (
      SeekerVerifyPhoneOtpSuccess ||
      providerVerifyPhoneOtpSuccess ||
      phoneVerificationSuccess ||
      seekerphoneVerificationSuccess
    ) {
      setOtp(["", "", "", "", "", ""])
    }
    if (
      SeekerVerifyPhoneOtpError ||
      providerVerifyPhoneOtpError ||
      phoneVerificationError ||
      seekerphoneVerificationError
    ) {
      setOtpError(
        (SeekerVerifyPhoneOtpError as any)?.data?.message ||
          (providerVerifyPhoneOtpError as any)?.data?.message ||
          (phoneVerificationError as any)?.data?.message ||
          (seekerphoneVerificationError as any)?.data?.message,
      )
    }
    if (
      resendsuccess ||
      resendOTPproviderSuccess ||
      phoneVerificationSuccess ||
      seekerphoneVerificationSuccess
    ) {
      setOtpError(null)
      setResentMessage(false)
    }
  }, [
    phoneVerificationSuccess,
    seekerphoneVerificationSuccess,
    phoneVerificationError,
    seekerphoneVerificationError,
    phoneVerificationSuccess,
    seekerphoneVerificationSuccess,
    SeekerVerifyPhoneOtpSuccess,
    providerVerifyPhoneOtpSuccess,
    SeekerVerifyPhoneOtpError,
    providerVerifyPhoneOtpError,
    resendsuccess,
    resendOTPproviderSuccess,
  ])

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text').trim();
    // Ceck for numeric OTP 
    if(!(isNaN(parseInt(pastedData)))) {
      const newOtp = pastedData.split('').slice(0, otp.length); 
      setOtp(newOtp);
      inputRefs[5]?.current?.focus();
    }
  };
  return (
    <>
      <Modal
        show={
          modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL ||
          modalName === MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE
        }
        onHide={() => {
          dispatch(setModalName(MODALNAME.CLOSED))
        }}
        onExited={() => {
          setOtp(["", "", "", "", "", ""]), setOtpError(null)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Modal.Header
            closeButton
            style={{ border: "none" }}
            onClick={() => setValError("")}
          ></Modal.Header>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col xl={12} lg={12}>
                  <div className="d-flex justify-content-between flex-column h-100 text-center">
                    <div>
                      <p className="verification-heading">
                        Complete Verification
                      </p>
                      <p className="get-started-text mb-4 ">
                        Enter the 6 digit OTP shared
                      </p>
                      {(resendsuccess || resendOTPproviderSuccess) && (
                        <p className=" mb-1">
                          {!resentMessage && "Otp has been sent successfully"}
                        </p>
                      )}
                    </div>
                    <form action="margin-auto">
                      <div className="input-field otp-field ">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            onPaste={(event) => handlePaste(event)}
                            onKeyDown={(event) => handleKeyDown(index, event)}
                            ref={inputRefs[index] as any}
                          />
                        ))}
                      </div>

                      {otpError && <span className="error text-danger">{otpError}</span>}
                      {valerror && <span className="error text-danger fs-6">{valerror}</span>}
                    </form>
                    {!(modalName === MODALNAME.CLOSED) && (
                      <div className="otp-heading mt-4">
                        {currentTime === 0 ? (
                          <p className=" text-danger">
                            Time's up! Click Resend for new OTP.
                          </p>
                        ) : (
                          <p>Time Remaining: {formatTime(currentTime)}</p>
                        )}
                        {(loading ||
                          resendOTPproviderloading ||
                          phoneVerificationLoading ||
                          seekerphoneVerificationLoading) && (
                          <span>
                            <Spinner />
                          </span>
                        )}
                        {currentTime === 0 ? (
                          <>
                            <p className="resend-otp" onClick={handleResendOtp}>
                              Resend OTP{" "}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <button
                      type="button"
                      className="send-button mt-2"
                      onClick={handleEmailVerify}
                      disabled={currentTime === 0}
                      // otp.join("").length !== 6
                    >
                      {SeekerVerifyPhoneOtpLoading ||
                      providerVerifyPhoneOtpLoading ? (
                        <>
                          <Spinner className="spinner-small" />
                        </>
                      ) : (
                        "VERIFY"
                      )}
                    </button>{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Verified />
    </>
  )
}

export default VerificationModal
