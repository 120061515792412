import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { boolean } from 'yup'
import { useAppSelector } from '../../../app/hooks'
import { currentModalName } from '../../../features/Auth/ModalHandler/modalSlice'
import { MODALNAME } from '../../../constants'
import { MdOutlineReport } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { BiCross } from 'react-icons/bi'
import { toast } from 'react-toastify'

const ReportAdvStpOne = ({handleClose, handleReportProgress, setValue}: any) => {
  const [reportReasons, _ReportReason] = useState<string[]>(['False Credentials', 'Inappropriate Content', 'Scam or Fraudulent Activity', 'Violation of Company Policies' , 'Quality Concerns', 'Other']) 
  const [checkedResponse, _checkResponse] = useState<boolean[]>([])
  useEffect(()=>{
    if(reportReasons) _checkResponse(Array.from({length: reportReasons.length}, () => false))
  },[reportReasons])
  function handleChangeCheck(e: any, index: number) {
    _checkResponse((prev)=>{
      let temp = Array.from({length: reportReasons.length}, () => false)
      temp[index] = !prev[index]
      return temp
    })
  }
  function progressToNext(): void {
    const res = checkedResponse
        .map((isChecked, index) => (isChecked ? reportReasons[index] : null))
        .filter(reason => reason !== null);
    if(res[0]!==null) {
      setValue('category',res[0])
      handleReportProgress(1);
    }
    else {
      toast.error('Plase select at least a field to report ')
    }
  }


  return (
    <>
      <Modal.Body className='p-3'>
        <h5 className="mb-3 modal_content_heading">Please select the issues you are experiencing</h5>
        <div className="d-flex justify-content-between flex-column report_modal">
            {
              reportReasons?.map((reportsTitle: string, index: number) => {

                return (
                <p
                onClick={(e)=>{handleChangeCheck(e, index)}}>{reportsTitle}
                  {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mb-3 rpt-ads-checkbox">
                      <Form.Check
                        type={'checkbox'}
                        id={`default-${type}`}
                        checked={checkedResponse.at(index)}
                        className='fs-5 vedichom-custom-checkbox'
                      />
                    </div>
                  ))}
                </p>
                )

              })
            }
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between footer-reply-modal pt-0">
        <Button variant="secondary" className="send-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="secondary" className="send-btn" onClick={()=>progressToNext()} disabled={!checkedResponse.some(element => element === true)}>
          Next
        </Button>
      </Modal.Footer>
    </>
  )
}

export default ReportAdvStpOne