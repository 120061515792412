import React, { useEffect, useState } from "react";
import styles from "./NewVerificationCard.module.css";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { dashProps } from "../../../../Types";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../app/hooks";
import { useAppDispatch } from "../../../../app/store";
import { USERTYPE, MODALNAME } from "../../../../constants";
import { useResendOtpMutation, useResendOtpProviderMutation } from "../../../../features/Auth/authApi";
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice";
import { useProviderPhoneVerificationMutation } from "../../../../features/featProviderDashboard/FeatProviderApi";
import { useSeekerPhoneVerificationMutation } from "../../../../features/featSeekerDashboard/seekerDashboardApi";
import useNavigateAndScroll from "../../../../utils/commonFunctions/navigateAndScroll";
import { LuShieldCheck } from "react-icons/lu";

const NewVerificationCard: React.FC<dashProps> = ({
    userType,
    seekerProfileData,
    sourceProfileData,
    isLoading,
    authUser: auth,
  }) => {

  const navigateAndScroll = useNavigateAndScroll();
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  // add auto collapse on path change
  const location = useLocation()
  useEffect(()=>{setOpen(false)},[location])
  const authUser = useAppSelector((state: any) => state.auth)

  // phone verification
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification
  const [
    verifyEmail,
    {
      data: verifySeekerEmaildata,
      error: verifySeekerEmailError,
      isSuccess: verifySeekerEmailSuccess,
      isLoading: verifySeekerEmailLoading,
    },
  ] = useResendOtpMutation()
  const [
    verifyProviderEmail,
    {
      data: verifyProviderEmaildata,
      error: verifyProviderEmailError,
      isSuccess: verifyProviderEmailSuccess,
      isLoading: verifyProviderEmailLoading,
    },
  ] = useResendOtpProviderMutation()

  const handlePhoneVerify = async () => {
    toast.dismiss()
    if (userType === USERTYPE.PROVIDER) {
      if (!phoneVerificationLoading) {
        phoneVerify().unwrap()
        .then(()=>{dispatch(setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),)})
        .catch(()=>{toast.error("Something went Wrong, please try again later")})
        // Replaced
        // const res = await phoneVerify()
        // if ((res as any)?.data?.success) {
        //   dispatch(
        //     setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
        //   )
        // } else {
        //   toast.dismiss()
        //   toast.error("Something went Wrong, please try again later")
        // }
      }
    } else {
      if (!seekerphoneVerificationLoading) {
        seekerphoneVerify().unwrap()
        .then(()=>{dispatch(setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),)})
        .catch(()=>{toast.error("Something went Wrong, please try again later")})
        // Replaced
        // const res = await seekerphoneVerify()
        // if ((res as any)?.data?.success) {
        //   dispatch(
        //     setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
        //   )
        // } else {
        //   toast.dismiss()
        //   toast.error("Something went Wrong, please try again later")
        // }
      }
    }
  }
  const handleEmailVerify = async () => {
    try {
      if (auth?.currentUserRole === USERTYPE.PROVIDER) {
        if (!verifyProviderEmailLoading) {
          const res = await verifyProviderEmail({email: auth?.user?.email})
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }
        }
      } else {
        if (!verifySeekerEmailLoading) {
          const res = await verifyEmail({email: auth?.user?.email})
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }
        }
      }
    } catch (error) {
      console.error("Error while handling email verification:", error)
      toast.error("An unexpected error occurred. Please try again later.")
    }
  }

    function handleAddPhoneNumber(): void {
        navigateAndScroll(auth?.currentUserRole === USERTYPE.PROVIDER?'my-account?focus=verificationCardPhone':'my-account?focus=verificationCardPhone', undefined, 'verificationCardPhone')
    }

    function handleAddEmail(): void {
        navigateAndScroll(auth?.currentUserRole === USERTYPE.PROVIDER?'my-account?focus=verificationCardEmail':'my-account?focus=verificationCardEmail', undefined, 'verificationCardEmail')
    }

  return (
    <div className={styles.container}>
      {(auth?.user?.isMobileNoVerified && auth?.user?.isEmailVerified && true) ?
      (
        <div className={styles.completeCard}>
          <h3 className={styles.verifiedtitle}>
         
           <RiVerifiedBadgeFill /> Verification Complete
          </h3>
          <div className="d-flex gap-2 align-item-center justify-content-center">
          <div className={styles.verifiedfield}>
            <span> <MdEmail size={21} /> Verified</span>
          </div>
          <div className={styles.verifiedfield}>
             <span><IoPhonePortraitOutline />
             Verified</span>
          </div>
          </div>
        </div>
      ) : (
        <div className={styles.verifyCard}>
          <h3 className={styles.title}>
            <span className={styles.iconShield}> <LuShieldCheck  color="#FF5733" size={24} className="pb-1"/>
            </span> Verify Your Account
          </h3>
          <div className={styles.field}>
            <span> <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/message.png" alt=""  className="me-2" />  Email
            </span>
            {authUser?.user?.isEmailVerified ? <span className={styles.verified}>Verified</span>
            :(seekerProfileData?.data?.email || sourceProfileData?.data?.email) 
            ? <button className={styles.verifyButton} onClick={handleEmailVerify}>
              Verify
            </button>
            :<button className={styles.verifyButton} onClick={handleAddEmail}>
            Add Email
            </button>}
          </div>
          <div className={styles.field}>
            <span  className={styles.phoneIcon}>
              <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/mobile.png" alt="" className="me-2" /> Phone No.
           </ span>
            {authUser?.user?.isMobileNoVerified ? <span className={styles.verified}>Verified</span>
            : (seekerProfileData?.data?.mobileNo || sourceProfileData?.data?.mobileNo) 
            ? <button className={styles.verifyButton} onClick={handlePhoneVerify}>
              Verify
            </button>
            :<button className={styles.verifyButton} onClick={handleAddPhoneNumber}>
            Add Phone
            </button>}
          </div>
          {/* <div className={styles.progressContainer}>
            <div className={styles.progressBar}></div>
            <span className={styles.progressText}>1/2</span>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NewVerificationCard;
