import React from 'react'
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap'

interface inputTooltip {
    name: string
    type: string
    key: string
    placeholder: string
    children: any
    className?: string
    message?: string
    errors?: any
    register?: any
    isEditMode?: boolean
    tooltipText?: string
    placement?: string
}

const InputSelectorWithTooltip = (prop: inputTooltip) => {
  return (
    <>
    <OverlayTrigger
          placement="top"
          delay={{ show: 200, hide: 100 }}
          overlay={!prop.isEditMode ? (<Tooltip className=' position-absolute bottom-0' id="information-tooltip">Click on Edit to change </Tooltip>) : <></>}
        >
            <Form.Select
              {...prop.register}
              name={prop.name}
              disabled={!prop.isEditMode}
              type={prop.type}
              placeholder={prop.placeholder}
              className="account-field-input"
            >
                {prop.children}
                </Form.Select>
            </OverlayTrigger></>
  )
}

export default InputSelectorWithTooltip