import React, { useEffect, useState } from 'react';
import { RootState } from '../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { FiPlus } from 'react-icons/fi';
import { updateFunnel } from '../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice';
import './style.css';
import { IoMdAdd } from 'react-icons/io';
import { toast } from 'react-toastify';

type ConsultancyDetail = {
  skillSet: string;
  price?: number | null;
};
const MAX_NUMBER_OF_SKILLS = 5;
const MAX_SERVICE_PRICE = 99999;
const ConsultancyInfo = () => {
  const { skillSet, subSkills, averagePrice } = useAppSelector((state: RootState) => state.providerFunnel.providerFunnelData);
  const { providerFunnelData } = useAppSelector((state: RootState) => state.providerFunnel);
  const [consultancyDetails, setConsultancyDetails] = useState<ConsultancyDetail[]>([]);
  const [avgPrice, setAveragePrice] = useState<number | undefined>(averagePrice);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(subSkills && subSkills.length) {
      setConsultancyDetails((prev)=>[...subSkills]);
    }
    else if (skillSet && skillSet.length) {
      const currentList = consultancyDetails || [];
      const newItems = skillSet
        .filter((item) => !currentList.some((currentItem) => currentItem.skillSet === item))
        .map((item) => ({ skillSet: item, price: undefined }));

      if (newItems.length) {
        setConsultancyDetails([...currentList, ...newItems]);
      }
    }
  }, [skillSet]);

  useEffect(() => {
    const newItems = consultancyDetails.filter((item) => (item?.skillSet?.trim() !== '') );
    dispatch(updateFunnel({ ...providerFunnelData, subSkills: newItems }));
  }, [consultancyDetails]);

  useEffect(() => {
    dispatch(updateFunnel({ ...providerFunnelData, averagePrice: avgPrice }));
  }, [avgPrice]);

  const calculateAveragePrice = (details: ConsultancyDetail[]): number => {
    const prices = details
      .filter((item) => item.price !== undefined && item.price !== null)
      .map((item) => item.price!);
    if (prices.length === 0) return 0;
    return Number(
      (prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2)
    );
    // return Math.round(prices.reduce((sum, price) => sum + price, 0) / prices.length);
  };

  useEffect(() => {
    const value = calculateAveragePrice(consultancyDetails);
    setAveragePrice(value);
  }, [consultancyDetails]);

  const addNewItem = () => {
    if(consultancyDetails.some((item) => !item.skillSet?.trim())) {
      setErrorMessage(`Please enter a valid name for all the skills`)
    }
    else if(consultancyDetails.length >= MAX_NUMBER_OF_SKILLS) {
      setErrorMessage(`Cannot add more than ${MAX_NUMBER_OF_SKILLS} skills`)
    }
    else setConsultancyDetails([...consultancyDetails, { skillSet: '', price: undefined }]);
  };

  const removeItem = (index: number) => {
    const updatedList = consultancyDetails.filter((_, i) => i !== index);
    setConsultancyDetails(updatedList);
  };

  const handleOnSkillNameChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined)
    const updatedDetails = consultancyDetails.map((item, idx) =>
      idx === index
        ? { ...item, skillSet: e.target.value }
        : item
    );
    setConsultancyDetails(updatedDetails);
  };
  

  const handleOnPriceChange = (index: number, e: React.ChangeEvent<any>) => {
    setErrorMessage(undefined)
    var value: number | undefined = parseFloat(e.target.value);
    if (isNaN(value)) {
      value = undefined;
    }
    if(value && value>MAX_SERVICE_PRICE){ 
      setErrorMessage(`Price must not be greater than ${MAX_SERVICE_PRICE}`)
      return;}
    const updatedDetails = consultancyDetails.map((item, idx) =>
      idx === index
        ? { ...item, price: value ? value : undefined }
        : item
    );
    setConsultancyDetails(updatedDetails);
  };
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };
  useEffect(() =>{
      const inputField = document.getElementById(`subskill-${consultancyDetails.length-1}`)
      if (inputField) {inputField.focus()}
    },[consultancyDetails.length])

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label className="green-text mb-2 font-14 d-flex gap-2 justify-content-start align-items-center">
          <p className="text-black fw-bolder fs-5">Add Details</p>
          <p className="fs-6 fw-bolder mt-1">for your Services</p>
        </label>
          <p>Prices added in each of the below options will effect your average pricing</p>
        <div className="d-flex flex-column gap-2">
          {consultancyDetails.map((detail, index) => (
            <Row key={`consultancy-${index}`} className="gap-2 mb-2">
              <Col lg={6} md={6} xs={6} className='pe-0'>
              <div className='your-services-skill-inpt'>
                <input
                  placeholder="Skill Set"
                  className={`form-control`}
                  id={`subskill-${index}`} 
                  value={detail.skillSet}
                  onChange={(e) => handleOnSkillNameChange(index, e)}
                  onKeyDown={(e) => {
                    if (
                      /^[0-9]$/.test(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                </div>
              </Col>

              <Col lg={4} md={4} xs={4} className="d-flex align-items-center justify-content-center gap-2 p-0">
                {/* <div className="rs-text">Rs </div> */}
                <div  className=' d-flex align-items-center your-services-skill-inpt'>
                <Form.Control
                    inputMode="numeric"
                    className="form-control border-none text-end"
                    type="text"
                    pattern="[0-9]*"
                    placeholder="Price"
                    onChange={(e) => handleOnPriceChange(index, e)}
                    value={detail.price || ''}
                    autoComplete="off"
                    onBlur={()=>{setErrorMessage(undefined)}}
                    onKeyDown={(e) => {
                      if (
                        !/^[0-9]$/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "Tab"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className=' fw-bolder '>Rs</span>  </div>
              </Col>

              <Col lg={1} md={1} xs={1} className="p-0 m-0 d-flex align-items-center justify-content-center">
                <Button
                  type="button"
                  onClick={() => removeItem(index)}
                  variant="transparent"
                  className="yours-service-remove-btn"
                >
                  <RxCross2 size={25} color="#333" />
                </Button>
              </Col>
            </Row>
          ))}
          {errorMessage&&<Col xs={12} className=' fw-light fs-6 text-danger'>{errorMessage}</Col>}
          <Col lg={12} className="pe-4">
            <Button
              type="button"
              onClick={addNewItem}
              variant="outline-dark"
              className="justify-content-center add-new-skill-btn"
            >
              <span className="d-none d-sm-block">
                <IoMdAdd size={21} /> Add New Sub Services
              </span>
              <span className="d-sm-none d-lg-none">
                <FiPlus /> Add
              </span>
            </Button>
          </Col>
        </div>
      </div>

      <div className="mt-4">
        <label className="green-text mb-2 font-14 d-flex gap-2 justify-content-start align-items-center">
          <p className="text-black fw-bolder fs-5">Add Your</p>
          <p className="fs-6 fw-bolder mt-1">Average Price</p>
          <p>*</p>
        </label>
        <p className="w-100">Your avarage price will be evaluated automatically</p>
        <div className="d-flex flex-column gap-2 form-control custom-input funnel-input-focus justify-content-md-center justify-content-end align-content-md-center align-items-md-center">
          <Row className="justify-content-end align-content-end align-items-end justify-content-md-center align-content-md-center align-items-md-center">
            <Col xs={12} sm={8} md={8} className=' justify-content-end align-items-end cursor-disable'>
              <FormControl
                placeholder="Average price"
                className={`form-control custom-input funnel-input-focus border-0 text-start text-md-end `}
                value={avgPrice || ''}
                readOnly
                disabled
              />
            </Col>
            <Col className="input-custom-price-text text-end  text-md-start" xs={6} sm={3} md={'auto'}>Rs/Session</Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default ConsultancyInfo;
