import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useScrollTracking from '../../features/googleAnalytics/useScrollTracking';
import ReactGA from 'react-ga4';

const AnalyticsMeasurer = () => {
    const location = useLocation();
    // useScrollTracking();

    // Funnel tracking state
    const [funnelSteps, setFunnelSteps] = useState<string[]>([]);

    useEffect(() => {
        // Track page views
        const pageTitle = document.title; // Get the current page title
        ReactGA.set({ page: location.pathname, title: pageTitle });
        ReactGA.send('pageview');

        const startTime = Date.now();
        return () => {
            const timeSpent = Math.floor((Date.now() - startTime) / 1000); // Time in seconds
            ReactGA.event({
                category: 'User Engagement',
                action: 'Time on Page',
                label: location.pathname,
                value: timeSpent,
            });
        };
    }, [location]);

    useEffect(() => {
        // Funnel tracking
        const funnelJourney = ['journey-source', 'subject-details', 'avt-details', 'source-details', 'class-details', 'source-picture', 'my-advt'];
        const currentStep = location.pathname.split('/').pop();

        if (currentStep && funnelJourney.includes(currentStep)) {
            // Add the current step to the funnelSteps if not already present
            setFunnelSteps(prevSteps => {
                if (!prevSteps.includes(currentStep)) {
                    ReactGA.event({
                        category: 'Funnel Tracking',
                        action: `Funnel Progress`,
                        label: currentStep,
                        value: prevSteps.length + 1, // Step number in the funnel
                    });
                    return [...prevSteps, currentStep];
                }
                return prevSteps;
            });
        }

        // Debugging or additional action on funnel completion
        if (currentStep === 'my-advt') {
            ReactGA.event({
                category: 'Funnel Tracking',
                action: 'Funnel Completed',
                label: `Completed Funnel`,
            });
        }
    }, [location]);

    useEffect(() => {
        // Global click event listener
        const handleClick = (event: { target: any }) => {
            const clickedElement = event.target;
            if (['BUTTON', 'A', 'P'].some(tag => tag === clickedElement.tagName)) {
                const elementLabel =
                    clickedElement.textContent ||
                    clickedElement.getAttribute('aria-label') ||
                    'Unknown';
                ReactGA.event({
                    category: 'User Engagement',
                    action: 'Click Engagement',
                    label: `${elementLabel} - ${location.pathname}`, // Include the page in the label
                });
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [location]);

    return <div />;
};

export default AnalyticsMeasurer;
