import React, { useEffect, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import {
  useGetLocationMutation,
  useGetLocationSuggestionMutation,
} from "../../../../features/locationFunc/locationApi"
import SpinnerMain from "../../../spinner/SpinnerMain"
import "./locationInput.css"
import { IoClose } from "react-icons/io5"
import { FaLocationArrow } from "react-icons/fa6"
import { toast } from "react-toastify"
import { motion } from "framer-motion"
import { useDebounce } from "../../../../utils/debouncer"
import CustomTooltip from "../../../buttons/CustomTooltip"
import  ReactGA  from "react-ga4"

type SearchLocationInputProps = {
  isdisabled: boolean
  defaultValue: string
  setAtParent?: (place: string, city?: string) => void
  placeholder?: string
  setLocation?: (place: string) => void
  setCity?: (city: string) => void
  clearCity?: () => void
}

interface Coordinates {
  latitude: number
  longitude: number
}

interface PositionError {
  message: string
}

export const SearchLocationInputBar = (prop: SearchLocationInputProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [location, setLocation] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [locationList, setLocationList] = useState<any[]>([])

  useEffect(()=>{
    if(prop?.defaultValue) {
      setLocation(prop.defaultValue)
    }
  },[prop])
  const [search, { data, isLoading: locSearchLoading, error: locSearchError }] =
    useGetLocationSuggestionMutation()
  const [
    getLocation,
    { data: CurlocationData, error, isLoading: loadingCurLoc },
  ] = useGetLocationSuggestionMutation()
  const [getMyCoordinates] = useGetLocationMutation()

  const handleOnBlurOrCancle = () => {
    setIsActive(false)
    if (location && prop.setAtParent) prop.setAtParent(location, "")
  }

  const handleSearch = () => {
    if (location && location.length > 3) search({ name: location })
    else setLocationList([])
  }

  const searchLocationWithDebounce = useDebounce(handleSearch, 870)
  useEffect(() => {
    searchLocationWithDebounce()
  }, [location])

  const handleLocationClick = (locationResult: any) => {
    const Label = locationResult.Place.Label
    const SubRegion = locationResult.Place?.SubRegion
    const Region = locationResult.Place?.Region
    if(prop.setAtParent) prop.setAtParent(SubRegion)
    if(prop.setLocation) prop.setLocation(Label)
    if(prop.setCity) prop.setCity(SubRegion || Region)
    setLocation(Label)
    setIsActive(false)
  }

  const handleLocationfetch = () => {
    setIsLoading(true)
    setIsActive(true)
    getMyCoordinates()
      .unwrap()
      .then((res: any) => {
        getLocation({ coordinates: res }).unwrap()
        .then((res)=>{
          ReactGA.event({
            category: "User Location",
            action: "Country Detected",
            label: "Country",
            value: res?.data?.Results[0]?.Place?.Region
          });
        })
      })
      .catch((error) => {
        toast.dismiss()
        toast.error(error?.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (CurlocationData?.data?.Results[0]) {
      handleLocationClick(CurlocationData?.data?.Results.at(0))
    }
  }, [CurlocationData])

  function handleInputChange(value: string) {
    const trimmedValue = value.trim();
  
    if (!trimmedValue || value.startsWith(" ")) {
      setLocation(trimmedValue)
      return; 
    }
  
    setLocation(value);
    if (prop.clearCity && isActive) {
      prop.clearCity();
    }
  }
  

  useEffect(() => {
    if (data && data?.data?.Results) {
      setLocationList(data?.data?.Results)
    }
  }, [data])

  return (
    <>
      <div className="location-frame-container">
        <Row className=" location-btn-custmize">
          {(loadingCurLoc || isLoading) && (
            <SpinnerMain className=" position-absolute d-md-inline-block text-center w-100 bg-light bg-opacity-50" />
          )}
          <input
            onFocus={() => {
              setIsActive(true)
            }}
            onBlur={handleOnBlurOrCancle}
            disabled={prop.isdisabled}
            value={location || ""}
            onChange={(e) => {
              handleInputChange(e.target.value)
            }}
            type="text"
            id="location"
            className="custom-input iframe-input form-control w-100  pe-5"
            placeholder={ "Search Location ..."}
            autoComplete="off"
          />
          {/* </Col> */}
          {/* {isActive && ( */}
          <Col
            sm={1}
            className="justify-content-center align-items-center closeBtn-wraper"
          >
            {/* {isActive&&
            locationList.length > 0 ? null
            : (location && location?.length > 1 && location?.length<5) &&  <div className="closeBtn pe-2 pt-2" onClickCapture={() => {
              setLocation("");
              handleOnBlurOrCancle();
            // }}><CustomTooltip content={"Enter more than 3 lines"} /> </div>} */}
            {/* removed the icon */}
          </Col>
          {/* )} */}
        </Row>

        {isActive && (
          <ul className="location-frame-suggestions loc-suggt-back-box ">
            <li
              key={0}
              onMouseDownCapture={handleLocationfetch}
              className="row w-100 align-items-center  "
            >
              {loadingCurLoc ? (
                <>
                  <div className="d-flex justify-content-center align-items-center p-2">
                    {/* <SpinnerMain className="d-flex justify-content-center" />{" "} */}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex gap-2">
                    {" "}
                    <div className="">
                      {" "}
                      <FaLocationArrow />
                    </div>
                    <div className="">{"Select Your Current Location"}</div>
                  </div>
                </>
              )}
            </li>
            {locSearchLoading && (
              <div className="d-flex justify-content-center align-items-center p-2">
                <SpinnerMain className="d-flex justify-content-center" />{" "}
              </div>
            )}
            {locSearchError && (
              <div className="d-flex justify-content-center align-items-center p-2">
                <span className=" text-danger ">{"Some error occurred"}</span>
              </div>
            )}
            {locationList?.map((result: any, index: number) => (
              <li
                key={index + 1}
                onMouseDownCapture={() => {
                  handleLocationClick(result)
                }}
              >
                {result?.Place?.Label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
