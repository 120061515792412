import React, { useEffect } from 'react';
import ContactInformation from '../dasboard/commonDashboard/generalInformation/ContactInformation';
import styles from './MultiStepForm.module.css';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';

const Step6AccountVerification = ({ onSuccess, isComplete, stepKey }: any) => {
    const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)

    function handleContactUpdate(): void {
        onSuccess((prev: number) => prev + 1)
    }
    useEffect(() =>{
        if (isComplete) { 
            if(user?.isEmailVerified && user?.isMobileNoVerified) isComplete(stepKey, true)
            else isComplete(stepKey, false)
        }
    },[user])
    return (
        <>
            <div className={styles.formStep}>
                <h2>Verify your account</h2>
                <p className={styles.subtitle}>
                    After entering your email and phone number you can verify your account
                </p>
                <ContactInformation
                    userType={currentUserRole}
                    className={'shadow-none'}
                    title={' '}
                />
                <div className={styles.buttonGroup}>
                    <button
                        type="button"
                        onClick={() => onSuccess((prev: number) => prev - 1)}
                        className={styles.backButton}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        onClick={handleContactUpdate}
                        className={styles.nextButton}
                    >
                        {false ? 'Save & Continue' : 'Continue'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Step6AccountVerification;
