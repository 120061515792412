import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useNavigate } from "react-router-dom"

import { Row, Col, Stack } from "react-bootstrap"
import "./newDash.css"
import PhotoIcon from "../../../assets/images/Photo.svg"
import VerifyCard from "./VerifyCard"
import UpgradeCard from "./UpgradeCard"
import { USERTYPE } from "../../../constants"
import { language } from "../../modals/EditAds/data"
import ProfileCard from "./ProfileCard"
import ChatWrapper from "../../chatUi/ChatWrapper"
import { dashProps } from "../../../Types"
import NewVerificationCard from "./newVerifyCardComponent/NewVerificationCard"
const ProfileSection = ({
  userType,
  seekerProfileData,
  sourceProfileData,
  isLoading,
  authUser,
  selectedChatData
}: dashProps) => {
  const [profileData, setProfileData] = useState<any>()
  useEffect(() => {
    if (seekerProfileData) {
      setProfileData(seekerProfileData?.data)
    } else setProfileData(sourceProfileData?.data)
  }, [seekerProfileData, sourceProfileData])
  const dashboardPremium = "dashboardPremium"
  const loginData = useAppSelector((state: any) => state.auth)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return <>
      <ProfileCard
      userType={userType}
      firstName={profileData?.firstName}
      lastName={profileData?.lastName}
      profilePic={profileData?.profilePic}
      ratings={profileData?.rating?.$numberDecimal === "0" ? "" : profileData?.rating?.$numberDecimal}
      reviews={profileData?.reviews}
      vedicSubjectsSkills={profileData?.skills?.join()}
      languages={profileData?.languages}
      isVerified={profileData?.isMobileNoVerified && profileData?.isEmailVerified}/>
      <div className="pt-2">
        {/* <VerifyCard
          isLoading={isLoading}
          userType={userType}
          seekerProfileData={seekerProfileData}
          sourceProfileData={sourceProfileData}
          authUser={authUser}
        /> */}
      </div>
    <NewVerificationCard
      isLoading={isLoading}
      userType={userType}
      seekerProfileData={seekerProfileData}
      sourceProfileData={sourceProfileData}
      authUser={authUser} />
      <div className="pt-2">
       {/* { seekerProfileData?.data?.isPremium || sourceProfileData?.data?.isPremium &&  */}
        <UpgradeCard  
          isLoading={isLoading}
          userType={userType}
          seekerProfileData={seekerProfileData}
          sourceProfileData={sourceProfileData}
          authUser={authUser}
        />
        </div>
    </>
  
}

export default ProfileSection
