import React, { useEffect, useRef, useState } from "react"
import {
  Modal,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap"
import "./Enter.css"
// import { useAppDispatch } from "../../../app/store"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { isLaunched, MODALNAME, USERTYPE } from "../../../constants"
import {
  closeModal,
  setModalName,
} from "../../../features/Auth/ModalHandler/modalSlice"
import Verified from "./Verified"
import {
  useEmailVerifyMutation,
  useProviderEmailverifyMutation,
  useProviderPhoneverifyMutation,
  useResendOtpMutation,
  useResendOtpProviderMutation,
  useSeekerPhoneVerifyMutation,
} from "../../../features/Auth/authApi"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { maskCredential } from "../../../utils/commonFunctions/CommonFunctions"

const OTP = () => {
  const [otpError, setOtpError] = useState(null)
  const [valerror, setValError] = useState<string>()
  const [resentMessage, setResentMessage] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState(0)
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const { currentUserRole, user, seekerToken, providerToken } = useAppSelector(selectCurrentUser)
  const navigate = useNavigate()

  const auth = useAppSelector((state: any) => state.auth)

  // seekr email verify
  const [seekerEmailVerify, { data, isError, isLoading, isSuccess, error }] = useEmailVerifyMutation()
  const [seekerPhoneVerify, { data: seekerPhoneData, isError: seekerPhoneFailed, isLoading: seekerPhoneLoading, isSuccess: seekerPhoneSuccess, error: seekerPhoneError }] = useSeekerPhoneVerifyMutation()

  const [
    providerEmailVerify,
    {
      data: providerData,
      error: providererror,
      isLoading: peoviderloading,
      isSuccess: providerverifySuccess,
    },
  ] = useProviderEmailverifyMutation()
  const [providerOTPWithPhone, {data: providerMobileData, isSuccess: providerPhoneVerified, error: providerPhoneRejected}] = useProviderPhoneverifyMutation()
  const [
    resendOtp,
    {
      isLoading: loading,
      error: resendError,
      isSuccess: resendsuccess,
      data: resendOtpData,
    },
  ] = useResendOtpMutation()

  const [
    resendOtpProvider,
    {
      isLoading: resendOTPproviderloading,
      error: resendOTPproviderError,
      isSuccess: resendOTPproviderSuccess,
      data: resendProviderOtpData,
    },
  ] = useResendOtpProviderMutation()
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState(["", "", "", "", "", ""])

  const inputRefs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ]

  const handleInputChange = (index: number, event: any) => {
    setResentMessage(true as any)
    const value = event.target.value
    setOtpError(null)
    if (isNaN(Number(value))) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, event: any) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current?.focus()
    }
  }

  const handleOTPlVerify = () => {
    if (user.role === "Seeker" || currentUserRole === USERTYPE.SEEKER) {
      if (otp.join("") === "") setValError("Plese enter the OTP shared")
      else {
        if(modalName === MODALNAME.OTP_VERIFICATION) seekerEmailVerify({ otp: otp.join(""), email: user?.email })
        else seekerPhoneVerify({ otp: otp.join(""), mobileNo: user?.mobileNo })
        setValError("")
      }
    } else {
      if (otp.join("") === "") setValError("Plese enter the OTP shared")
      else {
        if(modalName === MODALNAME.OTP_VERIFICATION) providerEmailVerify({email: user?.email, otp: otp.join("")})
        else providerOTPWithPhone({ otp: otp.join(""), mobileNo: user?.mobileNo })
        setValError("")
      }
    }
  }
  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""])
    setOtpError(null)
    setValError("")
    if (currentUserRole === USERTYPE.SEEKER || user?.role === "Seeker") {
      const res = modalName === MODALNAME.OTP_VERIFICATION ? resendOtp({email: user?.email}) : resendOtp({mobileNo: user?.mobileNo})
      if ((res as any)?.token) {
        setValError("")
        setOtpError(null)
      }
    } else {
      const res = modalName === MODALNAME.OTP_VERIFICATION ? resendOtpProvider({email: user?.email}) : resendOtpProvider({mobileNo: user?.mobileNo})
      if ((res as any)?.token) {
        setValError("")
        setOtpError(null)
      }
    }
  }
  useEffect(()=>{
    if(seekerPhoneSuccess || providerPhoneVerified) {
      setOtp(["", "", "", "", "", ""]);
      dispatch(closeModal())
      if(providerPhoneVerified)  navigate(isLaunched?'/source-dashboard':'/source-dashboard/my-advt')
    }
    else if (seekerPhoneError) setOtpError((seekerPhoneError as any)?.data?.message)
    else if  (providerPhoneRejected) setOtpError((providerPhoneRejected as any)?.data?.message)
  },[seekerPhoneSuccess, providerPhoneVerified,
    seekerPhoneError, providerPhoneRejected
  ])

  useEffect(() => {
    if (isSuccess || providerverifySuccess) {
      setOtp(["", "", "", "", "", ""])
    }
    if (resendsuccess || resendOTPproviderSuccess) {
      setOtpError(null)
      setResentMessage(false)
    }
    if (error || providererror) {
      setOtpError(
        (error as any)?.data?.message || (providererror as any)?.data?.message,
      )
    }
  }, [
    isSuccess,
    providerverifySuccess,
    resendsuccess,
    resendOTPproviderSuccess,
    error,
    providererror,
  ])

  const resetCountDown = () => {
    setCurrentTime(60)
      setOtpError(null)
      const timerId = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerId)
            return 0
          } else {
            return prevTime - 1
          }
        })
      }, 1000)

      return () => clearInterval(timerId)
  }
  useEffect(() => {
    if (resendsuccess || resendOTPproviderSuccess || modalName == MODALNAME.OTP_VERIFICATION_PHONE || modalName == MODALNAME.OTP_VERIFICATION) resetCountDown()
  }, [modalName, resendsuccess, resendOTPproviderSuccess])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`
  }
  const handleModalClose = () => {
    dispatch(setModalName(MODALNAME.CLOSED))
    setValError("")
  }
useEffect(()=>{
  if(inputRefs && inputRefs[0]?.current){
    inputRefs[0]?.current.focus()
  }
},[])
const handleClick = () => {
  handleOTPlVerify()
};

const handleEnterKeyPress = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    handleClick(); 
  }
};

// useEffect(()=>{
//   if(modalName === MODALNAME.OTP_VERIFICATION ||  modalName === MODALNAME.OTP_VERIFICATION_PHONE) window.addEventListener('keypress', handleEnterKeyPress)
//   else window.removeEventListener('keypress', handleEnterKeyPress)
// return window.removeEventListener('keypress', handleEnterKeyPress)
// },[modalName])

const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
  const pastedData = event.clipboardData.getData('text').trim();

  // Ceck for numeric OTP 
  if(!(isNaN(parseInt(pastedData)))) {
    const newOtp = pastedData.split('').slice(0, otp.length); 
    setOtp(newOtp);
    inputRefs[5]?.current?.focus();
  }
};

  return (
    <>
      <Modal
        show={modalName === MODALNAME.OTP_VERIFICATION ||  modalName === MODALNAME.OTP_VERIFICATION_PHONE}
        onHide={handleModalClose }
        onExited={() => {
          setOtp(["", "", "", "", "", ""]), setOtpError(null)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton={true}
          style={{ border: "none" }}
          onClick={handleModalClose}
        >
          <Modal.Title className="verification-heading"> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col xl={12} lg={12}>
                  <div className="d-flex justify-content-between flex-column h-100 text-center">
                    <div>
                      <p className="verification-heading"> Verification</p>
                      <p className="get-started-text mb-4 text-success">
                        {`You are Successfully registered please verify your ${(modalName === MODALNAME.OTP_VERIFICATION) ? 'Email' : 'Phone number'} to Continue !`}
                      </p>
                      <p className="get-started-text mb-4">

                        {(seekerToken || providerToken)?`Enter the 6 digit OTP sent to your ${(modalName === MODALNAME.OTP_VERIFICATION) ? `Email ${maskCredential(user?.email)}` : modalName === MODALNAME.OTP_VERIFICATION_PHONE ? `Phone number ${maskCredential(user?.mobileNo)}`: ''}`
                        :
                        `Enter the 6 digit OTP sent to your ${(modalName === MODALNAME.OTP_VERIFICATION) ? `Email ${user?.email}` : modalName === MODALNAME.OTP_VERIFICATION_PHONE ? `Phone number ${user?.mobileNo}`: ''}`}
                      </p>
                      {(resendsuccess || resendOTPproviderSuccess) && ( !resentMessage &&
                        <motion.div className=" mb-3"
                        initial={{opacity: 1}}
                        whileInView={{opacity: 0}}
                        transition={{delay: 2}}>
                            {`Otp has been sent successfully to your ${(modalName === MODALNAME.OTP_VERIFICATION) ? 'email' : 'Phone number'}`}
                        </motion.div>)}
                    </div>
                    <form action="margin-auto">
                      <div className="input-field">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            onKeyDown={(event) => handleKeyDown(index, event)}
                            ref={inputRefs[index] as any}
                            onPaste={(event) => handlePaste(event)}
                          />
                        ))}
                      </div>

                      {otpError && <span className="error text-danger">{otpError}</span>}
                      {valerror && <span className="error text-danger">{valerror}</span>}
                    </form>
                    {!(modalName === MODALNAME.CLOSED) && (
                      <div className="otp-heading mt-4">
                        {(loading || resendOTPproviderloading) && (
                          <span>
                            <Spinner />
                          </span>
                        )}
                        {currentTime == 0 ? (
                          <>
                            <p className="verify-reset-otp-btn" onClick={handleResendOtp}>
                              Resend OTP{" "}
                            </p>
                          </>
                        ) : (
                          <div className="d-flex flex-row justify-content-center gap-2">
                          <p>Time Remaining </p>
                          <motion.p
                          initial={{opacity: 0, y: -10}}
                          animate={{opacity: 1, y: 0}}
                          transition={{ease: "easeInOut"}}
                          >{formatTime(currentTime)}</motion.p>
                          </div>
                        )}
                      </div>
                    )}
                    <button
                      type="button"
                      className="send-button mt-3"
                      onClick={handleOTPlVerify}
                      // disabled={currentTime === 0}
                      // otp.join("").length !== 6
                    >
                      {isLoading ? (
                        <>
                          <Spinner className="spinner-md"/>
                        </>
                      ) : (
                        "VERIFY"
                      )}
                    </button>{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Verified tokenKeySeeker={data?.data?.token} />
    </>
  )
}

export default OTP
