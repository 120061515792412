import React from "react"
import PlanCards from "../PlanCards/PlanCards"
import { Form, InputGroup } from "react-bootstrap"
import "./FlexiblePricing.css"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { IoArrowBackOutline } from "react-icons/io5"
import { USERTYPE } from "../../constants"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/Auth/authSlice"
import CustomBackNavButton from "../buttons/custumBackNavButton/CustomBackNavButton"
interface FlexiblePricingProps {
  userType:USERTYPE
}
function FlexiblePricing({userType}:FlexiblePricingProps) {
  const { id } = useParams()
  const seekerPlan1 = {
    userType:userType,
    planType: "Monthly",
    price: "199",
    period: "1 month",
    perks: [
      "Send unlimited Requests",
      "Connect directly with sources",
      "Unlock contact details of any source",
    ],
  }

  const seekerPlan2 = {
    userType:userType,
    planType: "Annual",
    price: "1499",
    beforeDiscount:"2400",
    period: "12 months",
    perks: [
      "Send unlimited Requests",
      "Connect directly with sources",
      "Unlock contact details of any source",
    ],
  }
  const sourcePlan1 = {
    planType: "Monthly",
    price: "199",
    period: "1 month",
    userType:userType,
    perks: [
      "Unlock 100 requests",
      "Access contact details of unlocked chats.",
      "Get preference on advertisement display"
    ],
  }
  const sourcePlan2 = {
    planType: "Annual",
    price: "1499",
    period: "12 months",
    beforeDiscount:"2400",
    userType:userType,
    perks: [
      "Unlock 2000 requests",
      "Access contact details of unlocked chats.",
      "Get preference on advertisement display"
    ],
  }
  const {providerToken , seekerToken} = useAppSelector(selectCurrentUser)
  const navigate = useNavigate()
  return (
    (providerToken || seekerToken) ? 
    <div
      className=" container align-items-center min-vh-100"
      style={{ width: "100%", maxWidth: "915px", paddingTop: "144px" }}
    >    
      <div className="text-black fs-1 text-center position-relative lh-1_2">
        Flexible plans &{" "}
        <span
          className=" fw-semibold pricing-text"
         
        >
          Pricing
        </span>
        {/* <span className="back-icon-bg price-page-back-btn custom-border border-0 "  onClick={() => navigate(-1)}>
              <IoArrowBackOutline />
            </span>  */}
        <CustomBackNavButton class_name="back-icon-bg price-page-back-btn custom-border border-0"/>
      </div>
      
      <p
        className="mx-4 text-black-50 mt-4 text-center  "
       
      >
        We believe in transparency and honesty. That's why we provide clear and
        straightforward pricing information upfront. Say goodbye to hidden fees
        and unexpected charges. With VedicHom, you'll always know exactly what
        you're paying for.
      </p>
      {/* <div className="d-flex justify-content-center align-items-center gap-2 mt-3 mb-5">
        <p className="mb-0 checkprice-text">Monthly</p> */}
      {/* <InputGroup className="w-auto">
          <Form.Check
            type="switch"
            id="custom-switch"
            className="form-switch-purple notify-toggle-purple ps-0"
          />
        </InputGroup> */}
      {/* <p className="mb-0 checkprice-text">Yearly</p>
      </div> */}

      <div className="d-flex flexible-price-wraper gap-5 justify-content-center align-items-center">
        {userType === USERTYPE.PROVIDER ? <><PlanCards {...sourcePlan1} id={id} />
        <PlanCards {...sourcePlan2} id={id} /></> :<>
        <PlanCards {...seekerPlan1} id={id} />  
        <PlanCards {...seekerPlan2} id={id} /></>
}
      </div>
    </div>
  :<Navigate to='/' replace/>
  )
}

export default FlexiblePricing
