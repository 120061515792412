import React from 'react';
import styles from './style.module.css';
import { Button } from 'react-bootstrap';
import logoIcon from '../../../../assets/img/logo.png'
import { Link } from 'react-router-dom';

const Parallax = () => {
  return (
    <main>
      <section className={`${styles.sectionBackground} ${styles.parallax}`}>
        <div className={styles.contentRight}>
          <h2>Become a Valued Member of the VedicHom Family</h2>
          <p>Join a unique platform that connects passionate seekers with expert mentors in Indian arts, culture, and wisdom. Whether mastering classical dance, exploring Vedic sciences, or deepening your yoga practice, VedicHom is your gateway to timeless knowledge.</p>

         <div className='d-flex'>
          <Link to="/results">
          <Button className={styles.ctaButton}>
          Find your Expert
          </Button>
          </Link>
          <Link to="/source-signup">
          <Button className={styles.ctaButton2}>
          Become A Source
          </Button>
          </Link> 
          </div>
        </div>
      </section>

      <section >
        <footer className={styles.footerWrapper}>
          <div className={styles.logoContainer}>
            <img src={logoIcon} alt="" />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.heading}>
              1st floor-103, C 201-202 (B), near Platina Tower, Phase 8B, Industrial Area, 
            </h2>
            <p className={styles.subText}>
            Sector 74, Sahibzada Ajit Singh
              Nagar, Punjab 160055 India.
            </p>
          </div>
        </footer>

      </section>
    </main>
  );
};

export default Parallax;
