import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { boolean } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { currentModalName, setModalName } from '../../../features/Auth/ModalHandler/modalSlice'
import { MODALNAME } from '../../../constants'
import { MdOutlineReport } from 'react-icons/md'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import ReportAdvStpOne from './ReportAdvStepOne'
import DetailesReportModal from './DetailesReportModal'
import SpinnerSecondary from '../../spinner/SpinnerSecondary'
import { useReportAdvertMutation } from '../../../features/featSeekerDashboard/seekerDashboardApi'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import SpinnerMain from '../../spinner/SpinnerMain'
import ReportFinalStep from './ReportFinalStep'
import { selectCurrentUser } from '../../../features/Auth/authSlice'
import { RxCross2 } from "react-icons/rx";
import { HiMiniInformationCircle } from 'react-icons/hi2'
import { generateRandomFileName } from '../../../utils/commonFunctions/randomFilenameGenerator'

interface reportAd {
  category: string
  message: string
  image: any
}

const ReportAdModal = () => {
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<reportAd>();
  const [reportSteps, setReportSteps] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [reportAdvert, {isSuccess, error, isLoading}] = useReportAdvertMutation()
  const { user } = useAppSelector(selectCurrentUser)
  const adId = searchParams.get('adId')
  function handleClose() { 
    searchParams.delete('modal'); 
    searchParams.delete('adId')
    setSearchParams(searchParams)
   }
  const dispatch = useAppDispatch()
  const ReportStepObject = () => {
    switch (reportSteps) {
      case 0:
        return <ReportAdvStpOne 
        handleClose={handleClose} 
        handleReportProgress={setReportSteps} 
        setValue={setValue}/>
      case 1:
        return <DetailesReportModal setValue={setValue}  handleReportProgress={setReportSteps} register={register}/>
      case 2:
        return <ReportFinalStep />
      default:
        return null
    }
  }
  useEffect(()=>{
    if(searchParams.get('modal') === MODALNAME.REPORT_AD && user==null) dispatch(setModalName(MODALNAME.LOGIN))
  },[user, searchParams])
  useEffect(()=>{setReportSteps(0)},[searchParams.get('modal')])
  useEffect(()=>{
    toast.dismiss()
    if(isSuccess) {
      setReportSteps(2)
      reset()
      setTimeout(()=>{
        handleClose()
      }, 3500)
    }
    if(error) toast.error((error as any).message)
  },[isSuccess, error])

  const handleReportAdvert = (info: any) => {
    if(adId){const formData = new FormData()
    formData.append('adId', adId);
    formData.append('category', info.category);
    formData.append('message', info.message);
    if(info?.image) formData.append('reportedImage', info.image, generateRandomFileName(info.image.type.split('/').at(1)));
    reportAdvert(formData)}
    else toast.warn('Sorry we are unable to process your request right now')
  };

  return (
    <>
      <Modal  className="w-100 .footer_border" centered show={(searchParams.get('modal') === MODALNAME.REPORT_AD) && user} onBackdropClick={handleClose} >
      <Modal.Title className=" mt-4 text-center  md_head mt-2 d-flex justify-content-center align-items-center gap-1">Report AD <HiMiniInformationCircle  size={23}/>
            <Button className='reoprt-add-cross-btn close_btn_bg mt-4 me-3' onClick={handleClose}><RxCross2 /></Button>
        </Modal.Title>
      {isLoading && <div className=' position-absolute z-3 bg-opacity-75 bg-light rounded-3 h-100 w-100 d-flex justify-content-center align-items-center'><SpinnerSecondary wrapperClass={' d-inline-flex w-100 justify-content-center align-items-center'}/></div>}
      <Form onSubmit={handleSubmit(handleReportAdvert)}>
      <ReportStepObject />
      </Form>
    </Modal>
    </>
  )
}

export default ReportAdModal