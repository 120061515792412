import React, { useEffect } from "react"
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { ImOpt } from "react-icons/im"
import styles from './aboutusPage.module.css'
import backgroundImage from "../../../assets/img/signup-page-image.png";
import OurSpecialist from "./ourSpecialist/OurSpecialist";
import AchievementStats from "./Achievement/AchievementStats";
import EmpoweringTrainers from "./empoweringTrainor/EmpoweringTrainer";
import Parallax from "./parallax/Parallax";
import { RiGlobalFill } from "react-icons/ri";
import { GiMeditation } from "react-icons/gi";
import OurNewServices from "./ourNewServices/OurNewServices";
import { PiAirplaneTakeoff, PiFlowerLotusFill, PiUsersThreeBold } from "react-icons/pi";
import { GrGrow } from "react-icons/gr";

const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <>
      {/* <div className= "header sectionMargin">
        <Container>
          <header>
            <h1>About Us</h1>
          </header>
        </Container>
      </div> */}
      <Container fluid className="m-0 p-0">
        <section className={styles.AboutSection}>
          <div className={styles.elementorBackgroundOverlay}></div>
          <div className={styles.aboutShapeBottom} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 27.8" preserveAspectRatio="none">
              <path
                className={styles.aboutUsTopShape}
                d="M283.5,9.7c0,0-7.3,4.3-14,4.6c-6.8,0.3-12.6,0-20.9-1.5c-11.3-2-33.1-10.1-44.7-5.7	s-12.1,4.6-18,7.4c-6.6,3.2-20,9.6-36.6,9.3C131.6,23.5,99.5,7.2,86.3,8c-1.4,0.1-6.6,0.8-10.5,2c-3.8,1.2-9.4,3.8-17,4.7	c-3.2,0.4-8.3,1.1-14.2,0.9c-1.5-0.1-6.3-0.4-12-1.6c-5.7-1.2-11-3.1-15.8-3.7C6.5,9.2,0,10.8,0,10.8V0h283.5V9.7z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M269.6,18c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C267.7,18.8,269.7,18,269.6,18z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M227.4,9.8c-0.2-0.1-4.5-1-9.5-1.2c-5-0.2-12.7,0.6-12.3,0.5c0.3-0.1,5.9-1.8,13.3-1.2	S227.6,9.9,227.4,9.8z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M204.5,13.4c-0.1-0.1,2-1,3.2-1.1c1.2-0.1,2,0,2,0.3c0,0.3-0.1,0.5-1.6,0.4	C206.4,12.9,204.6,13.5,204.5,13.4z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M201,10.6c0-0.1-4.4,1.2-6.3,2.2c-1.9,0.9-6.2,3.1-6.1,3.1c0.1,0.1,4.2-1.6,6.3-2.6	S201,10.7,201,10.6z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M154.5,26.7c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C152.6,27.5,154.6,26.8,154.5,26.7z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M41.9,19.3c0,0,1.2-0.3,2.9-0.1c1.7,0.2,5.8,0.9,8.2,0.7c4.2-0.4,7.4-2.7,7-2.6	c-0.4,0-4.3,2.2-8.6,1.9c-1.8-0.1-5.1-0.5-6.7-0.4S41.9,19.3,41.9,19.3z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M75.5,12.6c0.2,0.1,2-0.8,4.3-1.1c2.3-0.2,2.1-0.3,2.1-0.5c0-0.1-1.8-0.4-3.4,0	C76.9,11.5,75.3,12.5,75.5,12.6z"
              ></path>
              <path
                className={styles.aboutUsTopShape}
                d="M15.6,13.2c0-0.1,4.3,0,6.7,0.5c2.4,0.5,5,1.9,5,2c0,0.1-2.7-0.8-5.1-1.4	C19.9,13.7,15.7,13.3,15.6,13.2z"
              ></path>
            </svg>
          </div>
          <h2>About US</h2>
        </section>
        <Container>
          <Row className={styles.aboutUsSecondSection}>
            <Col lg={5}>
              <div className={styles.aboutUsSecondSectionContainer}>
                <div className={styles.aboutUsHeadingWrapper}>
                  <div className={`${styles.aboutUsHeading} ${styles.bounce}`}>
                    <h6>About Us</h6>
                  </div>
                </div>
                <div className={`${styles.aboutUsSubHeading} ${styles.fadeInLeft}`}>
                  <h2>Step into the World of Tradition, Knowledge, and Connection – Welcome to VedicHom!</h2>
                </div>
                <div className={`${styles.aboutUsTextEditor} ${styles.fadeInLeft}`}>
                  <p>
                  Born out of a vision to create a global platform for arts, culture, holistic learning, professional consultancy, and many more services. VedicHom bridges the gap between traditional wisdom keepers, skilled experts, and curious minds. We believe in the transformative power of knowledge and services to inspire and empower individuals.
                  </p>
                </div>

                <section className={styles.aboutUsSectionWrapper}>
                  <div className={styles.aboutUsSection}>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                  
                        <GiMeditation size={25} color="#f4a261" />

                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>

                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top"> The first of its kind to unite arts, culture, and community under one roof.
                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Unique Platform</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                        <RiGlobalFill size={28} color="#f4a261" />
                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>

                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top"> Enabling heritage to travel across borders and connect diverse minds.
                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Global Reach</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>

                  <div className={styles.aboutUsSection}>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                        <PiFlowerLotusFill size={28} color="#f4a261" />

                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>
                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top">Supporting experts in sharing their skills while providing seekers with access to authentic knowledge and professional guidance.
                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Empowerment for All</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                        <GrGrow size={28} color="#f4a261" />
                      
                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>
                        {/* <h2>Personalized Learning</h2> */}
                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top">Direct connections between experts and seekers for personalized and meaningful experiences.
                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Personalized Learning</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>

                  <div className={styles.aboutUsSection}>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                        <PiAirplaneTakeoff   size={28} color="#f4a261" />

                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>
                        <h2></h2>
                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top">Experts shape their own journeys—choosing their schedules, fees, and service formats to suit their expertise.
                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Freedom to Thrive</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className={styles.aboutUsColumn}>
                      <div className={styles.aboutUsImageWrapper}>
                        <PiUsersThreeBold  size={28} color="#f4a261" />

                      </div>
                      <div className={styles.aboutUsHeadingWrapper}>
                        <h2></h2>
                        <OverlayTrigger
                          placement="top"  // Position of the tooltip (top, bottom, left, right)
                          overlay={<Tooltip id="tooltip-top">Seekers and experts connect directly, fostering a journey of growth built on trust and collaboration.

                          </Tooltip>}
                        >
                          <h2 style={{ cursor: 'pointer' }}>Authentic Connections</h2>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </section>

                {/* <div className={styles.aboutUsButtonWrapper}>
                  <button className={`${styles.aboutUsButton} ${styles.bob}`}>
                    Learn More
                  </button>
                </div> */}
              </div>
            </Col>

            <Col lg={7}>
              <div className={styles.aboutUsSecondRightImgCard}>
                <div>
                  <img src={"https://img.freepik.com/free-photo/beautiful-young-woman-wearing-sari_23-2149502970.jpg?t=st=1731761559~exp=1731765159~hmac=878d482699f22ffd74dd944a1beb221e964deab5ede5c3ed425f55ae229a093c&w=360"} alt="" />

                </div>
                <div className={styles.aboutUsSecondRightImg2}>
                  <img src={"https://img.freepik.com/free-photo/woman-meditating-with-singing-bowl-incense_23-2148847559.jpg?t=st=1731761455~exp=1731765055~hmac=485d4484fecf453ea9803bfb5e2f93a26750ca729a0fbfdf382bb01bd177fd6d&w=360"} alt="" />

                </div>
                <div>
                  <img src={"https://img.freepik.com/free-photo/front-view-man-practicing-yoga_23-2148732918.jpg?t=st=1731761712~exp=1731765312~hmac=0fc3712bd9e3f59b2316977b6ad9cd2f1d2a8a6bc2e92816b49ff7cdf7dd7403&w=360"} alt="" />

                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <OurSpecialist />
        <AchievementStats />
        <EmpoweringTrainers />
        {/* <OurNewServices/> */}
        <Parallax />
      </Container>
    </>
  )
}

export default AboutUs
