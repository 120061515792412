import React from 'react';
import { AiOutlineLinkedin, AiOutlineWhatsApp } from 'react-icons/ai';
import { RiTwitterXFill } from 'react-icons/ri';
import styles from './MultiStepForm.module.css';
import { useAppSelector } from '../../app/hooks';
import { DeviceType } from '../../constants';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { detectDeviceType } from '../../utils/commonFunctions/CommonFunctions';
import useNavigateAndScroll from '../../utils/commonFunctions/navigateAndScroll';

const Step7Completion = ({handleClose, onSuccess}: any) => {
        const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)
        const navigateAndScroll = useNavigateAndScroll()
    
    const currentURL = 'https://www.vedichom.com '
    const text = 'Grow Your Teaching Reach – Join VedicHom and Connect with Knowledge Seekers Worldwide ' //TODO add content
    const thanksMessage = '.Thank you ! '
    const handleShare = (url: string) => {
      setTimeout(() => {
          window.open(url, '_blank');
      }, 300);
    };
  return (
    <div className={styles.formStep}>
                  <h2 className="text-center">
                    <span className={styles.formStepSPan}>
                      {
                    completion > 0 && completion < 50 ? "Keep going!" :
                      completion >= 50 && completion < 90 ? "Almost there!" :
                        completion >= 90 && completion < 100 ? "So close!" :
                          completion === 100 ? "Congratulations!" : ""
                  }
                    </span>
                    <br /> Your profile is {completion}% complete.
                  </h2>
                  <p  className=" text-center">
                  {
                    completion > 0 && completion < 50 ? "Add more details to improve your profile" :
                      completion >= 50 && completion < 90 ? " Just a few more steps" :
                        completion >= 90 && completion < 100 ? "Final touches needed" :
                          completion === 100 ? "Your profile is complete" : ""
                  }
                  </p>
                  <div className={styles.socialMediaContainer}>
                    <h6 className={styles.socialMediaHeading}>Share on</h6>
                    <div className={styles.socialIcons}>
                      <a
                        onClick={() => handleShare(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(currentURL)}&hashtags=vedichom`)}
                        className={styles.socialIcon}
                      >
                        <RiTwitterXFill size={40} color="black"/>
                      </a>

                      <a
                        onClick={() => handleShare(detectDeviceType()===DeviceType.Mobile?`https://wa.me/send?text=${encodeURIComponent(`${text} ${currentURL} ${thanksMessage}`)}&source=${currentURL}`:`https://web.whatsapp.com/send?text=${encodeURIComponent(`${text} ${currentURL} ${thanksMessage}`)}&source=${currentURL}`)}
                        className={styles.socialIcon}
                      >
                        <AiOutlineWhatsApp size={40} color="green" />
                      </a>

                      <a
                        onClick={() => handleShare(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentURL)}&title=${encodeURIComponent(currentURL)}&summary=${encodeURIComponent(currentURL)}&source=${encodeURIComponent('vedichome.online')}`)}
                        className={styles.socialIcon}
                      >
                        <AiOutlineLinkedin size={40} color="#0077B5"/>
                      </a>
                    </div>
                  </div>

                  <div className={`${styles.buttonGroup} justify-content-end`}>
                    <button type="submit" className={styles.submitButton}
                      onClick={() => {
                        if (completion === 100) {
                          navigateAndScroll("/source-dashboard/my-account", 1);
                          handleClose();
                        } else {
                          handleClose();
                          onSuccess((prev: number)=>1);
                        }

                      }} >
                      {completion === 100 ? "Continue" : "Close"}
                    </button>
                  </div>
                </div>
  );
};

export default Step7Completion;
