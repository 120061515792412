import React, { useEffect, useState, Suspense, useRef } from "react"
import "./GeneralInformation.css"
import { useForm, Controller } from "react-hook-form"
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useProviderPhoneVerificationMutation,
  useUpdateAccountMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import {
  useSeekerPhoneVerificationMutation,
  useUpdateSeekerProfileMutation,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"

import { toast } from "react-toastify"
import {
  contactInfoPhoneSchema,
  contactInfoEmailSchema,
} from "../../../../utils/yupSchema/Schema"
import { useAppSelector } from "../../../../app/hooks"
import { useAppDispatch } from "../../../../app/store"
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../../constants"
import {
  useResendOtpMutation,
  useResendOtpProviderMutation,
} from "../../../../features/Auth/authApi"
import ProfileIcon from "../../../../assets/img/my-profile.svg"
import InputControllWithTooltip from "./inputControllWithTooltip/InputControllWithTooltip"
import InputSelectorWithTooltip from "./inputControllWithTooltip/InputSelectorWithTooltip"
import { TbDeviceLandlinePhone } from "react-icons/tb"
import { HiOutlineMail } from "react-icons/hi"
import { BsPencil } from "react-icons/bs"
import { FaTelegram } from "react-icons/fa6"
import { PhoneInput } from "react-international-phone"
import { HiMiniDocumentCheck, HiPencilSquare } from "react-icons/hi2"
import { sanitizePhoneNumber } from "../../../../utils/commonFunctions/CommonFunctions"
import PhoneNumberInputField from "../../../phoneNumberInputField"
import { useSearchParams } from "react-router-dom"
import { selectCurrentUser } from "../../../../features/Auth/authSlice"

enum setEdit {
  none = 0,
  email = 1,
  phone = 2,
}

const ContactInformation = ({ Data, userType, isMobile, handleClose, className, title }: any) => {
  const [isPhoneEdit, setPhoneEdit] = useState(false)
  const [isEmailEdit, setEmailEdit] = useState(false)
  const [editOption, setEditOption] = useState<setEdit>(setEdit.none)
  const [searchparams, setSearchParams] = useSearchParams()
  const {user} = useAppSelector(selectCurrentUser)

  const [accountForm, { data, error, isLoading }] =
    userType === USERTYPE.SEEKER
      ? useUpdateSeekerProfileMutation()
      : useUpdateAccountMutation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm<any>({
    resolver:
      editOption === setEdit.phone
        ? contactInfoPhoneSchema
        : editOption === setEdit.email
        ? contactInfoEmailSchema
        : undefined,
  })
  const onSubmit = async (values: any) => {
    const payload =
      editOption === setEdit.phone
        ? {
            mobileNo: values.phone ? sanitizePhoneNumber(values.phone) : '',
            countryCode: values.phone ? values.countryCode : null,
          }
        : {
            email: values.email ? values.email : '',
          }
    if(editOption === setEdit.phone && payload.mobileNo==''){
      toggleEditOptions(setEdit.none)
      return}
    accountForm(payload)
  }
  useEffect(() => {
    if (data) {
      if (handleClose) handleClose()
      toast.dismiss()
      if (editOption === setEdit.phone) {
        if (data?.data?.mobileNo && !data?.data?.isMobileNoVerified) {
          toast.success(`Please verify Registered Phone number`)
          handlePhoneVerify()
        }
        // else  toast.warn(`You have not changed your phone number`)
      }
      if (editOption === setEdit.email) {
        if (data.data?.email && !data.data?.isEmailVerified) {
          toast.success(`Please verify Registered Email`)
          handleEmailVerify(data.data?.email)
        }
        // else  toast.warn(`You have not changed your email`)
      }
      toggleEditOptions(setEdit.none)
    } else {
      if (error) {
        toast.dismiss()
        toast.error((error as any)?.data?.message)
        if (editOption === setEdit.phone) {
          setValue("phone", Data?.data?.mobileNo || user?.mobileNo)
          setValue("countryCode", Data?.data?.countryCode || user?.countryCode)
          setValue("mobileNO", Data ? Data?.data?.countryCode + Data?.data?.mobileNo : user?.countryCode + user?.mobileNo)
        }
        else {
          setValue("email", Data?.data?.email || user?.email)
        }
        toggleEditOptions(setEdit.none)
      }
    }
  }, [data, error])
  useEffect(() => {
    if(Data){setValue("email", Data?.data?.email)
    setValue("phone", Data?.data?.mobileNo)
    setValue("countryCode", Data?.data?.countryCode)
    setValue("mobileNO", Data?.data?.countryCode + Data?.data?.mobileNo)}
    else if(user) {
      setValue("email", user?.email)
      setValue("phone", user?.mobileNo)
      setValue("countryCode", user?.countryCode)
      setValue("mobileNO", user?.countryCode + user?.mobileNo)}
  }, [Data, data, user])

  const auth = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  // phonr verification
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
      isError: providerPhoneError,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
      isError: seekerPhoneError,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification

  const [
    verifyEmail,
    {
      data: verifySeekerEmaildata,
      error: verifySeekerEmailError,
      isSuccess: verifySeekerEmailSuccess,
      isLoading: verifySeekerEmailLoading,
    },
  ] = useResendOtpMutation()
  const [
    verifyProviderEmail,
    {
      data: verifyProviderEmaildata,
      error: verifyProviderEmailError,
      isSuccess: verifyProviderEmailSuccess,
      isLoading: verifyProviderEmailLoading,
    },
  ] = useResendOtpProviderMutation()

  const handleEmailVerify = async (email?: string) => {
    try {
      if (userType === USERTYPE.PROVIDER) {
        if (!verifyProviderEmailLoading) {
          verifyProviderEmail({ email: email || auth?.user?.email })
            .unwrap()
            .then(() => {
              dispatch(
                setModalName(
                  MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
                ),
              )
            })
            .catch(() => {
              toast.error(
                "Sorry, we are unable to deliver your OTP please try again",
              )
            })
        }
      } else {
        if (!verifySeekerEmailLoading) {
          verifyEmail({ email: email || auth?.user?.email })
            .unwrap()
            .then(() => {
              dispatch(
                setModalName(
                  MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
                ),
              )
            })
            .catch(() => {
              toast.error(
                "Sorry, we are unable to deliver your OTP please try again",
              )
            })
        }
      }
    } catch (error) {
      console.error("Error while handling email verification:", error)
      toast.error("An unexpected error occurred. Please try again later.")
    }
    if (handleClose) handleClose()
  }

  const handlePhoneVerify = async () => {
    if (userType === USERTYPE.PROVIDER) {
      if (!phoneVerificationLoading) phoneVerify()
    } else {
      if (!seekerphoneVerificationLoading) seekerphoneVerify()
    }
    if (handleClose) handleClose()
  }

  useEffect(() => {
    if (phoneVerificationSuccess || seekerphoneVerificationSuccess)
      dispatch(
        setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
      )
    else if (providerPhoneError || seekerPhoneError) {
      toast.dismiss()
      toast.error("Something went Wrong, please try again later")
    }
  }, [seekerphoneVerificationSuccess, phoneVerificationSuccess])

  function handlePhonenumberUpdate(phone: string, country: any) {
    // const countryCode = "+" + country.country.dialCode
    // const phoneNum = phone.replace(country, "")
    setValue("phone", phone, {shouldValidate: true})
    setValue("countryCode", country)
    if(phone=='') clearErrors('phone')
  }

  const focusEnterbyID = (ref?: any) => {
    // TODO: take input as focoused item here 
  };
  function toggleEditOptions(editOption: setEdit): void {
    if (editOption === setEdit.none) {
      setEmailEdit(false)
      setPhoneEdit(false)
      setEditOption(editOption)
      return
    }
    toast.dismiss()
    if (editOption === setEdit.email) {
      if (isPhoneEdit) toast.warn("Please, finish changing phone number first ")
      else {
        setEmailEdit(true)
        setEditOption(editOption)
        focusEnterbyID()
      }
    }
    if (editOption === setEdit.phone) {
      if (isEmailEdit)
        toast.warn("Please, finish changing your email at first ")
      else {
        setPhoneEdit(true)
        setEditOption(editOption)
        focusEnterbyID()
      }
    }
  }

  function removeFocus() {
    searchparams.delete('focus')
    setSearchParams(searchparams)
  }
  return (
    <div id="accountInformation">
      <div className={`${className ? className : ''} block-card  mb-4 pb-2`}>
        <h2 className="card-heading position-relative">
          {title?
          title
          :<>
          Account Information
          <img
            src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/account-info.png"
            className="ms-2"
            alt="profile-icon"
            height={20}
            width={20}
            title="Account Information"
          />
          </>}
        </h2>
        <Form className="form-general" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Row className="g-1">
              <Col xs={10}>
                <Form.Group id="verificationCardEmail"   className={`phoneFormGroup account-field justify-content-start PhoneInputInput_bg-light ${searchparams.get('focus')=='verificationCardEmail'?' border-blink':''}`}
                onFocus={removeFocus}>
                  <HiOutlineMail size={20} />
                  <Form.Control
                    {...register("email")}
                    name="email"
                    disabled={!isEmailEdit}
                    type="email"
                    placeholder="Email Address"
                    className={`account-field-input pe-7 verify-email-sm-input ${searchparams.get('focus')=='verificationCard'?' bg-danger':''}`}
                  />
                  {(Data?.data?.email || user?.email) &&
                    (auth?.user?.isEmailVerified ? (
                      <span className="myaccount-form-emailverify-card">
                        {(editOption===setEdit.email)?'':'Verified'}
                      </span>
                    ) : (
                      <span
                        className="myaccount-form-phoneverify-card"
                        onClick={() => {
                          handleEmailVerify()
                        }}
                      >
                        {(editOption===setEdit.email)?'':'Please Verify'}
                      </span>
                    ))}
                </Form.Group>
              </Col>
              <Col
                xs={2}
                className=" justify-content-center align-items-center"
              >
                {isEmailEdit && (
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    className="submit-card-btn schedule-session-btn"
                    type="submit"
                  >
                    {/* Add a loading state if needed */}
                    <div className="d-xl-none text-center ">
                      <HiMiniDocumentCheck size={22} />
                    </div>
                    {editOption == setEdit.email && isLoading ? <Spinner size="sm"/>
                      :<span className="d-none d-xl-block">Save</span>}
                  </Button>
                )}
                {!isEmailEdit && (
                  <Button
                    variant="primary"
                    className="submit-card-btn schedule-session-btn"
                    onClick={() => toggleEditOptions(setEdit.email)}
                  >
                    <div className="d-xl-none text-center ">
                      <HiPencilSquare size={22} />
                    </div>
                    {editOption == setEdit.email && isLoading ? <Spinner size="sm"/>
                      :<span className="d-none d-xl-block">{(Data?.data?.email||user?.email)?'Edit':'Add'}</span>}
                  </Button>
                )}
              </Col>
            </Row>

            {errors?.email?.message && (
              <span className="error">{(errors as any)?.email?.message}</span>
            )}
          </div>
        </Form>
        <Form className="form-general" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Row className="g-1">
              <Col xs={10}>
                <Form.Group id="verificationCardPhone" className={`phoneFormGroup pe-10 account-field justify-content-start PhoneInputInput_bg-light ${searchparams.get('focus')=='verificationCardPhone'?' border-blink':''}`}
                onFocus={removeFocus}
                >
                  <Suspense>
                    <Controller
                      name="mobileNO"
                      control={control}
                      render={({ field }) => (
                        <PhoneNumberInputField 
                        className="flag-sm-input"
                          defaultCountryCode={Data?.data?.countryCode || user?.countryCode}
                          defaultCountry="in"
                          disabled={!isPhoneEdit}
                          placeholder="Enter the phone number"
                          onChange={(phone, country) =>
                            handlePhonenumberUpdate(phone, country)
                          }
                          controller={field}
                         />
                      )}
                    />
                  </Suspense>
                  {(Data?.data?.mobileNo || user?.mobileNo) &&
                    (auth?.user?.isMobileNoVerified ? (
                      <div className="myaccount-form-emailverify-card">
                        {(editOption===setEdit.phone)?'':'Verified'}
                      </div>
                    ) : (
                      <div
                        className="myaccount-form-phoneverify-card"
                        onClick={handlePhoneVerify}
                      >
                        {(editOption===setEdit.phone)?'':'Please Verify'}
                      </div>
                    ))}
                </Form.Group>
              </Col>
              <Col
                xs={2}
                className=" justify-content-center align-items-center"
              >
                {isPhoneEdit && (
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    className="submit-card-btn schedule-session-btn"
                    type="submit"
                  >
                    {/* Add a loading state if needed */}
                    <div className="d-xl-none text-center ">
                      <HiMiniDocumentCheck size={22} />
                    </div>
                    {editOption == setEdit.phone && isLoading ? <Spinner size="sm"/>
                    :<span className="d-none d-xl-block">Save</span>}
                  </Button>
                )}
                {!isPhoneEdit && (
                  <Button
                    variant="primary"
                    className="submit-card-btn schedule-session-btn"
                    onClick={() => toggleEditOptions(setEdit.phone)}
                  >
                    <div className="d-xl-none text-center ">
                      <HiPencilSquare size={22} />
                    </div>
                    {editOption == setEdit.phone && isLoading ? <Spinner size="sm"/>
                    :<span className="d-none d-xl-block">{(Data?.data?.mobileNo || user?.mobileNO)?'Edit':'Add'}</span>}
                  </Button>
                )}
              </Col>
            </Row>
            {errors?.phone && (
              <span className="error">{(errors as any)?.phone?.message}</span>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ContactInformation
