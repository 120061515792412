import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./CreateAdvert.css"
import { useAppDispatch } from "../../../../app/store"
import {
  updateFunnel,
  setIsStepValid,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { useAppSelector } from "../../../../app/hooks"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { toast } from "react-toastify"
import { convertToEmbedUrl } from "../../../../utils/youtybeURLvalidator/handleYoutubeUrl"
import ErrorPage from "../../../ErrorPage"
import  brokeUrlIcn from "../../../../assets/img/yt-broke-url.svg"
import CustomTooltip from "../../../buttons/CustomTooltip"
import { serviceTypeValue, youtubevideoState } from "../../../../Types"
import CustomTextarea, { SECTION_KEY } from "../../../hoc/AutofillInputField"

interface SelectDetails {
  aboutClass: string
  addTitle: string
  aboutYou: string
  youtubeLink: string
}
const TITLE_MinWords = 3
const TITLE_MaxWords = 10
const TITLE_MaxChar = 100
const maxLetters = 50
const minLetterCount = 10
const CHARECTER_LIMIT = 500

const CreateAdvert = () => {
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState({title: '', aboutClass: '', aboutYou: ''})
  const [focusField, setFocusField] = useState<string>("")
  const [youtubeLink, setYoutubeLink] = useState<youtubevideoState>({url: '',isValid: true})

  const { isStepValid } = useAppSelector((state) => state.providerFunnel)
  const [infoactive, setInfoActive] = useState(false)
  const handleInfoActive = () => {setInfoActive(!infoactive)}
  const providerFunnelData = useAppSelector((state) => state.providerFunnel.providerFunnelData)
  const handleInputChange = (
    value: string,
    name: any,
  ) => {
    var inputValue = value
    const maxWordLength = name === "title" ? TITLE_MaxWords : maxLetters
    const maxCharLimit = name === "title" ? TITLE_MaxChar : CHARECTER_LIMIT
    const minWordsCount = name === "title" ? TITLE_MinWords : minLetterCount
    if (inputValue.length > maxCharLimit) {
      toast.dismiss()
      toast.error("You've reached the maximum character limit for this field.")
      inputValue = inputValue.substring(0, maxCharLimit)
    }
    var words = inputValue.trim().split(/\s+/).filter(Boolean)

    if (name === "youtubeLink") {
      const newYTvalue = convertToEmbedUrl(inputValue)
      dispatch(updateFunnel({ [name]: newYTvalue.url }))
      setYoutubeLink(newYTvalue)
    }
    else {
      if(words.length > maxWordLength) {
        words = words.slice(0, maxWordLength)
        inputValue = words.join(" ")
      }
      setFormData(prev => ({ ...prev, [name]: inputValue }));
      dispatch(updateFunnel({ [name]: inputValue }))
    }
  }


  useEffect(()=>{
    handleInputChange(providerFunnelData?.aboutYou, 'aboutYou')
    handleInputChange(providerFunnelData?.title, 'title')
    handleInputChange(providerFunnelData?.aboutClass, 'aboutClass')
    handleInputChange(providerFunnelData?.youtubeLink, 'youtubeLink')
    const titleWordcount = providerFunnelData.title.trim().split(/\s+/).filter(Boolean).length;
    const classWordCount = providerFunnelData.aboutYou.trim().split(/\s+/).filter(Boolean).length
    const aboutWordCount = providerFunnelData.aboutClass.trim().split(/\s+/).filter(Boolean).length
    // if (
    //   (titleWordcount >= TITLE_MaxWords || titleWordcount < TITLE_MinWords) &&
    //   (classWordCount >= minLetterCount || classWordCount < maxLetters) &&
    //   (aboutWordCount >= minLetterCount || aboutWordCount < maxLetters) &&
    //   providerFunnelData.title.length >= TITLE_MaxChar &&
    //   providerFunnelData.aboutYou.length >= CHARECTER_LIMIT &&
    //   providerFunnelData.aboutClass.length >= CHARECTER_LIMIT
    // ) dispatch(setIsStepValid(false))
    // else dispatch(setIsStepValid(true))
  },[])

  useEffect(()=>{
    const titleWordcount = formData.title.split(/\s+/).filter(Boolean).length;
    const classWordCount = formData.aboutYou.split(/\s+/).filter(Boolean).length
    const aboutWordCount = formData.aboutClass.split(/\s+/).filter(Boolean).length
    if ((titleWordcount <= TITLE_MaxWords && titleWordcount >= TITLE_MinWords && formData.title.length < TITLE_MaxChar) &&
      (classWordCount <= maxLetters && classWordCount >= minLetterCount && formData.aboutYou.length < CHARECTER_LIMIT) &&
      (aboutWordCount <= maxLetters && aboutWordCount >= minLetterCount && formData.aboutClass.length < CHARECTER_LIMIT)) dispatch(setIsStepValid(false))
    else dispatch(setIsStepValid(true))
  },[formData])

  return (
    <>
      <section className="py-lg-1 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="pb-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      Add Title for your Ad{" "}
                      <span className=" text-danger">*</span>
                      <CustomTooltip content={"Please note that your input should be between 5 to 10 words"} />
                    </p>
                    <div className="letter-count-info font-14 d-flex">
                      {formData?.title.split(/\s+/).filter(Boolean).length}/{TITLE_MaxWords}
                    </div>
                  </div>
                  <CustomTextarea
                    value={formData.title}
                    onChange={handleInputChange}
                    setFocusField={setFocusField}
                    name="title"
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    style={{ height: "151px" }}
                    vedicSubject={providerFunnelData?.vedicSubject}
                    subSkill={providerFunnelData?.skillSet}
                    section={SECTION_KEY.TITLE}
                  />
                  {/* <Form.Control
                    as="textarea"
                    rows={10}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,"title")
                    }
                    value={formData.title}
                    // maxLength={maxLetterCount}
                    onFocus={() => setFocusField("Title")}
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    onBlur={() => setFocusField("")}
                    style={{
                      height: "151px",
                    }}
                  /> */}
                  {focusField !== "Title" &&
                    formData?.title.length > 1 &&
                    formData?.title.split(/\s+/).filter(Boolean).length < 3 && (
                      <div
                        className="mt-2 font-14"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 3 Words are required*
                      </div>
                    )}
                </div>
                <div className="pb-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      {providerFunnelData.services[0]===serviceTypeValue.Source?`About the Class`:`About the services`} <span className=" text-danger">*</span>
                      <CustomTooltip content={"Please note that your input should be between 10 to 50 words"} />
                    </p>
                    <div className="letter-count-info d-flex font-14">
                      {formData.aboutClass.split(/\s+/).filter(Boolean).length}/{maxLetters}
                    </div>
                  </div>
                  <CustomTextarea
                    value={formData.aboutClass}
                    onChange={handleInputChange}
                    setFocusField={setFocusField}
                    name="aboutClass"
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    style={{ height: "151px"}}
                    vedicSubject={providerFunnelData?.vedicSubject}
                    subSkill={providerFunnelData?.skillSet}
                    section={SECTION_KEY.ABOUT_CLASS}
                  />
                  {/* <Form.Control
                    as="textarea"
                    rows={10}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,"aboutClass"
                      )
                    }
                    value={formData.aboutClass}
                    // maxLength={maxLetterCount}
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    onFocus={() => setFocusField("aboutClass")}
                    onBlur={() => setFocusField("")}
                    style={{
                      height: "151px",
                    }}
                  /> */}
                  {focusField !== "aboutClass" &&
                    formData.aboutClass.length >=  1 &&
                    formData.aboutClass.split(/\s+/).filter(Boolean).length < 10 && (
                      <div
                        className="font-14 mt-2"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 10 words are required*
                      </div>
                    )}
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      About You <span className=" text-danger">*</span>
                      <CustomTooltip content={"Please note that your input should be between 10 to 50 words"} />
                    </p>
                    <div className="letter-count-info font-14 d-flex">
                      {formData.aboutYou.split(/\s+/).filter(Boolean).length}/{maxLetters}
                    </div>
                  </div>
                  <CustomTextarea
                    value={formData.aboutYou}
                    onChange={handleInputChange}
                    setFocusField={setFocusField}
                    name="aboutYou"
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    style={{ height: "151px" }}
                    vedicSubject={providerFunnelData?.vedicSubject}
                    subSkill={providerFunnelData?.skillSet}
                    section={SECTION_KEY.ABOUT_YOU}
                  />
                  {/* <Form.Control
                    as="textarea"
                    // rows={10}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,"aboutYou"
                      )
                    }
                    value={ formData.aboutYou}
                    // maxLength={maxLetterCount}
                    onFocus={() => setFocusField("aboutYou")}
                    onBlur={() => setFocusField("")}
                    className="shadow-none rounded-4 font-14 py-2 custom-scrollbar resize--none ad-create-area"
                    style={{
                      height: "151px",
                    }}
                  /> */}
                  {focusField !== "aboutYou" &&
                    formData.aboutYou?.length >= 1 &&
                    formData.aboutYou.split(/\s+/).filter(Boolean).length < 10 && (
                      <div
                        className="font-14 mt-2"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 10 words are required*
                      </div>
                    )}
                </div>
                <div className="mt-3 mb-5">
                  <p className="get-started-text fw-normal">
                    Upload youtube link{" "}
                    <CustomTooltip content={"This is an optional field that supports youtube video link"} />
                  </p>
                  <p className=" text-black-50">
                    Upload your youtube channel link so that students can visit
                    and see your work{" "}
                  </p>
                  {(youtubeLink.isValid) && <div>
                    <iframe
                      width="100%"
                      height="315"
                      src={ youtubeLink.url  }
                      title="YouTube video player"
                      className="video-ad border-none"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>}
                 
                  <Form.Control
                    type="text"
                    value={youtubeLink.url}
                    onChange={(e) =>handleInputChange(e.target.value,"youtubeLink")}
                    placeholder="paste URL"
                    className="py-3 shadow-none font-14 funnel-input-focus"
                    style={{
                      borderRadius: "10px",
                      paddingLeft: "1rem",
                    }}
                  />
                  {(!youtubeLink.isValid && youtubeLink.url !== '') && <>
                    <div className=" text-danger ">This url seems broken !</div>
                  </>}
                </div>
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                    Did You Know?
                    </h3>
                  </div>
                  <p className="font-lg-16 mb-0">
                 <strong>Earn More 💰 — </strong> Unlock new income streams by attracting a global audience of seekers.
                  </p>
                  {/* <p className="font-lg-16 mb-0">
                  Crafting an ad title with the right keywords will enhance your visibility online, leveraging our platform’s powerful algorithm to attract more users.
                  </p> */}
                  <ul>
                    <li>
                    <strong>Crafting an ad title</strong> with the right keywords will enhance your visibility online, leveraging our platform’s powerful algorithm to attract more users.
                    </li>
                    <li>
                    <strong>Developing engaging titles</strong> and descriptions will boost user interaction with your ads, increasing the likelihood of attracting new learners.
                    </li>
                  </ul>
            
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default CreateAdvert
