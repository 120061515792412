// hotjarUtils.ts
import Hotjar from '@hotjar/browser';
import { InitOpts } from '@hotjar/browser/dist/utils';

type UserInfo = Record<string | number, string | number | Date | boolean>;

/**
 * Initializes Hotjar with the given site ID, version, and options.
 * @param hotjarId - Hotjar Site ID
 * @param hotjarVersion - Hotjar Version
 * @param opts - Optional configuration options
 * @returns boolean - Indicates if initialization was successful
 */
export const initHotjar = (hotjarId: number, hotjarVersion: number, opts?: InitOpts): boolean => {
    return Hotjar.init(hotjarId, hotjarVersion, opts);
};

/**
 * Tracks an event with Hotjar.
 * @param actionName - Name of the action to track
 * @returns boolean - Indicates if the event was successfully tracked
 */
export const trackHotjarEvent = (actionName: string): boolean => {
    return Hotjar.event(actionName);
};

/**
 * Identifies a user in Hotjar with their ID and associated information.
 * @param userId - Unique identifier for the user
 * @param userInfo - Object containing user details
 * @returns boolean - Indicates if the user identification was successful
 */
export const identifyHotjarUser = (userId: string | null, userInfo: UserInfo): boolean => {
    return Hotjar.identify(userId, userInfo);
};

/**
 * Notifies Hotjar of a state change (e.g., route navigation).
 * @param relativePath - The new relative path of the application
 * @returns boolean - Indicates if the state change was successfully tracked
 */
export const hotjarStateChange = (relativePath: string): boolean => {
    return Hotjar.stateChange(relativePath);
};

/**
 * Checks if Hotjar is ready for use.
 * @returns boolean - Indicates if Hotjar is ready
 */
export const isHotjarReady = (): boolean => {
    return Hotjar.isReady();
};
