import { useEffect, useRef } from 'react';
import { 
    initHotjar, 
    trackHotjarEvent, 
    identifyHotjarUser, 
    hotjarStateChange, 
    isHotjarReady 
} from './hotjarUtils';

const useInitHotjar = (siteId: number, version: number, debug = false) => {
    const isInitialized = useRef(false); // Ensure Hotjar is initialized only once
    useEffect(() => {if(isHotjarReady()) hotjarStateChange(window.location.pathname)}, [window.location])

    useEffect(() => {
        if (!isInitialized.current) {
            initHotjar(siteId, version, {
                debug: debug
              });
            isInitialized.current = true;
        }
    }, [siteId, version]);
};

export default useInitHotjar;
