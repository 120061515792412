type SubObject = {
    [key: string]: any;
};

type TimeObject = {
    [key: string]: SubObject;
};

function checkConditions(timeObject: TimeObject, keyToCheck: string): boolean {
    // Ensure the keyToCheck exists in the timeObject
    try {
    if (timeObject.hasOwnProperty(keyToCheck)) {
        const subObject = timeObject[keyToCheck];

        // Check if subObject is actually an object and not null
        if (typeof subObject === 'object' && subObject !== null) {
            // Condition 1: subObject is an empty object
            const isCondition1 = Object.keys(subObject).length === 0;

            // Condition 2: subObject contains hourlyFee with null value
            const isCondition2 = subObject.hasOwnProperty('hourlyFee') && subObject.hourlyFee === null;

            // Condition 3: subObject contains only a single null value
            const isCondition3 = Object.keys(subObject).length === 1 && subObject[Object.keys(subObject)[0]] === null;

            // Condition 4: subObject contains hourlyFee with a non-null value
            const isCondition4 = subObject.hasOwnProperty('hourlyFee') && subObject.hourlyFee !== null;

            // Differentiate between conditions 1-3 and condition 4
            if (isCondition1 || isCondition2 || isCondition3) {
                return false;
            } else if (isCondition4) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }}
    catch {
        return false;
    }
}

function isPricesStructureEmpty(prices: any) {
    if (!prices || typeof prices !== 'object') return false;
  
    const keysToCheck = ['realTime', 'virtual'];
    for (const key of keysToCheck) {
      if (
        !prices[key] ||
        typeof prices[key] !== 'object' ||
        !prices[key].group ||
        !prices[key].private ||
        Object.keys(prices[key].group).length > 0 ||
        Object.keys(prices[key].private).length > 0
      ) {
        return false;
      }
    }
  
    return true;
  }
  

export { checkConditions, isPricesStructureEmpty }