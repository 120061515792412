import React from "react"
import ReactDOM from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ErrorBoundary from "./errorBoundary/ErrorBoundary"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import { globalToastStyle, progressStyle } from "./utils/toast/ToastStyles"
import ErrorPage from "./components/ErrorPage"
import { initializeGA } from "./components/googleAnalyticsMeasure/event"
// import ReactGA from 'react-ga4';

// const MEASUREMENT_ID = import.meta.env.VITE_MEASUREMENT_ID;
// const MEASUREMENT_ID1 = import.meta.env.VITE_MEASUREMENT_ID1;

// ReactGA.initialize([
//   { trackingId: MEASUREMENT_ID }, 
//   { trackingId: MEASUREMENT_ID1 },
//   { trackingId: 'G-5HDKP4003B' }
// ], { testMode: true });
initializeGA('G-QYJQ7679GQ')
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorPage/>}>
      <Provider store={store}>
        <App />
        <ToastContainer
          position="bottom-right" 
          toastStyle={globalToastStyle}
          autoClose={1500}
          progressStyle={progressStyle}
          closeButton={false}
          limit={3}
          style={{ zIndex: 999999999 , position: "fixed", overflow: 'hidden' }}
        />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
)
