import React from 'react';
import styles from './style.module.css';

const OurSpecialist: React.FC = () => {
  return (
    <div className={styles.OurSpecialistContainer}>
      <div className={styles.headingSection}>
        <p className={styles.subHeading}>Our Specialties</p>
        <h2 className={styles.mainHeading}>We're the Keepers of Tradition</h2>
        <p className={styles.description}>
        We unite enthusiastic seekers with authentic experts, celebrating the preservation and sharing of India’s rich arts and traditions to inspire personal growth and deepen cultural appreciation.</p>
      </div>

      <div className={styles.centerContent}>
        {/* <div className={`${styles.card} ${styles.topLeftCard}`}>
          <div className={styles.icon}>🧘‍♀️</div>
          <h3 className={styles.cardTitle}>Advertise Like a Pro</h3>
          <p className={styles.cardDescription}>Teachers can create their own ads to showcase their expertise, which VedicHom will
            promote across social media, Google Ads, and digital platforms, ensuring maximum
            visibility to a global audience.</p>
        </div> */}
      <div className={`${styles.newCardAnimation} ${styles.topLeftCard}`}>
      <div className={styles.newCardAnimationFront}>
         <div className={styles.icon}>🧘‍♀️</div>
        <p className={styles.newCardAnimationTitle}>Advertise Like a Pro</p>
        {/* <p className={styles.newCardAnimationSubtitle}>fsdfdsfdsf</p> */}
         </div>
      <div className={styles.newCardAnimationBack}>
        <p>Sources can create their own ads to showcase their expertise, which VedicHom will
            promote across social media, Google Ads, and digital platforms, ensuring maximum
            visibility to a global audience.</p>
      </div>
    </div>
    <div className={`${styles.newCardAnimation} ${styles.topRightCard}`}>
      <div className={styles.newCardAnimationFront}>
         <div className={styles.icon}>🧘🏻</div>
        <p className={styles.newCardAnimationTitle}>Flexible Fee Structure for Sources</p>
        {/* <p className={styles.newCardAnimationSubtitle}>fsdfdsfdsf</p> */}
         </div>
      <div className={styles.newCardAnimationBack}>
        <p>Set your own fees and schedules, giving you full control over your services while aligning with your unique expertise and goals.
</p>
      </div>
    </div>

        {/* <div className={`${styles.card} ${styles.topRightCard}`}>
          <div className={styles.icon}>🧘‍♀️</div>
          <h3 className={styles.cardTitle}> Flexible Fee Structure for Teachers</h3>
          <p className={styles.cardDescription}>Connection, awareness, balance, peace.</p>
        </div> */}

        <div className={styles.centerImage}>
          <img
            src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/aboutpage-meditation-img.png"
            alt="Yoga Pose"
            className={styles.image}
          />
        </div>
        <div className={`${styles.newCardAnimation} ${styles.bottomLeftCard}`}>
      <div className={styles.newCardAnimationFront}>
         <div className={styles.icon}>📖</div>
        <p className={styles.newCardAnimationTitle}>Personalized Services for Seekers </p>
        {/* <p className={styles.newCardAnimationSubtitle}>fsdfdsfdsf</p> */}
         </div>
      <div className={styles.newCardAnimationBack}>
        <p>Seekers can select from a range of experts for consultancy and learning, customizing their journey to match their pace, interests, and goals.</p>
      </div>
    </div>

    <div className={`${styles.newCardAnimation} ${styles.bottomRightCard}`}>
      <div className={styles.newCardAnimationFront}>
         <div className={styles.icon}>🫴</div>
        <p className={styles.newCardAnimationTitle}>Access to Authentic Services</p>
        {/* <p className={styles.newCardAnimationSubtitle}>fsdfdsfdsf</p> */}
         </div>
      <div className={styles.newCardAnimationBack}>
        <p>Seekers access verified experts in Indian arts, culture, and traditions for both learning and consultancy, ensuring a credible experience.
             </p>
      </div>
     </div >
       
      </div>
    </div>
  );
};

export default OurSpecialist;
