import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './MultiStepForm.module.css';
import GeneralInformation from '../dasboard/commonDashboard/generalInformation/GeneralInformation';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { Form, Spinner } from 'react-bootstrap';
import { SearchLocationInputBar } from '../modals/editProfile/locationUpdate/EditLocModal';
import { toast } from 'react-toastify';
import { providerDashboardMyAccountSchema } from '../../utils/yupSchema/Schema';
import { useUpdateSeekerProfileMutation } from '../../features/featSeekerDashboard/seekerDashboardApi';
import { useUpdateAccountMutation } from '../../features/featProviderDashboard/FeatProviderApi';

const Step4PostalAddress = ({ onSuccess, isComplete, stepKey }: any) => {
    const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)
    const [
        updateAccount,
        { isLoading: formloading, error: bioError },
    ] = useUpdateAccountMutation();
    const [
        updateSeeker,
        { isLoading: seekerUpdating, isSuccess: seekerSuccess, error: seekerError },
    ] = useUpdateSeekerProfileMutation()
    const {
        handleSubmit,
        setValue,
        register,
        getValues,
        formState: { errors },
    } = useForm<any>({
        resolver: providerDashboardMyAccountSchema,
    })
    const [defaultLocation, _defaultLocation] = useState<string>(user?.postalAddress ? user?.postalAddress : "")
    const setLocData = (place: string, city?: string) => {
        setValue("postalAddress", place)
        _defaultLocation(place)
    }
    const handleLocationUpdate = (values: any) => {
        const trimmedValue = values.postalAddress.trim()
        if (user?.postalAddress === trimmedValue) {
            toast.dismiss()
            onSuccess((prev: number)=>prev+1)
            return
        }
        if (trimmedValue === "" || values.postalAddress.length < 3) {
            toast.dismiss()
            toast.error("please enter a valid address")
        } else {
            const payload = {
                postalAddress: values.postalAddress,
            }
            if (providerToken) updateAccount(payload).unwrap().then((res: any) => { onSuccess((prev: number)=>prev+1); toast.success('Location Updated') })
                .catch((err: any) => { toast.error(err?.data?.message) });
            else if (seekerToken) updateSeeker(payload).unwrap().then((res: any) => { onSuccess((prev: number)=>prev+1); toast.success('Location Updated') })
                .catch((err: any) => { toast.error(err?.data?.message) });
        }
    }

    return (
        <>
            <Form className={styles.formStep}
                onSubmit={handleSubmit(handleLocationUpdate)}>
                <h2>Add Your Postal</h2>
                <p className={styles.subtitle}>
                    Add your Postal address
                </p>
                <div>
                    <SearchLocationInputBar
                        isdisabled={false}
                        defaultValue={defaultLocation}
                        setLocation={setLocData}
                        placeholder={
                            "Please enter your postal address"
                        }
                    />
                    <span className="text-danger">
                        {(errors as any)?.postalAddress?.message}
                    </span>
                </div>
                <div className={styles.buttonGroup}>
                    <button
                        type="button"
                        onClick={() => onSuccess((prev: number)=>prev-1)}
                        className={styles.backButton}
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        className={styles.nextButton}
                    >
                        {(seekerUpdating || formloading) && <Spinner size="sm" className="me-2" />}
                        {(defaultLocation !== user?.postalAddress) ? 'Save & Continue' : 'Continue'}
                    </button>
                </div>
            </Form>
        </>
    );
};

export default Step4PostalAddress;
