import React, { useEffect, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import styles from './MultiStepForm.module.css';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { BiSolidFilePdf } from 'react-icons/bi';
import { OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { MdCloudDone } from 'react-icons/md';
import { convertFileSize } from '../../utils/commonFunctions/convertFileSize';
import { toast } from 'react-toastify';
import { useUploadProfileDocumentsMutation } from '../../features/featProviderDashboard/FeatProviderApi';
import { extractFilename } from '../../utils/commonFunctions/getFileDisplayName';
import { useUploadDocumentsMutation } from '../../features/featSeekerDashboard/seekerDashboardApi';

interface CertificationFile {
  name: string;
  file: File;
  localOnly: boolean;
  url?: string;
}

interface FormData {
  image: File | null;
  bio: string;
  certifications: CertificationFile[];
  socialMedia: {
    twitter: string;
    linkedin: string;
    github: string;
  };
}

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const Step3IdDoc = ({ onSuccess, isComplete, stepKey }: any) => {
  const { user, providerToken } = useAppSelector(selectCurrentUser);

  const [formData, setFormData] = useState<FormData>({
    image: null,
    bio: "",
    certifications: [],
    socialMedia: {
      twitter: "",
      linkedin: "",
      github: "",
    },
  });

  const [updateDocument, { isLoading: loadingDocument, error: documentError }] = 
  providerToken ? useUploadProfileDocumentsMutation() : useUploadDocumentsMutation();
  const [replacedDocs, setReplacedDocs] = useState<string>('');
  const [isUploadingDocs, setIsUploadingDocs] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileSize, setFileSize] = useState("");

  function handleDocumentUpdate(): void {
    const payload = new FormData();
    if (formData.certifications.length > 0) {
      payload.append('credential', formData.certifications[0].file);
    }

    if (replacedDocs) {
      // uploadeAtProvider({ delKey: 'delCreds=1' }) 
      payload.append('certiUrl', replacedDocs);
    }

    if (!isUploadingDocs) {
      onSuccess((prev: number) => prev + 1);
      return;
    }

    updateDocument({ data: payload }).unwrap()
      .then((res: any) => {
        toast.success("Your document updated successfully");
        setReplacedDocs('');
        onSuccess((prev: number) => prev + 1);
        setIsUploadingDocs(false);
      })
      .catch((err: any) => {
        toast.error("Failed to upload document");
      });
  }

  const handleCertificationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formData.certifications.length >= 1) {
      setErrorMessage("You can only upload 1 certification, Please remove the existing file");
      return;
    }

    const files = e.target.files;

    if (files) {
      const filesArray = Array.from(files);

      const nonPdfFiles = filesArray.filter((file) => file.type !== "application/pdf");
      const oversizedFiles = filesArray.filter((file) => file.size > MAX_FILE_SIZE);

      if (nonPdfFiles.length > 0) {
        setErrorMessage("Only PDF files are allowed.");
      } else if (oversizedFiles.length > 0) {
        setFileSize(`Some files exceed the size limit of ${MAX_FILE_SIZE / (1024 * 1024)} MB.`);
      } else {
        setErrorMessage('');
        setFileSize('');

        const newCertification = {
          name: "",
          file: filesArray[0],
          localOnly: true
        };

        setFormData({
          ...formData,
          certifications: [newCertification],
        });
        setIsUploadingDocs(true);
      }
    }
  };

  useEffect(() => {
    if (user?.credentials) {
      const certifications = {
        name: extractFilename(user.credentials, user?._id),
        file: user.credentials,
        url: user.credentials,
        localOnly: false
      };
      setFormData((prev) => ({ ...prev, certifications: [certifications] }));
    }
  }, [user]);

  return (
    <div className={styles.formStep}>
      <h2>Upload Your Identity Proof</h2>
      <p className={styles.subtitle}> Your identity proof will only be used for verification purposes. </p>
      <div className={`${styles.certificationsContainer}`}>
        <div className={`${styles.pdfContainer} custom-scrollbar`}>
          {formData.certifications.map((cert, index) => (
            <div key={index} className={styles.certificationItem}>
              <BiSolidFilePdf className={`${styles.fileIcon}`} />
              <div className={`${styles.certDetails} d-flex flex-row justify-content-center align-items-center`}>
                {cert.localOnly
                  ? <span className={styles.pill_text}><FaUpload /></span>
                  : <span className={styles.pill_text_done}><MdCloudDone /></span>}
                {cert.localOnly
                  ? <span className={styles.fileName}>
                      <OverlayTrigger placement="top" trigger={'hover'} overlay={<Popover><PopoverBody>{cert.file.name}</PopoverBody></Popover>} >
                        <span>{cert.file.name}</span>
                      </OverlayTrigger>
                      <br />
                      <span className="px-1">{convertFileSize(cert.file.size, "Bytes", "KB")}<span className="text-dark fw-bold">{' KB'}</span></span>
                    </span>
                  : <OverlayTrigger placement="top" trigger={'hover'} overlay={<Popover><PopoverBody>{cert.name as any as string}</PopoverBody></Popover>} >
                      <span className={styles.fileName}>{cert.name}</span>
                    </OverlayTrigger>}
              </div>
              <button
                type="button"
                title={cert.localOnly ? 'Remove' : 'Delete from our record'}
                onClick={() => {
                  if (!cert.localOnly) {
                    setIsUploadingDocs(true);
                    setReplacedDocs((prev) => {
                      const newRemovedDocs = prev ? [prev, cert.url].join(',') : cert.url;
                      return newRemovedDocs || '';
                    });
                  }
                  const newCerts = formData.certifications.filter((_, i) => i !== index);
                  setFormData({ ...formData, certifications: newCerts });
                  setErrorMessage("");
                }}
                className={styles.removeCertButton}
              >
                <IoMdClose />
              </button>
            </div>
          ))}
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {fileSize && <p style={{ color: "red" }}>{fileSize}</p>}
        <div className={styles.uploadCertification}>
          <label className={styles.uploadLabel}>
            <AiOutlineCloudUpload className={styles.uploadIcon} />
            <span>Add Certification</span>
            <input
              type="file"
              accept=".pdf"
              onChange={handleCertificationUpload}
              className={`${styles.fileInput} d-none`}
            />
          </label>
          <p className={styles.fileHint}>
            Supported formats: PDF
          </p>
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <button
          type="button"
          onClick={() => { onSuccess((prev: number) => prev - 1); }}
          className={styles.backButton}
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleDocumentUpdate}
          className={styles.nextButton}
        >
          {isUploadingDocs ? 'Save & Continue' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default Step3IdDoc;
