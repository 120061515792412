import React, { Suspense, lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import TopNavbar from "../navbars/TopNavbar";
const Marquee = lazy(() => import("../buttons/MarqueeBox"));
import ReportAdModal from "../modals/reportAdvtModal/ReportAdModal";
import TermsAndCModal from "../modals/termsModal/TermsAndCModal";
import VerificationModal from "../dasboard/commonDashboard/generalInformation/verificationModal/VerificationModal";
import ModalControl from "../modals/commonModal/ModalControl";
import Footer from "../pages/LandingPage/explore/Footer/Footer";
import { isLaunched } from "../../constants";

const AppWrapper = () => {
  const path = useLocation()
  return (
    <>
    {
      ! (path.pathname == '/' && !isLaunched)
      ?
      <div className="app-wrapper d-flex flex-column " style={{minHeight:"100vh"}}>
      <Container fluid className="px-0 flex-grow-1 d-flex flex-column">
        {/* <Suspense fallback={<></>}>
          <Marquee />
        </Suspense> */}
        <Row className="mx-0">
          <Col className="px-0">
            <TopNavbar navClass="fixed-top fixed-top-2 py-0" />
          </Col>
        </Row>
        <Row className="mx-0 flex-grow-1">
          <Col className="px-0">
          <div style={{minHeight: '100vh'}}>
            <Outlet /></div>
          </Col>
        </Row>
        <Row className="mx-0 mt-auto">
          <Col className="px-0">
            <Footer />
          </Col>
        </Row>
        {/* <TermsAndCModal /> */}
      </Container>
    </div>
    :<div>
    <Container fluid className="px-0 flex-grow-1 d-flex flex-column">
    <Row className="mx-0 flex-grow-1">
          <Col className="px-0">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>}
  </>
  );
};

export default AppWrapper;
