import 'react-international-phone/style.css';
import "./App.css"
import React, { Suspense, lazy } from "react"
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom"
import AppWrapper from "./components/wrappers/AppWrapper"
import { Button, Modal, Spinner } from "react-bootstrap"

import {
  baseRoutes,
  customerDashboardRoutes,
  providerDashboardRoutes,
  providerFunnelRoutes,
  seekerFunnelRoutes,
} from "./Routes"

import { USERTYPE } from "./constants"
import { useAppSelector } from "./app/hooks"
const FeatProviderFunnel = lazy(()=> import( "./features/ServiceFunnel/FeatProviderFunnel/FeatProviderFunnel"))

const  FeatSeekerFunnel =  lazy(()=>import("./features/ServiceFunnel/FeatSeekerFunnel/FeatSeekerFunnel"))
const StudentView = lazy(()=>import("./components/dasboard/Providerdashboard/Myads/studentView")) 
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import PrivateRoute from "./utils/PrivateRoutes/PrivateRoute"
import DashWrapper from "./components/dasboard/newDash/DashWrapper"
import SpinnerSecondary from "./components/spinner/SpinnerSecondary"
import { useFetchNotificationInstanceQuery, useFetchTokenQuery, useListenForMessagesQuery } from "./features/notification/notificationApi"
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md"
import SpinnerMain from "./components/spinner/SpinnerMain"
import TeacherProfile from "./components/ScheduleSession/TeacherProfile/TeacherProfile"
import ChatAuthenticator from './features/chat/ChatAuthenticator';
import ProviderFunnelPrivacy from './components/hoc/ProviderFunnelPrivacy';
import { getCurrentNotificationToken } from './features/notification/notificationSlice';
import { NotificationTokenError } from './Types';
import { useLazyRegisterTokenQuery } from './features/Auth/authApi';
import VerificationModal from './components/dasboard/commonDashboard/generalInformation/verificationModal/VerificationModal';
import ModalControl from './components/modals/commonModal/ModalControl';
import RootWrapper from './components/hoc/RootWrapper';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import useInitHotjar from './utils/hotjarAnalyticsFeatures/inititHotjar';

const VAPID = import.meta.env.VITE_FIREBASE_VAPID

function App() {
  const auth = useAppSelector((state: any) => state.auth)
  const token = useAppSelector(getCurrentNotificationToken)
  const { data, error, refetch, isUninitialized} = useFetchTokenQuery(VAPID, {
    skip: VAPID == null,
    refetchOnMountOrArgChange: true
  }) 
  const [registerToken, {isUninitialized: isTokenRegistered, isSuccess: IsTokenRegisteredSucceeded}] = useLazyRegisterTokenQuery()
  useEffect(()=>{
    if(token===null && VAPID !== null && !isUninitialized){refetch()}
  },[VAPID])
  useEffect(()=>{
    if(data) {
      registerToken(data.data)
    }
    if(error) {
      if((error as NotificationTokenError).data) return
      else if((error as NotificationTokenError).data == null) return
      else return
    }
  }, [data, error])

  const { error: messageError } = useListenForMessagesQuery(undefined,{
    skip: isTokenRegistered
  });

  useEffect(() => {
    if (messageError) {
      console.error('Error in listening for messages:', messageError);
    }
  }, [messageError]);
  useInitHotjar(5219477, 6, true);


  const router = createBrowserRouter([
    // {
    //   path: '/home',
  
    //   element: (
    //     <Suspense fallback={<SpinnerMain />}>
    //         <PreLaunchPage />
    //     </Suspense>
    //   ),
    // },
    {
      path: '/',
      element: (<RootWrapper/>),
      children: [
    {
      path: "/",
      element: (
        <Suspense fallback={<><SpinnerMain/></>}>
        <AppWrapper />
        </Suspense>),
      children: baseRoutes,
    },
    {
      path: "/journey-seeker",
      element: (
              <Suspense fallback={<><SpinnerMain/></>}>
              <FeatSeekerFunnel />
              </Suspense>),
      children: seekerFunnelRoutes,
    },

    {
      path: "/journey-source",
      element: (
        <Suspense fallback={<><SpinnerMain/></>}>
                <PrivateRoute>
                  <ProviderFunnelPrivacy>
                    <FeatProviderFunnel />
                  </ProviderFunnelPrivacy>
                </PrivateRoute>
          </Suspense>
        // // <PrivateRoute>
        //   <FeatProviderFunnel />
        // // </PrivateRoute>
      ),
      children: providerFunnelRoutes,
    },
    {
      path: "/source-dashboard",
      element: (
        // <PrivateRoute>
          // <FeatProviderDashboard />
        // </PrivateRoute>
        <>
        <ErrorBoundary>
 {!auth?.providerToken ? (
        <Navigate to={"/"} replace />
      ) : ( 
        <ChatAuthenticator>
        <DashWrapper userType={USERTYPE.PROVIDER} />
      </ChatAuthenticator>
      )}</ErrorBoundary></> ),
      children: providerDashboardRoutes,
    },

    {
      path: "/seeker-dashboard",
      element: (
        <PrivateRoute userType={USERTYPE.SEEKER}>
            <ChatAuthenticator><DashWrapper userType={USERTYPE.SEEKER} /></ChatAuthenticator>

        </PrivateRoute>
      ),
      children: customerDashboardRoutes,
    },
      
    {
      path: "/teacher-profile-view",
      element: (
        <Suspense fallback={<SpinnerMain/>}>
        <PrivateRoute>
          <TeacherProfile/>
        </PrivateRoute>
        </Suspense>
      ),
    },
    // verification-screen
    // {
    //   path: "/enter",
    //   element: <Enter />,
    //   loader: () => <Spinner variant="grow" />,
    // },
    // {
    //   path: "/otp",
    //   element: <OTP />,
    //   loader: () => <Spinner variant="grow" />,
    // },
    // {
    //   path: "/verified",
    //   element: <Verified />,
    //   loader: () => <Spinner variant="grow" />,
    // },
    // {
    //   path: "/notification",
    //   element: (
    //     <PrivateRoute>
    //       <NotificationScreen />
    //     </PrivateRoute>
    //   ),
    //   loader: () => <Spinner variant="grow" />,
    // },
  ]}
  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App


