import React, { ReactNode, MouseEvent, useState, useEffect } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import "./PromotionpageWrapper.css"
import lockIcon from "../../assets/img/lock-icon.png"
import { isLaunched, TARGET_DATE } from "../../constants"
import { useGetTotalCountQuery } from "../../features/Auth/authApi"
import ModernProfileMeter from "../completeYourProfileMeter/CompleteYourProfileMeter"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/Auth/authSlice"

interface PromotionpageWrapperProps {
  children: ReactNode
}
const TARGET_USERS_COUNT = 108
const PromotionpageWrapper: React.FC<PromotionpageWrapperProps> = ({
  children,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [isAllowed, setIsAllowed] = useState<boolean>(true)
  const location = useLocation()
  const { data, error, isLoading } = useGetTotalCountQuery(undefined, {
    skip: isLaunched,
  })
  const { user, completion } = useAppSelector(selectCurrentUser)
  console.log(user)
  const handleClick = () => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 200980)
  }
  useEffect(() => {
    if (
      ["my-advt", "my-account"].some((path) => location.pathname.includes(path))
    )
      setIsAllowed(true)
    else if (!isLaunched) setIsAllowed(false)
  }, [location])

  function evaluateLaunchTime(): number {
    const today: Date = new Date()
    const differenceInTime: number = TARGET_DATE.getTime() - today.getTime()
    const differenceInDays: number = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24),
    )
    return differenceInDays
  }
  function evaluateUserCount() {
    if (data) {
      return TARGET_USERS_COUNT - (data?.data?.count as number)
    } else {
      return 1
    }
  }

  return (
    <>
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Body>
          <Container>
            <Modal.Header closeButton className="p-0" />
            <div className="feat-access-card">
              <img
                src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/nodataconcept.png"
                alt=""
              />
              <h5>Coming Soon!</h5>
              <p>This feature is not accessible now untill launch </p>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <div className="position-relative">
        {!isAllowed && (
          <>
            <div
              className={`timer-container ${isAllowed ? "" : "blurred-section-timer"
                }`}
            >
              <img src={lockIcon} alt="locked" />
              <h2>The wait is almost over!</h2>
              {/* <p>Mark your calendars for the big reveal</p> */}
              <p>User onboarding has been active since</p>

              <DateContainer date={TARGET_DATE} />
              {!error && (
                <div className="days-remaining">
                  {isLoading ? <CountChangeEffect /> : evaluateUserCount()}{" "}
                  users to go
                </div>
              )}
            </div>
            <ModernProfileMeter percentage={completion} />
          </>
        )}

        <div className={isAllowed ? "" : "blurred-section"}>
          <div
            className={isAllowed ? "" : "overlay-blur"}
            onClick={() => !isAllowed && handleClick()}
          />
          {children}
        </div>
      </div>
    </>
  )
}

export default PromotionpageWrapper
interface DateContainerProps {
  date: Date
}

const DateContainer: React.FC<DateContainerProps> = ({ date }) => {
  // Format day, month, and year from the date
  const day = date.getDate() // Get day (1-31)
  const month = date.toLocaleString("default", { month: "long" }) // Get full month name (e.g., "October")
  const year = date.getFullYear() // Get year (e.g., 2024)

  return (
    <div className="date-container">
      <span className="date-box">{day}</span>
      <span className="date-box">{month}</span>
      <span className="date-box">{year}</span>
    </div>
  )
}

const CountChangeEffect = ({ end = 108, speed = 120 }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      const randomNumber = Math.floor(Math.random() * (end + 1))
      setCount(randomNumber)
    }, speed)

    return () => clearInterval(timer) // cleanup on component unmount
  }, [end, speed])

  return <>{count}</>
}
