import React from 'react'
import { OverlayTrigger, Tooltip, Form, FloatingLabel } from 'react-bootstrap'

interface inputTooltip {
    name: string
    type: string
    keyIdentifier: string
    placeholder: string
    className?: string
    message?: string
    errors?: any
    register?: any
    isEditMode?: boolean
    tooltipText?: string
    placement?: string
    max?: any
    icon?: any
    isMobile?: boolean
}

const InputControllWithTooltip = (prop: inputTooltip) => {
  return (
    <>
    <OverlayTrigger
        key={prop.keyIdentifier}
          placement={`${prop.placement?'top':"bottom"}`}
          delay={{ show: 200, hide: 100 }}
          overlay={!prop.isEditMode ? (<Tooltip className={`position-absolute ${prop.placement?'bottom-0':"top-0"}`} id={`information-tooltip-${prop.keyIdentifier}`}>Click on Edit to change </Tooltip>) : <></>}
        >
          <div className="account-field d-flex flex-row justify-content-center align-items-center ">
          {prop.icon&& prop.icon}
            {
            (prop.isMobile) ?
              <FloatingLabel
              className='w-100'
                label={prop.placeholder}>
                <Form.Control
                  {...prop.register}
                  name={prop.name}
                  disabled={!prop.isEditMode}
                  type={prop.type}
                  placeholder={prop.placeholder}
                  className={'account-field-input'}
                  max={prop.max}
                />
                </FloatingLabel>
            :<Form.Control
              {...prop.register}
              name={prop.name}
              disabled={!prop.isEditMode}
              type={prop.type}
              placeholder={prop.placeholder}
              className={'account-field-input'}
              max={prop.max}
            />}
            </div>
            </OverlayTrigger>
            </>
  )
}

export default InputControllWithTooltip