import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { fetchStepNumber } from "../stepApi"
import { providerDashboardApi, useUploadAdProfileDetailsMutation } from "../../featProviderDashboard/FeatProviderApi"
import { RESPONSE_TIME } from "../../../constants"
import { serviceTypeValue } from "../../../Types"

interface stepUserData {}
export interface funnelState {
  funnelStepperCount: number
  stepCount: number
  activeStep: number
  status: "idle" | "loading" | "failed"
  providerFunnelData: {
    prices: object
    services: serviceTypeValue[] | serviceTypeValue.null
    vedicSubject: string[]
    skillSet: string[]
    subSkills?: {skillSet: string, price?: number}[]
    customServiceDetails?: string
    averagePrice?: number
    title: string
    aboutClass: string
    aboutYou: string
    phoneNo: string
    language: string[]
    location: string
    modeOfClass: string
    hourlyRate: string
    profileImage: object
    profileVideo: object
    youtubeLink: string
    responseTime: RESPONSE_TIME
    recordedLect: boolean
    revealPhoneNo: boolean
    city: string
  }
  maxHourlyRate: number
  minHourlyRate: number
  errors: boolean
  maxStep: number
  isStepValid: boolean
}

const initialState: funnelState = {
  stepCount: 0,
  maxStep: 0,
  providerFunnelData: {
    prices: {},
    services: serviceTypeValue.null,
    averagePrice: undefined,
    customServiceDetails: undefined,
    vedicSubject: [],
    skillSet: [],
    title: "",
    aboutClass: "",
    aboutYou: "",
    phoneNo: "",
    language: [],
    location: "",
    city: '',
    modeOfClass: "",
    hourlyRate: "",
    youtubeLink: "",
    profileImage: {},
    profileVideo: {},
    responseTime: RESPONSE_TIME.none,
    recordedLect: false,
    revealPhoneNo: false,
  },
  funnelStepperCount: 0,
  status: "idle",
  maxHourlyRate: 5000,
  minHourlyRate: 0,
  errors: true,
  activeStep: 0,
  isStepValid: true,
}
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "funnel/fetchCount",
  async (amount: number) => {
    const response = await fetchStepNumber(amount)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const decrementAsync = createAsyncThunk(
  "funnel/fetchCountDecrement",
  async (amount: number) => {
    const response = await fetchStepNumber(amount)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)
export const ProviderFunnelSlice = createSlice({
  name: "ProviderFunnel",
  initialState,
  reducers: {
    updateFunnel: (
      state: funnelState,
      { payload }: PayloadAction<{ [key: string]: any }>,
    ) => {
      state.providerFunnelData = {
        ...state.providerFunnelData,
        ...payload,
      }
      // state.maxHourlyRate = maxHourlyRate
      // state.minHourlyRate = minHourlyRate
    },
    // setprofileData: (state,action) =>{
    //   state.providerFunnelData.profileImage = new FormData();
    //   state.providerFunnelData.profileImage = action.payload
    // },

    resetProviderFunel: (state) => {
      state.providerFunnelData = {
        prices: {},
        services: serviceTypeValue.null,
        averagePrice: undefined,
        vedicSubject: [],
        skillSet: [],
        title: "",
        aboutClass: "",
        aboutYou: "",
        phoneNo: "",
        language: [],
        location: "",
        city: '',
        modeOfClass: "",
        hourlyRate: "",
        youtubeLink: "",
        profileImage: {},
        profileVideo: {},
        responseTime: RESPONSE_TIME.none,
        recordedLect: false,
        revealPhoneNo: false,
        customServiceDetails: undefined,
      }
      state.activeStep = 0
      state.status = "idle"
      state.errors = false
    },

    setActiveStep: (state, { payload: stepNumber }: PayloadAction<number>) => {
      state.activeStep = stepNumber
    },
    setFunnelStepNumber: (
      state,
      { payload: stepNumber }: PayloadAction<number>,
    ) => {
      state.funnelStepperCount = stepNumber
    },
    resetProviderStepper: (state) => {
      ;(state.activeStep = 0), (state.stepCount = 0)
    },
    setMaxStep: (state, { payload: maxStep }: PayloadAction<number>) => {
      state.maxStep = maxStep
    },
    setIsStepValid: (state, action) => {
      state.isStepValid = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    
  
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(
        incrementAsync.fulfilled,
        (state, { payload: step }: PayloadAction<number>) => {
          state.status = "idle"
          state.stepCount = step + 1
        },
      )
      .addCase(incrementAsync.rejected, (state) => {
        state.status = "failed"
      })
    builder
      .addCase(decrementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(decrementAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.stepCount = action.payload - 1
      })
      .addCase(decrementAsync.rejected, (state) => {
        state.status = "failed"
      })
      .addCase(setIsStepValid, (state, action) => {
        state.isStepValid = action.payload
      })
      builder.addMatcher(
        providerDashboardApi.endpoints.addDeatils.matchFulfilled,
        (state, { payload }) => {
          state = initialState
        },
      )
  },
})

export const {
  setMaxStep,
  setFunnelStepNumber,
  setActiveStep,
  resetProviderStepper,
  resetProviderFunel,
  updateFunnel,

  setIsStepValid,
} = ProviderFunnelSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const selectVedicSubject = (state: RootState) => state.providerFunnel.providerFunnelData.vedicSubject;
export default ProviderFunnelSlice.reducer
export const funnelState = (state: RootState) => state.providerFunnel
