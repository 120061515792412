import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState, useAppDispatch } from "../../../../app/store";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "./styles.module.css";
import { setActiveStep, setIsStepValid, updateFunnel } from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice";
import { AiOutlineInfo } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { serviceTypeValue } from "../../../../Types";

const options = [
  { 
    id: "01", 
    value: serviceTypeValue.Source, 
    label: "Teaching Services", 
    info: "Share your knowledge of Vedic practices through structured teaching and learning modules." 
  },
  { 
    id: "02", 
    value: serviceTypeValue.Consultance, 
    label: "Consultancy Services", 
    info: "Provide personalized guidance and solutions based on Vedic principles to help individuals on their journey." 
  },
  { 
    id: "03", 
    value: serviceTypeValue.Others, 
    label: "Custom Services", 
    info: "Are you unsure about where to start? By selecting Custom Services, our team will reach out to you for personalized assistance." 
  },
];


const ServiceTypeForm = () => {
  const providerFunnelData = useAppSelector(
    (state: RootState) => state.providerFunnel.providerFunnelData
  );
  const dispatch = useAppDispatch();

  const [infoactive, setInfoActive] = useState(false)

  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }

  const [classInfo, setClassInfo] = useState<serviceTypeValue[]>(
    providerFunnelData.services as serviceTypeValue[] || []
  );

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>, value: serviceTypeValue) => {
    let updatedClassInfo: serviceTypeValue[] = e.target.checked ? [value] : [];

    setClassInfo(updatedClassInfo);
    // TODO: reset to initial state
    dispatch(updateFunnel({ ...providerFunnelData, services: updatedClassInfo }));
  };

  useEffect(() => {
    const isStepValid = classInfo.length < 1;
    dispatch(setIsStepValid(isStepValid));
  }, [classInfo, dispatch]);

  return (
    <section className="py-lg-1 funnel-section">
      <Container>
        <div className="inner-fav">
          <Row className="justify-content-center">
            <Col xl={6} lg={8}>
              <div className="d-flex flex-column gap-4">
                <Form>
                  <Form.Group>
                    <Form.Label className="get-started-text mb-2">
                      Select Service Type
                    </Form.Label>
                    <div className={styles.pillContainer}>
                      {options.map((option) => {
                          const [mouseActive, setMouseActive] = useState(false)
                        return(
                        <label
                          key={option.id}
                          className={`${styles.pill} ${
                            classInfo.includes(option.value) ? styles.selected : ""
                          }`}
                          onMouseEnter={()=>{setMouseActive(true)}}
                          onMouseLeave={()=>{setMouseActive(false)}}
                        >
                          <input
                            type="checkbox"
                            id={`check-api-checkbox-${option.id}`}
                            checked={classInfo.includes(option.value)}
                            onChange={(e) => handleSelectChange(e, option.value)}
                            className={styles.checkboxInput}
                          />
                          {option.label}
                          {mouseActive&&
                          <div className={`${styles.infoOverlay}`}>
                            <span className=" text-decoration-underline fw-medium label">{option.label}</span>
                            {option.info}
                          </div>}
                        </label>
                      )})}
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <>
                  <div className="make-it-better">
                    <div
                      className="d-flex gap-3 mb-1 "
                      style={{ height: "2rem" }}
                    >
                      <img
                        src={thumbs}
                        alt="thumbs-up"
                        style={{ height: "2.5rem" }}
                      />
                      <h3 className="make-heading text-black fw-bolder fs-3">
                  Did you Know?
                      </h3>
                    </div>
                    
                   <div className="mt-5">
                   <ul>
                      <li >
                   <strong>Niche Services Gain More Visibility</strong> Seekers are often looking for specific skills or expertise. Clearly defining your service type helps you stand out and reach the right audience faster.
                    </li></ul>
                    <ul><li>
                    <strong>Your Unique Offering is Your Brand </strong>Highlighting your unique skills or experience (like rare yoga techniques or specialized astrological methods) can make you more appealing and increase demand for your services.</li>
                    </ul>
                   </div>
                   
                  </div>
                </>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ServiceTypeForm;
