import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { adjustHeight } from '../../../../utils/commonFunctions/CommonFunctions';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { updateFunnel } from '../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice';
import { RootState } from '../../../../app/store';

const MAX_CHARACTER_LENGTH = 500;
const MAX_WORD_LENGTH = 50;
const MIN_WORD_LENGTH = 10;
const MAX_SERVICE_PRICE = 99999;
// Define validation schema with Yup
const schema = Yup.object().shape({
  serviceDetails: Yup.string()
    .required('Service details are required')
    .test(
      'word-count',
      'Service details must have between 10 and 50 words',
      (value) => {
        const wordCount = value?.trim().split(/\s+/).length || 0;
        return wordCount >= MIN_WORD_LENGTH && wordCount < MAX_WORD_LENGTH;
      }
    )
    .max(MAX_CHARACTER_LENGTH, 'Service details cannot exceed 500 characters'),
    averagePrice: Yup.number()
    .nonNullable()
    .required('Price is required')
    .typeError('Price must be a number')
    .positive('Price must be a positive number')
    .max(MAX_SERVICE_PRICE, `Price limit for session is set ${MAX_SERVICE_PRICE }`),
});

type CustomServiceDetailsForm = {
  serviceDetails: string;
  averagePrice: number;
};

const CustomServiceDetails = () => {
    const dispatch = useAppDispatch()
    const { providerFunnelData } = useAppSelector((state: RootState) => state.providerFunnel);
    const { customServiceDetails, averagePrice } = useAppSelector((state: RootState) => state.providerFunnel.providerFunnelData);
    const [WORD_LENGTH, SET_WORD_LENGTH] = useState<number>(0)
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CustomServiceDetailsForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      serviceDetails: '',
      averagePrice: undefined,
    } 
  });
  const avgPrice = watch('averagePrice')
  const serviceDetails = watch('serviceDetails')
  useEffect(()=>{
    dispatch(updateFunnel({...providerFunnelData, averagePrice: avgPrice}))
  },[avgPrice])

  const onSubmit = (data: CustomServiceDetailsForm) => {
    // console.log('Submitted Data:', data);
  };
  useEffect(()=>{
    if(averagePrice) setValue('averagePrice', averagePrice, {shouldValidate: true})
    if(customServiceDetails) setValue('serviceDetails', customServiceDetails, {shouldValidate: true});
  },[averagePrice, customServiceDetails])
  useEffect(()=>{dispatch(updateFunnel({...providerFunnelData, customServiceDetails: serviceDetails}))},[serviceDetails])

  useEffect(()=>{
    const details = serviceDetails?.trim()
    SET_WORD_LENGTH( details ? details.split(/\s+/).length : 0);
  },[serviceDetails])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label className="green-text font-14 d-flex gap-2 justify-content-start align-items-center">
          <p className="text-black fw-bolder fs-5">Add Details</p>
          <p className="fs-6 fw-bolder mt-1">to your Services</p>
          <p>*</p>
        <p className='ms-auto me-0 text-end text-black-50 fw-normal my-auto'><span className={`${WORD_LENGTH<MIN_WORD_LENGTH ? 'text-danger':''}`}>{WORD_LENGTH}</span>/<span>{MAX_WORD_LENGTH}</span></p>
        </label>
        <Controller
          name="serviceDetails"
          control={control}
          rules={{
            validate: (value) => {
              const wordCount = value.trim().split(/\s+/).length; // Count words
              if (wordCount < 10) {
                return 'Please enter at least 10 words.';
              }
              if (wordCount > 50) {
                return 'Please limit your input to a maximum of 50 words.';
              }
              return true;
            },
          }}
          render={({ field }) => (
            <Form.Control
              {...field}
              as="textarea"
              placeholder="Please fill out the details of the service you would like to provide...."
              className={`custom-scrollbar custom-input funnel-input-focus  ${
                errors.serviceDetails ? 'is-invalid' : ''
              }`}
              required
              rows={4}
              maxLength={500}
              onKeyDown={(e)=>{
                if (e.key === " " && WORD_LENGTH >= MAX_WORD_LENGTH) {
                  e.preventDefault();
                  field.onBlur();
                }
              }}
              onChange={(e) => {
                dispatch(updateFunnel({...providerFunnelData, customServiceDetails: e.target.value}))
                field.onChange(e); // Notify react-hook-form of value change
                adjustHeight(e, 5); // Adjust height dynamically
              }}
            />
          )}
        />
        {errors.serviceDetails && (
          <p className="text-danger">{errors.serviceDetails.message}</p>
        )}
      </div>
      <div className="mt-4">
        <label className="green-text font-14 d-flex gap-2 justify-content-start align-items-center">
          <p className="text-black fw-bolder fs-5">Add Your</p>
          <p className="fs-6 fw-bolder mt-1">Average Price</p>
          <p>*</p>
        </label>
        <div className="d-flex flex-column gap-2">
            <Row className=' justify-content-center align-content-center align-items-center'>
            <Col>
            <FormControl
              {...register('averagePrice')}
              placeholder="Enter average price"
              className={`form-control custom-input funnel-input-focus ${errors.averagePrice && 'is-invalid'}`}
              type="text"
              // disabled={consultancyDetails.some((item) => item.price === undefined)}
              min={0}
              max={999999}
              step={100}
              required
              aria-describedby="averagePriceHelp"
              onKeyDown={(e) => {
                  const invalidKeys = ['e', 'E', '+', '-', '.', ',', ' '];
                  if (invalidKeys.includes(e.key)) {
                      e.preventDefault();
                  }
              }}
              onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.replace(/[^\d]/g, '');
              }}
          />
          </Col>
          <Col className='input-custom-price-text'>Rs/Session</Col>
          </Row>
          {errors.averagePrice && (
            <p className="text-danger">{errors.averagePrice.message}</p>
          )}
        </div>
      </div>
    </form>
  );
};

export default CustomServiceDetails;
