import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  CardImg,
  CardFooter,
  CardBody,
  CardSubtitle,
} from "react-bootstrap"
import "./SelectPath.css"
import learnimage from "../../../assets/img/explore-learn.jpg"
import guideimage from "../../../assets/img/meditation.png"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { ScrollRestoration, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../app/store"
import { reset } from "../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { resetProviderFunel } from "../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useAppSelector } from "../../../app/hooks"
import { isLaunched, MODALNAME, USERTYPE } from "../../../constants"
import { closeModal, setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { setCurrentUser } from "../../../features/Auth/authSlice"
import checkboxImage from "../../../assets/img/checkbox-select.png"
import { BsQuestionSquareFill } from "react-icons/bs"
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { options, publicScreenDemo } from "../../welcomeInstruction/InstructionComponent"
const SelectPath = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const auth = useAppSelector((state: any) => state.auth)
  const handleClick = () => {
    if (auth.providerToken !== null) {
      dispatch(resetProviderFunel())
      navigate("/journey-source")
    } else {
      dispatch(setCurrentUser(USERTYPE.PROVIDER))
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }
  useEffect(() => {
    if (auth.providerToken !== null) {
      dispatch(resetProviderFunel())
      navigate("/journey-source")
    }
  }, [auth.user])
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (!hasSeenTour) {
      setRun(isLaunched ? true : false);
      localStorage.setItem('hasSeenTour', 'true');
    }
  }, []);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      setStepIndex(0);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // TODO if required add steps and actions 
      // if(index==1 && action === ACTIONS.NEXT)  dispatch(setModalName(MODALNAME.LOGIN))
      // if(index==2) dispatch(closeModal())
      // if(index==3 && action === ACTIONS.PREV) dispatch(setModalName(MODALNAME.LOGIN))
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <>
    <ScrollRestoration/>
    <Joyride
        steps={publicScreenDemo}
        run={run}
        continuous={true}
        disableScrolling={ window.innerWidth > 991 ? true : false}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        spotlightPadding={10}
        scrollDuration={20}
        styles={{options}}
      />
      <section className="my-account-sec  d-flex justify-content-center align-items-center m-2 pt-5 ">
        <Container>
          <div className="inner-account position-relative">
            <Row className="justify-content-center get-started-card mt-5">
              <div className="pt-4"></div>
              <Col xl={8} lg={8}>
                <div>
                  <div className="p-1 p-lg-0">
                    <h3 className="fw-600 complete-para mb-1 heading-secondary">
                      who 
                      <span className="started-card-heading"> you </span> want to be... 
                       <span className="">
 {/* <BsQuestionSquareFill/>                 */}
       </span>
                    </h3>
                    <p className="mb-2 started-card-heading-text my-3 h5 ">
                      Join as a seeker to discover and delve deep, or become a
                      source to share your expertise and earn. If you possess
                      the skills to teach, seize the opportunity to empower
                      others with your knowledge. Register now and embark on
                      your journey of learning, sharing, and earning.
                    </p>
                    <div className="mt-5"></div>
                    <Row>
                      <Col xs={10} sm={6} className="mb-md-0 mb-4 mx-auto">
                        <Card
                          id='seeker-learn-card'
                          className="learn-card"
                          onClick={() => {
                            navigate("/journey-seeker"), dispatch(reset())
                          }}
                        >
                          <div className="learn-card-heading ">
                            <h2 className="mt-2" >Seeker</h2>
                          </div>
                          <CardImg
                            className="learn-imgbx pt-1"
                            src={"https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/funnel-seeker-img.jpeg"}
                          ></CardImg>
                          <CardBody className="learn-content">
                            <h2>Learn and Explore</h2>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={10} sm={6} className="mx-auto">
                        <Card 
                        id='source-learn-card'
                        className="learn-card">
                          <div className="learn-card-heading">
                            <h2 className="mt-2">Source</h2>
                          </div>
                          <CardImg
                            className="learn-imgbx pt-1"
                            src={"https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/funnel-tutor-img.jpg"}
                            onClick={handleClick}
                          ></CardImg>
                          <CardBody className="learn-content">
                            <h2>Guide and Earn</h2>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <div className="pt-5"></div>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SelectPath
