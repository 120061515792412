import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Modal, Row } from 'react-bootstrap';
import { IoCloudUploadOutline } from "react-icons/io5";
import { RxCross2 } from 'react-icons/rx';

const MAX_WORD_LENGTH = 50; // Maximum word length for report message
const DetailesReportModal = ({ handleReportProgress, register, setValue }: any) => {
    const [value, _setValue] = useState<string>('');
    const [file, setFile] = useState<any>(null);
    const [messageWordLength, setMessageLength] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const clearFile = () => setFile(null);
    const selectImage = () => { if (inputRef.current) inputRef.current.click() };

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const message = e.target.value;
        const wordLength = message.split(/\s+/).filter(Boolean).length;

        if (wordLength <= MAX_WORD_LENGTH) {
            // Update the message only if the word count is <= MAX_WORD_LENGTH
            _setValue(message); 
            setValue('message', message.trim());
            setMessageLength(wordLength);
        } else {
            // Limit the message to MAX_WORD_LENGTH words
            const truncatedMessage = message.split(/\s+/).slice(0, MAX_WORD_LENGTH).join(" ");
            _setValue(truncatedMessage); 
            setValue('message', truncatedMessage.trim());
            setMessageLength(MAX_WORD_LENGTH);  // Ensure word length is not more than MAX_WORD_LENGTH
        }
    };

    const handleOnFileChange = (e: any) => {
        if (e?.target.files[0]) {
            setFile(e.target.files[0])
            setValue('image',e.target.files[0]);
        }
    };


    return (
        <>
            <Modal.Body className="modal_content_head">
                <div className="mb-3">Please describe the report details</div>
                <div className='text-end'>{`${messageWordLength}/${MAX_WORD_LENGTH}`}</div>
                <div className="d-flex justify-content-between report_modal mb-4">
                    <Form.Control
                        as='textarea'
                        maxLength={500}
                        rows={4}
                        className='report-describe-msg-box'
                        onChange={handleOnChange}
                        value={value}
                        placeholder='Describe the details ...'
                    />
                </div>
                {(messageWordLength >=1 && messageWordLength < 10) &&
                    <p className=' text-danger'>Message must be at least 10 word long.</p>
                }
                <Container className="dashed_border text-center">
                    {file ?
                        <div className="report-ads-upld-img-box">
                            <button className="close-btn close_btn_bg1" onClick={clearFile}>
                                <RxCross2 size={20} />
                            </button>
                            <img className="report-ads-upload-img" src={URL.createObjectURL(file)} alt="Uploaded file" />
                        </div>
                        :
                        <Row onClickCapture={selectImage}>
                            <div className="text-center d-flex flex-column align-items-center detail_box detail_report_icon">
                                <IoCloudUploadOutline />
                                <strong className="mb-2">Drag & drop files or Browse</strong>
                                <span>Supported formats: JPEG, PNG</span>
                            </div>
                        </Row>}
                    <input
                        type='file'
                        className='d-none'
                        accept='image/png, image/jpg, image/jpeg'
                        onChange={handleOnFileChange}
                        ref={inputRef}
                    />
                </Container>
                <div className="upload_sec">
                    <Container>
                        <Row>
                            {file &&
                                <>
                                    <label>Uploading</label>
                                    <p className='overflow-auto custom-scrollbar'>{file.name}</p>
                                </>
                            }
                        </Row>
                    </Container>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between footer-reply-modal">
                <Button variant="secondary" className="send-btn" onClick={() => handleReportProgress(0)}>
                    Back
                </Button>
                <Button
                    variant="primary"
                    className="send-btn"
                    type='submit'
                    disabled={messageWordLength < 10}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </>
    );
};

export default DetailesReportModal;
