export const convertFileSize = (
  size: number,
  inputUnit: "Bytes" | "KB" | "MB" | "GB" | "TB",
  outputUnit: "Bytes" | "KB" | "MB" | "GB" | "TB"
) => {
  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  const inputIndex = units.indexOf(inputUnit);
  const outputIndex = units.indexOf(outputUnit);

  if (inputIndex === -1 || outputIndex === -1) {
    throw new Error("Invalid unit provided");
  }

  // Convert size to bytes first
  let sizeInBytes = size * Math.pow(1024, inputIndex);

  // Convert bytes to the desired output unit
  const convertedSize = sizeInBytes / Math.pow(1024, outputIndex);

  return `${convertedSize.toFixed(2)}`;
};
