import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { api } from "./authApi"
import { seekerDashboardApi } from "../featSeekerDashboard/seekerDashboardApi"
import { USERTYPE } from "../../constants"
import { providerDashboardApi } from "../featProviderDashboard/FeatProviderApi"
import { trackLoginEvent, trackLogoutEvent } from "../../components/googleAnalyticsMeasure/event"

const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userChat: null,
    seekerToken: null,
    providerToken: null,
    userProfile: "",
    data: [],
    role: USERTYPE.SEEKER,
    verfiedEmail: null,
    currentUserRole: USERTYPE.SEEKER,
    loading: false,
    emailVeificationStep: false,
    forgotPassEmail: null,
    searchAdvtsCount: null,
    isLoginEmailVerified: false,
    isTermsConditionAccepted: false,
    progressCount: null,
    completion: 0,
  } as {
    user: null | any
    userChat: null | any
    userProfile: string
    seekerToken: null | string
    providerToken: null | string
    data: Array<any>
    role: null | string
    verfiedEmail: any
    currentUserRole: USERTYPE
    loading: boolean
    emailVeificationStep: boolean
    forgotPassEmail: null | string
    searchAdvtsCount: null
    isLoginEmailVerified: boolean
    isTermsConditionAccepted: boolean
    progressCount: any
    completion: number
  },
  reducers: {
    updateSeekerToken: (state: any, action: PayloadAction<string>) => {
      // state.user = null
      state.seekerToken = action.payload
    },
    // updateChatUser: (state: any, action: PayloadAction<any>) => {
    //   // state.user = null
    //   state.userChat = action.payload
    // },

    updateProviderToken: (state: any, action: PayloadAction<string>) => {
      // state.user = null
      state.providerToken = action.payload
    },
    providerToken: (state: any, action: any) => {
      // state.user = null
      state.providerToken = action.payload
    },
    emailVerifyToken: (state: any, action: any) => {
      // state.user = null
      state.token = action.payload
    },
    providerEmailVerifyToken: (state: any, action: any) => {
      // state.user = null
      state.token = action.payload
    },
    logout: (state) => {
      state.user = null
      state.seekerToken = null
      state.providerToken = null
    },
    setEmailVerified: (state: any, action: any) => {
      state.verfiedEmail = action.payload
    },
    setCurrentUser: (state: any, action: { payload: USERTYPE }) => {
      state.currentUserRole = action.payload
    },
    setLoading: (state: any, action: any) => {
      state.loading = action.payload
    },
    setEmailVerification: (state: any, action: any) => {
      state.emailVeificationStep = action.payload
    },
    setForgotPasswordEmail: (state: any, action: any) => {
      state.forgotPassEmail = action.payload
    },
    setPageCountOnScroll: (state: any, action: any) => {
      state.pageNo = action.payload
    },
    setTermApproved: (state: any, action: { payload: boolean }) => {
      state.isTermsConditionAccepted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.userRegister.matchFulfilled,
      (state, { payload }) => {
        state.seekerToken = payload.token
        state.providerToken = null
        state.user = payload.data
        state.currentUserRole = USERTYPE.SEEKER
      },
    ),
      builder.addMatcher(
        api.endpoints.providerRegister.matchFulfilled,
        (state, { payload }) => {
          state.providerToken = payload.token
          state.seekerToken = null
          state.user = payload.data
          state.currentUserRole = USERTYPE.PROVIDER
        },
      ),
      builder.addMatcher(
        api.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.seekerToken = payload.token
          state.providerToken = null
          state.role = USERTYPE.SEEKER
          state.user = payload.user
          state.currentUserRole = USERTYPE.SEEKER
          state.isTermsConditionAccepted = payload.user.termsAccepted
          trackLoginEvent()
        },
      ),
      builder.addMatcher(
        providerDashboardApi.endpoints.providerlogin.matchFulfilled,
        (state, { payload }) => {
          state.providerToken = payload.token
          state.seekerToken = null
          state.role = payload.user.role
          state.user = payload.user
          state.isLoginEmailVerified = payload.isEmailVerified
          state.currentUserRole = USERTYPE.PROVIDER
          state.isTermsConditionAccepted = payload.user.termsAccepted
          trackLoginEvent();
        },
      ),
      // Seeker after verifing with otp
      builder.addMatcher(
        api.endpoints.emailVerify.matchFulfilled,
        (state, { payload }) => {
          state.role = USERTYPE.SEEKER
          state.user = payload.data
          state.currentUserRole = USERTYPE.SEEKER
          state.providerToken = null
          state.seekerToken = payload.token
        },
      ),
      builder.addMatcher(
        api.endpoints.seekerPhoneVerify.matchFulfilled,
        (state, { payload }) => {
          state.role = USERTYPE.SEEKER
          state.user = payload.data
          state.currentUserRole = USERTYPE.SEEKER
          state.providerToken = null
          state.seekerToken = payload.token
        },
      ),
      // To avaoid user data loss from forgot password
      // builder.addMatcher(
      //   api.endpoints.forgetPassword.matchFulfilled,
      //   (state, { payload }) => {
      //     state.user = payload
      //   },
      // ),
      // builder.addMatcher(
      //   api.endpoints.providerforgetPassword.matchFulfilled,
      //   (state, { payload }) => {
      //     state.user = payload
      //   },
      // ),
      builder.addMatcher(
        api.endpoints.logout.matchFulfilled,
        (state, { payload }) => {
          state.seekerToken = null
          state.providerToken = null
          state.user = null
          state.data = []

          state.userProfile = ""
          state.role = USERTYPE.SEEKER
          state.verfiedEmail = null
          state.currentUserRole = USERTYPE.SEEKER
          state.loading = false
          state.emailVeificationStep = false
          state.forgotPassEmail = null
          state.searchAdvtsCount = null
          state.isLoginEmailVerified = false
          state.userChat = null
          state.isTermsConditionAccepted = false
          state.progressCount = null
          state.completion = 0
          // state.user = payload.result.user;
          trackLogoutEvent();
        },
      )
    builder.addMatcher(
      api.endpoints.logoutProvider.matchFulfilled,
      (state, { payload }) => {
        state.seekerToken = null
        state.providerToken = null
        state.user = null
        state.data = []

        state.userProfile = ""
        state.role = USERTYPE.SEEKER
        state.verfiedEmail = null
        state.currentUserRole = USERTYPE.SEEKER
        state.loading = false
        state.emailVeificationStep = false
        state.forgotPassEmail = null
        state.searchAdvtsCount = null
        state.isLoginEmailVerified = false
        state.userChat = null
        state.isTermsConditionAccepted = false
        state.progressCount = null
        state.completion = 0
        // state.user = payload.result.user;
        trackLogoutEvent();
      }
    )
    builder.addMatcher(
      api.endpoints.forgetPassword.matchFulfilled,
      (state, { payload }) => {
        // causing reset to credentials
        // state.forgotPassEmail = payload.email
      },
    )
    builder.addMatcher(
      api.endpoints.providerforgetPassword.matchFulfilled,
      (state, { payload }) => {
        // causing reset to credentials
        // state.forgotPassEmail = payload.email
      },
    )
    // builder.addMatcher(
    //   seekerDashboardApi.endpoints.searchLandingPage.matchFulfilled,
    //   (state, { payload }) => {
    //     state.data = payload.data
    //     state.searchAdvtsCount = payload.totalItems
    //     // state.user = payload.result.user;
    //   },
    // )
    builder.addMatcher(
      api.endpoints.searchSortProviders.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.data
      },
    )
    builder.addMatcher(
      api.endpoints.providerEmailverify.matchFulfilled,
      (state, { payload }) => {
        state.verfiedEmail = payload.data
        state.user = payload.data
        state.providerToken = payload.token
        state.seekerToken = null
      },
    )
    builder.addMatcher(
      api.endpoints.providerPhoneverify.matchFulfilled,
      (state, { payload }) => {
        state.verfiedEmail = payload.data
        state.user = payload.data
        state.providerToken = payload.token
        state.seekerToken = null
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.seekerVerifyPhoneOtp.matchFulfilled,
      (state, { payload }) => {
        state.user.isMobileNoVerified = payload?.success
      },
    )
    builder.addMatcher(
      providerDashboardApi.endpoints.providerVerifyPhoneOtp.matchFulfilled,
      (state, { payload }) => {
        state.user.isMobileNoVerified = payload?.success
      },
    )
    builder.addMatcher(
      api.endpoints.emailVerify.matchFulfilled,
      (state, { payload }) => {
        state.user.isEmailVerified = payload?.data ? true : false
        state.seekerToken = payload.token
      },
    )
    builder.addMatcher(
      api.endpoints.providerEmailverify.matchFulfilled,
      (state, { payload }) => {
        state.user.isEmailVerified = payload?.data ? true : false
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.updateSeekerProfile.matchFulfilled,
      (state, { payload }) => {
        state.user.isEmailVerified = payload?.data?.isEmailVerified
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.updateSeekerProfile.matchFulfilled,
      (state, { payload }) => {
        state.user.isMobileNoVerified = payload?.data?.isMobileNoVerified
      },
    )
    builder.addMatcher(
      providerDashboardApi.endpoints.updateAccount.matchFulfilled,
      (state, { payload }) => {
        state.user = payload?.data
      },
    )
    builder.addMatcher(
      providerDashboardApi.endpoints.addDeatils.matchFulfilled,
      (state, { payload }) => {
        state.user.skills = payload?.data?.skillSet
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.paymentVerify.matchFulfilled,
      (state) => {
        state.user.isPremium = true
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.updateSeekerProfile.matchFulfilled,
      (state, { payload }) => {
        state.userProfile = payload?.data?.profilePic
        state.user = {
          ...state.user,
          profilePic: payload.data.profilePic,
        }
      },
    )
    builder.addMatcher(
      providerDashboardApi.endpoints.uploadProfilepic.matchFulfilled,
      (state, { payload }) => {
        state.user.profilePic = payload.data.profilePic
      },
    )
    builder.addMatcher(
      seekerDashboardApi.endpoints.getseekerProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.data
        state.role = USERTYPE.SEEKER
        state.providerToken = null
        state.isTermsConditionAccepted = payload.data.termsAccepted
      },
    )
    builder.addMatcher(
      providerDashboardApi.endpoints.providerAccountDetails.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.data
        state.role = USERTYPE.PROVIDER
        state.seekerToken = null
        state.isTermsConditionAccepted = payload.data.termsAccepted
        state.progressCount = payload.progressCount
        state.completion = parseInt(payload?.completion || 10, 10);
      },
    )
  },
})
export default slice.reducer
export const {
  logout,
  updateSeekerToken,
  updateProviderToken,
  emailVerifyToken,
  providerEmailVerifyToken,
  setEmailVerified,
  setCurrentUser,
  setLoading,
  setEmailVerification,
  setForgotPasswordEmail,
  // updateChatUser
  setTermApproved,
} = slice.actions

export const selectCurrentUser = (state: RootState) => state.auth
export const providerData = (state: RootState) => state.auth.data
export const ProviderToken = (state: RootState) => state.auth.providerToken
export const SeekerToken = (state: RootState) => state.auth.seekerToken
