import React, { useEffect, useRef, useState } from "react"
import dummy from "../../../assets/images/dummy-profile-pic.jpg"
import verifiedbandage from "../../../assets/img/verified-bandage.svg"
import "../Providerdashboard/Dashboardcomponent/DashboardComponent.css"
import seekerEyeLogo from "../../../assets/images/seekereye (1).svg"
import deleteIcon from "../../../assets/images/addeleted.svg"

import ToggleButton from "../../buttons/ToggleButton"
import { AD_STATUS, ADVT_MODALS, USERTYPE } from "../../../constants"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { FaPencil, FaPlay } from "react-icons/fa6"
import { Button, Col, Row } from "react-bootstrap"
import {
  useUpdateAdStatusMutation,
  useUploadAdProfileDetailsMutation,
  useUploadProfilepicMutation,
} from "../../../features/featProviderDashboard/FeatProviderApi"
import CroperModal from "../../modals/imageCroper/CroperModal"
import DeleteAdModal from "../../modals/deleteAd/DeleteAdModal"
import { toast } from "react-toastify"
import SpinnerSecondary from "../../spinner/SpinnerSecondary"
import { useUpdateSeekerProfileMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import premimumStar from "../../../assets/images/premimumStar.png"
import RenderProfile from "../../../utils/RenderProfilePic/RenderProfilePic"
// import EditPictureModal from "../../modals/editProfile/pictureUpdate/EditPictureModal";
import { VscVerifiedFilled } from "react-icons/vsc";
import { IoIosAdd } from "react-icons/io"
import { AiOutlineVideoCameraAdd } from "react-icons/ai"
import EditVideoModal from "../../modals/mediaEditModal/EditVideoModal"
import { motion } from "framer-motion"
interface profileProps {
  firstName: string
  lastName: string
  profilePic: string
  ratings: string
  reviews: string
  vedicSubjectsSkills: string
  languages: string
  isVerified: string
  userType: USERTYPE
}

const ProfileCard = ({
  profilePic,
  reviews,
  ratings,
  firstName,
  lastName,
  languages,
  vedicSubjectsSkills,
  isVerified,
  userType,
}: profileProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pickImg, _pickImg] = useState<any>(null)
  const [cropResult, _cropResult] = useState<any>(null)
  const [cropermodal, _cropermodal] = useState<boolean>(false)
  const { pathname } = location
  const splitPath = pathname.split("/")
  const profile = useAppSelector((state: any) => state.providerSlice)
  const [fileName, setFileName] = useState<string>('file.jpg')
  const [adData, _adDAta] = useState<any>(null)
  const [advertId, _advertID] = useState<string>(
    profile?.user?.data?.allAdvertisements[profile.id]?._id || "",
  )
  const [isHovered, setHovered] = useState<boolean>(false)
  const userData = useAppSelector((state: any) => state.auth)
  const [
    updateSeekerPicture,
    {
      isLoading: seekerProfileLoading,
      error: seekerPPUpdateError,
      data: seekerProfilePicSuccess,
    },
  ] = useUpdateSeekerProfileMutation()
  const [
    uploadeProfilepic,
    { isLoading: isUploadingPP, data: profPicSuccess, error: profilepicError },
  ] = useUploadProfilepicMutation()
  const [
    uploadeAdMedia,
    { isLoading: updatingAd, error: isAdUpdateError, data: adUpdateSuccess },
  ] = useUploadAdProfileDetailsMutation()
  const [currentModal, setCurrentModal] = useState<string>("")
  const [show, setShow] = useState(false)
  const [
    updateAdStatus,
    {
      isError: adStatusFailed,
      isLoading: isToggling,
      isSuccess: adStatusSuccess,
      data: adUpdateData,
      error: adStatusError,
    },
  ] = useUpdateAdStatusMutation()
  const handleClose = () => setCurrentModal(ADVT_MODALS.CLOSED)
  const openModal = (modalType: any) => {
    setCurrentModal(modalType)
    setShow(true)
  }
  const imgPickerRef = useRef<HTMLInputElement | null>(null)
  function closeCroper() {
    _cropermodal(false)
    if (imgPickerRef.current) {
      imgPickerRef.current.value = '';
    }
  }
  useEffect(() => {
    if (splitPath[2] == "my-advt") {
      _adDAta(profile?.user?.data?.allAdvertisements[profile.id])
      _advertID(profile?.user?.data?.allAdvertisements[profile?.id]?._id)
    }
  }, [profile, splitPath])

  useEffect(() => {
    _cropermodal(pickImg !== null)
  }, [pickImg])
  useEffect(() => {
    if (cropResult) {
      handleUploadeImage(cropResult)
    }
  }, [cropResult])

  function generateUniqueFileName(file: any) {
    const timestamp = new Date().getTime()
    const randomString = Math.random().toString(36).substring(2, 8)
    const extension = file.type.split("/")[1] // Extract file extension

    return `${timestamp}_${randomString}.${extension}`
  }
  const handleUploadeImage = (file: any) => {
    const formData = new FormData()

    if (splitPath[2] == "my-advt") {
      if (adData._id) {
        formData.append("profileImage", file)
        uploadeAdMedia({
          id: adData._id,
          adData: formData,
        })
        _cropResult(null)
        _pickImg(null)
      } else {
        toast.error("Please, select the advertisement first")
      }
    } else {
      // formData.append("profilePic", file)
      formData.append("profilePic", file, generateUniqueFileName(file))
      if (splitPath[1] == "source-dashboard") {
        uploadeProfilepic(formData)
      } else {
        updateSeekerPicture(formData)
      }
      _cropResult(null)
      _pickImg(null)
    }
  }
  useEffect(() => {
    toast.dismiss()
    if (adStatusError) {
      if ("status" in adStatusError && typeof adStatusError.data === "object") {
        // Assuming error.data is an object that might contain a message property
        const errorData = adStatusError.data as { message?: string }
        if (errorData.message) {
          toast.error(errorData.message)
        } else {
          // If no message is present, stringify the entire data object
          toast.error(JSON.stringify(adStatusError.data))
        }
      } else {
        // If the error structure is different or not a FetchBaseQueryError
        toast.error("An unknown error occurred")
      }
    } else if (adStatusSuccess) {
      toast.success(adUpdateData?.message)
    }
  }, [adStatusSuccess, adStatusFailed])

  function toggleOption(): void {
    updateAdStatus({
      adId: profile?.user?.data?.allAdvertisements[profile.id]?._id,
    })
  }

  useEffect(() => {
    toast.dismiss()
    if (profPicSuccess || adUpdateSuccess || seekerProfilePicSuccess) {
      toast.success(
        profPicSuccess
          ? profPicSuccess.message
          : adUpdateSuccess
          ? adUpdateSuccess.message
          : seekerProfilePicSuccess
          ? seekerProfilePicSuccess.message
          : "Your picture updates successfully",
      )
    } else if (profilepicError || isAdUpdateError || seekerPPUpdateError) {
      toast.error(
        profilepicError
          ? "Sorry! we are unable to update yor profile image this time"
          : isAdUpdateError
          ? "Sorry! we'r unable to process yor request this time"
          : seekerPPUpdateError
          ? "Sorry! we are unable to update yor profile image this time"
          : "Sorry! we are failed to process yor request this time",
      )
    }
  }, [
    profPicSuccess,
    profilepicError,
    adUpdateSuccess,
    isAdUpdateError,
    seekerPPUpdateError,
    seekerProfilePicSuccess,
  ])

  const handleMouseEnter = () => {
    if(adData?.profileVideo) setHovered(true)
  }

  const handleMouseLeave = () => {
    if(adData?.profileVideo) setHovered(false)
  }


  return (
    <>
      <div className="fav-card teacher-card position-unset top-0 mt-0 mt-md-3">
        <div className={`${isHovered ? 'fav-video' : 'fav-img'} relative overflow-visible`}>
          {(updatingAd || isUploadingPP || seekerProfileLoading) && (
            <SpinnerSecondary />
          )}
          {splitPath[2] == "my-advt" ? (
            adData?.profileImage && (
              <>{
                isHovered
              ?
              <motion.div 
              initial={{opacity: 0}}
              whileInView={{opacity: [0, 1]}}
              exit={{opacity: 0}}
              transition={{duration: 0.2, delay: 0.1, ease: 'easeInOut'}}
              className="adVideo-container overflow-hidden h-100 w-100">
              <video muted loop controls autoPlay={true}>
                  <source
                    src={adData?.profileVideo}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
              </video></motion.div>
              :<motion.div
              initial={{opacity: 0}}
              animate={{opacity: [0, 1]}}
              exit={{opacity: 0}}
              transition={{duration: 0.2, delay: 0.1, ease: 'easeInOut'}}>
                <RenderProfile customClass="img" senderProfilePic={adData?.profileImage} senderNameValue={''}/>
              </motion.div>}
              </>
            )
          ) : (
            // <RenderProfile
            // customClass={`${(userData?.user?.profilePic ) ? '' : 'custom-border  initials fw-bolder img'
            // } d-flex w-100 h-100 justify-content-center`} 
            // senderNameValue={`${firstName} ${lastName}`} 
            // senderProfilePic={userData?.user?.profilePic || profilePic}/>
            <img src={userData?.user?.profilePic || profilePic || dummy}/>
          )}
          <input
            ref={imgPickerRef}
            type="file"
            onChange={(e) => {
              if (e.target.files?.length && e.target.files?.length > 0)
                {const fileExtension = e.target?.files[0]?.name?.split('.').at(-1) || 'png'
                  setFileName(fileExtension)
                _pickImg(e.target.files[0])}
            }}
            className="d-none"
            id="profile-image"
            accept=".jpg, .jpeg, .png"
          />
          {(splitPath[2] == "my-advt" && !isHovered) && (
            <>
              <button
              title="Edit"
                className="editAdsImage-btn "
                onClick={() => {
                  imgPickerRef.current?.click()
                }}
              >
                <FaPencil />
              </button>
            </>
          )}
          {!(
            splitPath[2] == "my-evaluations" ||
            !splitPath[2] ||
            splitPath[2] == "my-advt"
          ) && (
            <button
            title="Edit Your Profile"
              className="editAdsImage-btn"
              onClick={() => {
                imgPickerRef.current?.click()
              }}
            >
              <FaPencil />
            </button>
            )}
          {userData?.user?.isPremium && (splitPath[2] !== "my-advt" && <img
            src={premimumStar}
            alt="premimumStar"
            className="premium-icon-2 custom-border"
            title="Premium User"
          />)}
        </div>
        <div className="info-astrologer pb-0">
          <div className="d-flex justify-content-center flex-column  align-items-center mb-2">
            <div className="d-flex align-items-center justify-content-center ms-2">
              <h2 className="ast-name mb-1">
                {firstName} {lastName}{" "}
                </h2>
                <span>{isVerified && (
                  // <img
                  //   src={verifiedbandage}
                  //   className="verifedBadgeOverProfile"
                  //   style={{ marginTop: "-9px" }}
                  // />
                  <VscVerifiedFilled size={24}  color="green" className="ms-1 mb-1" title="Verified Profile" />

                )}</span>
             
            </div>
            {userType === USERTYPE.PROVIDER && (
              <div className="rating mb-0">
                <span className="total-rating"> {ratings} </span>{" "}
                <small className="reviews">{reviews}</small>
              </div>
            )}
          </div>
          {userType === USERTYPE.PROVIDER && (
            <>
              {/* {splitPath[2] === 'my-advt' && <div className="d-flex justify-content-between gap-4 font-16 align-items-start">
              <p className="ast-ocp mb-2">Vedic Subject</p>
              <p className="ast-ocp font-16  mb-lg-3 mb-0">
                {vedicSubjectsSkills}
              </p>
            </div>} */}

              {/* {splitPath[2] === 'my-advt' && <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap-wrap">
              <p className="ast-ocp mb-lg-0 mb-2">Language</p>
              <div className="d-flex gap-1 ms-3 flex-wrap">{languages}</div>
            </div>} */}

              {splitPath[2] === "my-advt" && (
                <div className="underProfileCardMain d-flex align-items-center justify-content-center">
                  <ul className="underProfileCardMain_ul">
                    <li onClick={()=>{if(adData?.status !== AD_STATUS.PENDING) toggleOption()}} className="option-item d-flex flex-column align-items-center justify-content-center ">
                      <ToggleButton
                        defaultVal={adData?.status === "Active"}
                        isLoading={isToggling}
                        disabled={adData?.status === AD_STATUS.PENDING || adData?.status === AD_STATUS.Deactive}
                      />
                      <label className="ms-1 cursor-pointer text-nowrap">
                        {adData?.status === AD_STATUS.Active ? " Online" 
                        : adData?.status === AD_STATUS.CLOSED ? " Offline" 
                        : adData?.status === AD_STATUS.PENDING ? 'Pending'
                        : adData?.status === AD_STATUS.Deactive && " Deactived"
                      }
                      </label>
                    </li>
                    <li
                    className="d-flex flex-column option-item"
                      onClick={() => {
                        navigate(
                          {
                            pathname: "/teacher-profile-view",
                            search: `?${createSearchParams({ advertId })}`,
                          },
                          {
                            replace: false,
                          },
                        )
                      }}
                    >
                      {" "}
                      <img
                        className="seekerImg"
                        src={seekerEyeLogo}
                        alt="seeker eye"
                         title="Seeker View"
                      />
                      <label className="cursor-pointer text-nowrap">{" Seeker view"}</label>
                    </li>
                    <li
                    className="d-flex flex-column align-items-center justify-content-center option-item"
                      onClick={() => {
                        openModal(ADVT_MODALS.DELETE)
                      }}
                    >
                      {" "}
                      <img className="seekerImg" src={deleteIcon} alt="" title="Delete Ad" />
                      <label className="cursor-pointer text-nowrap">{" Delete Ad"}</label>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
        {splitPath[2] == "my-advt" && <div className="position-absolute top-0 end-0 me-4 mt-4 p-1 "
        onMouseLeave={handleMouseLeave}
        >
          {(adData?.profileVideo && adData?.profileVideo!==' ') ?
          isHovered ? 
          <button className="play-btn" title="Play Video"
          onClick={()=>openModal(ADVT_MODALS.VIDEOEDIT)}>
            <IoIosAdd title="Edit video" />
          </button>
          :
          <button className="play-btn"
          onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
            <FaPlay />
          </button>
          :<button className="play-btn"
          onClick={()=>openModal(ADVT_MODALS.VIDEOEDIT)}>
            <AiOutlineVideoCameraAdd />
          </button>}
          </div>}
      </div>
      <CroperModal
        showModal={cropermodal}
        handleClose={closeCroper}
        image={pickImg}
        setImage={_pickImg}
        setResults={_cropResult}
        extension={fileName}
      />
      {
        <DeleteAdModal
          show={currentModal === ADVT_MODALS.DELETE}
          handleClose={handleClose}
          adID={advertId}
        />
      }
      {
        <EditVideoModal handleClose={handleClose}
        show={currentModal === ADVT_MODALS.VIDEOEDIT} 
        adID={advertId} profileVideo={adData?.profileVideo}/>
      }
    </>
  )
}

export default ProfileCard