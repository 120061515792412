import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa6'

const ReportFinalStep = () => {
  return (
    <>
      <Modal.Body className='mb-2'>
        <div className=" report_modal1 d-flex justify-content-center flex-column align-items-center">
          <p className="text-center">Thank you for your feedback!</p>
          <FaCheck />
          <span className="text-center fw-light">Your report has been submitted
            <br />
            Our team will review it and take action soon</span>
        </div>
      </Modal.Body>
    </>
  )
}

export default ReportFinalStep