import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Row, Alert, Col, Form } from 'react-bootstrap';
import { useProviderAdvertisementQuery, useUploadAdProfileDetailsMutation } from '../../../features/featProviderDashboard/FeatProviderApi';
import { IoMdAdd } from 'react-icons/io';
import { toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';
import { generateRandomFileName } from '../../../utils/commonFunctions/randomFilenameGenerator';
import SpinnerMain from '../../spinner/SpinnerMain';
import "../ReplyModal/ReplyModal.css"
import { MAX_AD_VIDEO_SIZE_MB } from '../../../constants';

interface props {
  show: boolean
  adID: string | undefined
  handleClose: any
  profileVideo?: string
}
const EditVideoModal = ({ show, adID, handleClose, profileVideo }: props) => {
  // const [adID, setAdID] = useState<string | null>(null)
  const [pickedFile, pickFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const videoPickerRef = useRef<HTMLInputElement | null>(null);
  const [uploadVideo, { data, error, isLoading }] = useUploadAdProfileDetailsMutation();
  // const {data: adData, isLoading: IdadLoading, error: isAdError} = useProviderAdvertisementQuery({adId: adID}, {skip: adID == null || adID == undefined || !show, refetchOnMountOrArgChange: true})

  // useEffect(()=>{if(adID)setAdID(adID)},[adID])
  function handleAddAnotherVideo() {
    videoPickerRef?.current?.click();
  }
  function removeFromServer () {
    if(adID){
      const formdata = new FormData();
      formdata.append("profileVideoUrl", " ");
    uploadVideo({ id: adID, adData: formdata}).unwrap()
    .then(() => {
      handleClose();
    })
    .catch((error: any) => {
      console.error((error as any)?.message)
      setErrorMessage('Upload failed. Please try again.');
    });}
  }

  useEffect(() => {
    if (show && !(profileVideo !== undefined && profileVideo !== ' ')) {
      setErrorMessage(null); handleAddAnotherVideo(); 
    }
  }, [show, profileVideo]);

  useEffect(() => {
    toast.dismiss()
    if (data) { toast.success(data?.message); pickFile(null) }
    else if (error) toast.error((error as any)?.message || "Failed to uploade file")
  }, [data, error])

  function handleSubmit() {
    if (adID && pickedFile) {
      const extension = pickedFile?.name?.split('.').at(-1) || 'mp4'
      const form = new FormData();
      form.append('profileVideo', pickedFile, generateRandomFileName(extension));
      uploadVideo({ id: adID, adData: form })
        .unwrap()
        .then(() => {
          handleClose();
        })
        .catch((error: any) => {
          setErrorMessage('Upload failed. Please try again.');
        });
    }
  }

  return (
    <Modal show={show} onHide={() => { pickFile(null); handleClose(); }}>
      {isLoading&&(<div className=' rounded-3 position-absolute z-3 bg-white bg-opacity-50 w-100 h-100'><SpinnerMain className='h-100 d-flex justify-content-center align-items-center '/></div>)}
      <Modal.Body>
        <Row >
          <div className='d-flex align-items-center justify-content-between'><span className=' edt-add-video-text'>Edit Video</span>
            <Button
              variant="transparent "
              onClick={() => { pickFile(null); handleClose(); }}
              className={'col-2 cross-btn-border'}
            >
              <RxCross2 className='yt-modal-cross-btn ms-1 ms-md-4' />
            </Button></div>
        </Row>
        <input
          ref={videoPickerRef}
          type="file"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const allowedTypes = ["video/mp4", "video/x-matroska", "video/webm"];
              if (!allowedTypes.includes(file.type)) {
                setErrorMessage("Please upload a valid video file (.mp4, .mkv, .webm).");
                pickFile(null);
                return;
              }
              if (file.size > MAX_AD_VIDEO_SIZE_MB * 1024 * 1024) { // 20 MB size limit
                setErrorMessage(`File size exceeds ${MAX_AD_VIDEO_SIZE_MB} MB limit`);
                pickFile(null);
              } else {
                setErrorMessage(null);
                pickFile(file);
              }
            }
          }}
          className="d-none"
          id="profile-image"
          // accept=".mp4, .mkv, .webm"
          accept="video/mp4, video/x-matroska, video/webm"
        />
        <Row className="overflow-hidden h-100 justify-content-center align-items-center">
          {pickedFile ? (
            <div>
           
            <video
              key={pickedFile.name} // Key based on file name
              muted
              loop
              controls
              autoPlay={true}
              style={{ width: '100%', height: 'auto' }}
            >
              <source
                src={URL.createObjectURL(pickedFile)}
                type={pickedFile.type}
              />
              Your browser does not support the video tag.
            </video>
            <p className='edt-video-title-text'>Your Selected Video</p>
            </div>
          ) : (
            (profileVideo && profileVideo!==' ') ?
            <>
            <Row className='mb-3'>
            <Col sm={12}>
              Your current video
            </Col>
            <video
                  key={profileVideo} // Key based on file name
                  muted
                  loop
                  controls
                  autoPlay={true}
                  style={{ width: '100%' }}
                >
                  <source
                    src={profileVideo}
                    // type={pickedFile.type}
                  />
                  Your browser does not support the video tag.
                </video>
            </Row>
            <Row className="mt-3 gap-5">
            <Button
            className="col-4 mx-auto yt-edit-btn " onClick={handleAddAnotherVideo}>Edit </Button>
            <Button
            className="col-4 mx-auto yt-edit-btn " onClick={removeFromServer}>Remove</Button>
            </Row>
            </>
            :<>
            <div
              onClick={handleAddAnotherVideo}
              className="col-4 py-5 rounded-4 bg-dark-subtle d-flex justify-content-center align-items-center  mx-auto"
            >
              <IoMdAdd size={50} color='#f4f4f4' />
            </div></>
          )}
        </Row>
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
        <Row>
          <label className=' fw-light fs-8 text-dark-emphasis text-center'>{"When you update your ad, it will be sent for verification by the admin. Until it is verified, your ad will not be live."}</label>
        </Row>
        <Row className="mt-3 gap-5">
          {pickedFile && (
            <Button className="col-4 mx-auto cancel-edit-btn" onClick={handleAddAnotherVideo}>
              Edit
            </Button>
          )}
          {(pickedFile || (profileVideo == undefined || profileVideo == ' '))&&<Button
            className="col-4 mx-auto yt-edit-btn "
            onClick={handleSubmit}
            disabled={!pickedFile}
          >
            Submit
          </Button>}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EditVideoModal;
