import React from 'react';
import GeneralInformation from '../dasboard/commonDashboard/generalInformation/GeneralInformation';
import styles from './MultiStepForm.module.css';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';

const Step5GeneralInformation = ({onSuccess, isComplete, stepKey}: any) => {
          const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)
          function handleProfileInfoUpdate(): void {
            onSuccess((prev: number)=>prev+1)
          }
    
  return (
    <div className={styles.formStep}>
      <h2>Complete your details</h2>
      <p className={styles.subtitle}>
        Add your basic information to complete your profile
      </p>
      <GeneralInformation
        userType={currentUserRole}
        isMobile={false}
        title={' '}
        isEditable={true}
      />
      <div className={styles.buttonGroup}>
        <button
          type="button"
          onClick={() =>onSuccess((prev: number)=>prev-1)}
          className={styles.backButton}
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleProfileInfoUpdate}
          className={styles.nextButton}
        >
          {false ? 'Save & Continue' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default Step5GeneralInformation;
