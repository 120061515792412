import React, { useEffect, useState, Suspense, useRef } from "react"
import "./GeneralInformation.css"
import { useForm, Controller } from "react-hook-form"
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useProviderPhoneVerificationMutation,
  useUpdateAccountMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import {
  useSeekerPhoneVerificationMutation,
  useUpdateSeekerProfileMutation,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"

import { toast } from "react-toastify"
import { generalInfoSchema } from "../../../../utils/yupSchema/Schema"
import { useAppSelector } from "../../../../app/hooks"
import { useAppDispatch } from "../../../../app/store"
// import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
// import { MODALNAME, USERTYPE } from "../../../../constants"
// import {
//   useResendOtpMutation,
//   useResendOtpProviderMutation,
// } from "../../../../features/Auth/authApi"
import ProfileIcon from "../../../../assets/img/my-profile.svg"
import InputControllWithTooltip from "./inputControllWithTooltip/InputControllWithTooltip"
import InputSelectorWithTooltip from "./inputControllWithTooltip/InputSelectorWithTooltip"
// import { TbDeviceLandlinePhone } from "react-icons/tb"
// import { HiOutlineMail } from "react-icons/hi"
// import { BsPencil, BsSkype } from "react-icons/bs";
// import { FaTelegram } from "react-icons/fa6"
// import { PhoneInput } from 'react-international-phone';
import { HiMiniDocumentCheck, HiPencilSquare } from "react-icons/hi2"
import { selectCurrentUser } from "../../../../features/Auth/authSlice"

const currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 5); // 5 stands for 5 years early from current date

const GeneralInformation = ({ Data, userType, isMobile, title, isEditable }: any) => {
  const { user } = useAppSelector(selectCurrentUser)
  const [isEditMode, setIsEditMode] = useState(isEditable ? isEditable : false)
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleDataFormat = (date: any) => {
    const originalDate = new Date(date)
    const month = ("0" + (originalDate.getMonth() + 1)).slice(-2)
    const day = ("0" + originalDate.getDate()).slice(-2)
    const year = originalDate.getFullYear()
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const [generaForm, { data, error, isLoading }] =
    userType === "SEEKER"
      ? useUpdateSeekerProfileMutation()
      : useUpdateAccountMutation()
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm<any>({
    resolver: generalInfoSchema,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      firstName: values.fname,
      lastName: values.lname,
      gender: values.gender!==''?values.gender:undefined,
      // landlineNo: values.landline, 
      // skypeId: values.skype,  Disebled as not required  
      TelegramId: values.TelegramId,
      dob: values.dob!==''?values.dob:undefined,
    }
    generaForm(payload)
  }
  
  useEffect(()=>{
    if (data) {
      toast.dismiss()
      toast.success("Updated Successfully")
      setIsEditMode(false)
    } else {
      if (error) {
        toast.dismiss()
        toast.error((error as any)?.data?.message)
      }
    }
  },[data, error])
  useEffect(() => {
    if(Data){
    setValue("fname", Data?.data?.firstName)
    setValue("lname", Data?.data?.lastName)
    setValue("gender", Data?.data?.gender)
    setValue("dob", Data?.data?.dob ? handleDataFormat(Data?.data?.dob) : "")
    setValue("landline", Data?.data?.landlineNo)
    setValue("skype", Data?.data?.skypeId)
    setValue("TelegramId", Data?.data?.TelegramId)
    }
    else if(user) {
      setValue("fname", user?.firstName)
      setValue("lname", user?.lastName)
      setValue("gender", user?.gender)
      setValue("dob", user?.dob ? handleDataFormat(user?.dob) : "")
      setValue("landline", user?.landlineNo)
      setValue("skype", user?.skypeId)
      setValue("TelegramId", user?.TelegramId)
    }
  }, [Data, user])

  const auth = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()

  const generalInfoFormSubmitRef = useRef<HTMLButtonElement>(null)
  return (
    <div>
      <div>
      {/* {/ <div className="block-card account-block-card mb-4"> /}
        {/ General Information component /} */}
        <h2 className="card-heading position-relative">
          {title?title:<>
          General Information
          <img
            src={ProfileIcon}
            className="ms-2"
            alt="profile-icon"
            height={20}
            width={20}
            title="General Information"
          />
          </>}
          {isMobile && 
          (isEditMode 
          ? <span title="Save" onClick={()=>{generalInfoFormSubmitRef?.current?.click()}} className="gn-edit-btn text-align-center"> <HiMiniDocumentCheck size={22}  /></span>
          :<span title="Edit" onClick={handleEditClick} className="gn-edit-btn text-align-center"> <HiPencilSquare  size={22}  /></span>)
          }
        </h2>
        
        <Form className="form-general" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
        <Form.Group className="">
          <InputControllWithTooltip 
          isMobile={isMobile}
          keyIdentifier={"fname"} 
          name={"fname"} 
          type={"text"} 
          placeholder={"First Name"}
          register={register('fname')}
          isEditMode={isEditMode} />
          </Form.Group>
          {errors && (
              <span className="error">{(errors as any)?.fname?.message}</span>
            )}
          </div>
          <div className="mb-4">
          <Form.Group className=" border-0">
            <InputControllWithTooltip 
            isMobile={isMobile}
            keyIdentifier={"lname"} 
            name={"lname"} 
            type={"text"} 
            placeholder={"Last Name"}
            isEditMode={isEditMode}
            register={register("lname")}/>
          </Form.Group>
            {errors && (
              <span className="error">{(errors as any)?.lname?.message}</span>
            )}
          </div>
          <Form.Group className="mb-4 account-field">
            <InputSelectorWithTooltip 
            key={"gender"} 
            name={"gender"} 
            type={""} 
            placeholder={"Gender"} 
            isEditMode={isEditMode}
            children={<><option disabled hidden value="">
            Gender
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option></>}
            register={register("gender")}
            className="account-field cursor-pointer" />
          </Form.Group>
          <div className="mb-4">
          <Form.Group>
            <InputControllWithTooltip keyIdentifier={"dob"} name={"dob"} type={"date"} placeholder={"DD/MM/YYYY"}
            isEditMode={isEditMode} register={register("dob")} 
            className={"account-field date-edit-input"} isMobile={isMobile}
            max={currentDate.toISOString().split("T")[0]}
            />
          </Form.Group>
            {errors && (
              <span className="error">{(errors as any)?.dob?.message}</span>
            )}
          </div>
          {/* Disebled as not required 
          <OverlayTrigger
        key={'landline'}
          placement={'top'}
          delay={{ show: 200, hide: 100 }}
          overlay={!isEditMode ? (<Tooltip className={`position-absolute bottom-0`} id={`information-tooltip-landline`}>Click on Edit to change </Tooltip>) : <></>}
        >
          <div className="mb-4">
          <Form.Group
            className="phoneFormGroup flex-column align-items-start account-field">
            <div className="d-flex justify-content-start align-items-center">
            <TbDeviceLandlinePhone size={20} />
            <Form.Control
              {...register("landline")}
              name="landline"
              disabled={!isEditMode}
              type="text"
              maxLength={12}
              defaultValue={Data?.data?.landlineNo}
              placeholder="Landline Number"
              className=" account-field-input"
              onKeyDown={(e) => {
                if (
                  !(
                    (e.key >= "0" && e.key <= "9") ||
                    e.key === "Backspace" ||
                    e.key === "Delete"
                  )
                ) {
                  e.preventDefault()
                }
              }}
              />
              </div>
          </Form.Group>
            {errors && (
              <span className="error">
                {(errors as any)?.landline?.message}
              </span>
            )}
          </div>
          </OverlayTrigger>
          <div className="mb-4">
          <Form.Group>
            <InputControllWithTooltip 
            icon={<><BsSkype size={20} color="#00aff0" /></>}
            register={register("skype")}
            isEditMode={isEditMode} keyIdentifier={"skype"} name={"skype"} type={"text"} placeholder={"Skype Id"}/>
          </Form.Group>
            {errors && (
              <span className="error">{(errors as any)?.skype?.message}</span>
            )}
          </div> */}
          {/* <div className="mb-4">
          <Form.Group className="">
            <InputControllWithTooltip 
            icon={<><FaTelegram size={20}  color="#0088cc"/></>}
            placement="top"
            register={register("TelegramId")}
            isEditMode={isEditMode} keyIdentifier={"TelegramId"} name={"TelegramId"} type={"text"} placeholder={"Telegram Id"}/>
          </Form.Group>
            {errors && (
              <span className="error">
                {(errors as any)?.TelegramId?.message}
              </span>
            )}
            </div> */}
          {isEditMode && (
            <Button
            ref={generalInfoFormSubmitRef}
              variant="primary"
              className="submit-card-btn schedule-session-btn"
              type="submit"
            >
              {isLoading ? <Spinner className="spinner-md" /> : "Update"}
            </Button>
          )}

          {!isEditMode && (
            <Button
              variant="primary"
              className="submit-card-btn schedule-session-btn"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </Form>
      </div>
      
    </div>
  )
}

export default GeneralInformation
