import React from "react"
import "./AccountDetailsMobileView.css"
import { useAppSelector } from "../../../app/hooks"
import forwardArrow from "../../../assets/img/angele-right.svg"
import msgs from "../../../assets/img/requestcons2.svg"
import help from "../../../assets/img/helpicon.svg"
import termsIcon from "../../../assets/img/term&conditionIcon.svg"
import settingIcon from "../../../assets/img/setting-2.svg"
import heartIcon from "../../../assets/img/myadsicon.svg"
import fabIcon from "../../../assets/img/grey-heart-icon.png"
import dashboardIcon from "../../../assets/img/dashboardicon.svg"
import logoutIcon from "../../../assets/img/logouticon.svg"
import { useNavigate } from "react-router-dom"
import premimumDiamond from "../../../assets/img/premium-diamond-plus-eclips.png"
import RenderProfile from "../../../utils/RenderProfilePic/RenderProfilePic"
import { isLaunched, USERTYPE } from "../../../constants"
import { RootState } from "../../../app/store"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import lockImg from "/src/assets/img/lock-icon.png"
import { toast } from "react-toastify"

function AccountDetailsMobileView({ signOutUser, toggleDropdown }: any) {
  const auth = useAppSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const { seekerToken, providerToken, user } = useAppSelector(selectCurrentUser)
  function handleNavigate(path: string, state?: object) {
    if(state) navigate(path, {state}) 
    else navigate(path)
    if (toggleDropdown) toggleDropdown()
  }

  return (
    <div className="accountContainer d-flex flex-column gap-1 pt-2 w-100">
      <div className="d-flex  gap-2 px-3">
        <RenderProfile
        size="dd"
          customClass="defavatar custom-border"
          senderNameValue={auth?.user?.firstName + " " + auth?.user?.lastName}
          senderProfilePic={auth?.user?.profilePic}
        />
        <div className="d-flex flex-column">
          <p className=" m-0 profile-name">
            {auth.user?.firstName} {auth.user?.lastName}
          </p>
          <p className=" text-black-50 profile-text  m-0">
            {auth.currentUserRole === USERTYPE.SEEKER ? "SEEKER" : "SOURCE"}
          </p>
        </div>
      </div>
      <div className="account-body">
        <div
          onClick={() =>
            handleNavigate(
              seekerToken
              ? "/seeker-dashboard"
              : "/",
            )
          }
          className="d-flex justify-content-between w-100 mt-1 cursor-pointer "
        >
          <div className="d-flex justify-content-start align-items-center gap-3  ">
            <img className="account-icons" src={dashboardIcon} alt="msgs" />
            <p className="account-text my-auto">Dashboard</p>
            {!isLaunched&&<img className="account-lock-icon" src={lockImg} alt="" />}
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          className="d-flex justify-content-between w-100  cursor-pointer"
          onClick={() => {
            auth.currentUserRole === "SEEKER"
              ? handleNavigate("/favourite-screen")
              : handleNavigate("/source-dashboard/my-advt")
          }}
        >
          {auth.currentUserRole === "SEEKER" ? (
            <div className="d-flex justify-content-start align-items-center gap-3">
              <img className="account-icons" src={fabIcon} alt="msgs" />
              <p className="account-text my-auto">Favourites</p>
              {!isLaunched&&<img className="account-lock-icon" src={lockImg} alt="" />}
            </div>
          ) : (
            <div className="d-flex justify-content-start align-items-center gap-3">
              <img className="account-icons" src={heartIcon} alt="My Ads" />
              <p className="account-text my-auto">My Ads</p>
            </div>
          )}

          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          onClick={() =>
            handleNavigate(
              seekerToken
                ? "/seeker-dashboard/my-requests"
                : "/source-dashboard/my-requests",
            )
          }
          className="d-flex justify-content-between w-100  cursor-pointer"
        >
          <div className="d-flex justify-content-start align-items-center  gap-3 ">
            <img className="account-icons" src={msgs} alt="msgs" />
            <p className="account-text my-auto">Requests</p>
            {!isLaunched&&<img className="account-lock-icon" src={lockImg} alt="" />}
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          onClick={() =>
            handleNavigate(
              seekerToken
                ? "/seeker-dashboard/my-account"
                : "/source-dashboard/my-account",
            )
          }
          className="d-flex justify-content-between w-100  cursor-pointer"
        >
          <div className="d-flex justify-content-start align-items-center gap-3">
            <img
              className="account-icons"
              src={settingIcon}
              alt="settingIcon"
              title="settingicon"
            />
            <p className="account-text my-auto">My Account</p>
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          className="d-flex justify-content-between w-100  cursor-pointer"
          onClick={() => handleNavigate("/terms-and-policies")}
        >
          <div className="d-flex justify-content-start align-items-center  gap-3 ">
            <img className="account-icons" src={termsIcon} alt="msgs" />
            <p className="account-text my-auto">Terms & Conditions</p>
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          className="d-flex justify-content-between w-100  cursor-pointer"
          onClick={() =>
            handleNavigate("/help-center",{tabId: auth.currentUserRole === "SEEKER" ? "student" : "mentor"})}
        >
          <div className="d-flex justify-content-start align-items-center  gap-3 ">
            <img className="account-icons" src={help} alt="msgs" />
            <p className="account-text my-auto">Help</p>
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
        <div
          className="d-flex justify-content-between w-100 mb-1"
          onClick={() => {
            if(!isLaunched) {
              toast.warn('This route is not available till launch')
              return
            }
            if (auth.role === USERTYPE.PROVIDER || auth.providerToken!==null)
              handleNavigate(
                `/payment_pro/${auth?.user?._id}?planType=Monthly&price=199&period=Month`,
              )
            else if (auth.role === USERTYPE.SEEKER || auth.seekerToken!==null)
              handleNavigate(
                `/payment-sub/${auth?.user?._id}?planType=Monthly&price=199&period=Month&existing=true`,
              )
            
          }}
        >
          <div className="d-flex justify-content-start align-items-center  gap-3 cursor-pointer ">
            <img className="account-icons" src={premimumDiamond} alt="msgs" />
            <p className="account-text premimum my-auto">{(user && user?.isPremium)?'Upgrade your plan':'Upgrade to premium'}</p>
            {!isLaunched&&<img className="account-lock-icon" src={lockImg} alt="" />}
          </div>
          <img
            className="forwardArrow-img me-1"
            src={forwardArrow}
            alt="forwardArrow"
          />
        </div>
      </div>
      <div
        className="d-flex gap-3 cursor-pointer px-4 pb-2"
        onClick={() => signOutUser()}
      >
        <img className="account-icons" src={logoutIcon} alt="logoutIcon" />
        <p className="account-text  my-auto">Logout</p>
      </div>
    </div>
  )
}

export default AccountDetailsMobileView
