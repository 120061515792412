import ReactGA from 'react-ga4';

let loginStartTime: number | null = null; // To store the login timestamp
const MEASUREMENT_ID = import.meta.env.VITE_MEASUREMENT_ID;
const MEASUREMENT_ID1 = import.meta.env.VITE_MEASUREMENT_ID1;
// Initialize Google Analytics
export const initializeGA = (trackingId: string): void => {
  ReactGA.initialize(
      [{ trackingId: MEASUREMENT_ID }, //Test tracking
      { trackingId: MEASUREMENT_ID1 }, //Test tracking
      {trackingId: trackingId}]);
};

// Generic event tracking function
export const trackEvent = (category: string, action: string, label?: string, value?: number): void => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

// Specific event functions
export const trackPageView = (pagePath: string): void => {
  ReactGA.send({ hitType: 'pageview', page: pagePath });
};

export const trackButtonClick = (buttonName: string): void => {
  trackEvent('Button', 'Click', buttonName);
};

export const trackFormSubmit = (formName: string): void => {
  trackEvent('Form', 'Submit', formName);
};

export const trackCustomEvent = (category: string, action: string, label?: string, value?: number): void => {
  trackEvent(category, action, label, value);
};

export const trackLoginEvent = (): void => {
    loginStartTime = Date.now(); // Record the login time
    trackEvent('User', 'Login');
  };
  
  // Track when a user logs out and calculate the session duration
  export const trackLogoutEvent = (): void => {
    if (loginStartTime) {
      const sessionDuration = Math.round((Date.now() - loginStartTime) / 1000); // Duration in seconds
      trackEvent('User', 'Logout', 'Session Duration', sessionDuration);
      console.log(`User session duration: ${sessionDuration} seconds`); // Optional: Log for debugging
    }
    loginStartTime = null; // Reset the login time
  };

export const trackSignUpEvent = (): void => {
  trackEvent('User', 'Sign Up');
};
