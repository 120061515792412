import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { io, Socket } from 'socket.io-client';
import { APP_ENV } from '../../constants';
const BASE_URL = APP_ENV === 'development'
  ? import.meta.env.VITE_REACT_Dev_API_URL
  : ['testing'].includes(APP_ENV as string)
  ? import.meta.env.VITE_REACT_TEST_API_URL
  : import.meta.env.VITE_REACT_APP_API_URL;

export const socketApi = createApi({
    reducerPath: "socketApi",
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        connectSocket: builder.query<any[], string | undefined | null>({
            queryFn: () => ({ data: [] }),
            async onCacheEntryAdded(
                token: string | undefined,
                { updateCachedData, cacheEntryRemoved }
            ) {
                if (!token) {
                    console.warn('No token provided. Socket.io connection will not be established.');
                    return;
                }

                const url = `${BASE_URL}/base?token=${token}`;

                // Initialize the Socket.io client
                const socket: Socket = io(url);

                const handleActiveJobsData = (data: any) => {
                    console.warn('Received active-jobs-data:', data);
                    updateCachedData((draft) => {
                        draft.push({ type: 'active-jobs', data });
                    });
                };

                const handleNotifications = (data: any) => {
                    console.warn('Received notifications:', data);
                    updateCachedData((draft) => {
                        draft.push({ type: 'notification', data });
                    });
                };

                try {
                    socket.on('connect', () => {
                        console.warn('Socket connection established.');
                    });

                    socket.on('active-jobs-data', handleActiveJobsData);
                    socket.on('notifications', handleNotifications);

                    socket.on('connect_error', (error) => {
                        console.error('Socket connection error:', error);
                    });

                    socket.on('disconnect', (reason) => {
                        console.warn('Socket connection closed:', reason);
                    });

                } catch (error) {
                    console.error('Socket exception:', error);
                }

                // Cleanup when the cache entry is removed
                await cacheEntryRemoved;
                socket.disconnect();
                console.warn('Socket connection closed due to cache entry removal.');
            },
        }),
    }),
});

export const { useConnectSocketQuery } = socketApi;
