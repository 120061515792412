import React from 'react';
import styles from "./style.module.css"

const AchievementStats = () => {
    const stats = [
      {
        icon: (
          <svg viewBox="0 0 24 24" className={styles.icon}>
            <path
              fill="currentColor"
              d="M12 2C13.1 2 14 2.9 14 4C14 5.1 13.1 6 12 6C10.9 6 10 5.1 10 4C10 2.9 10.9 2 12 2M15.9 8.1C15.5 7.7 14.8 7 13.5 7H10.5C9.2 7 8.5 7.7 8.1 8.1C7.7 8.5 7 9.2 7 10.5V13.5C7 14.8 7.7 15.5 8.1 15.9C8.5 16.3 9.2 17 10.5 17H13.5C14.8 17 15.5 16.3 15.9 15.9C16.3 15.5 17 14.8 17 13.5V10.5C17 9.2 16.3 8.5 15.9 8.1M12 15C10.3 15 9 13.7 9 12C9 10.3 10.3 9 12 9C13.7 9 15 10.3 15 12C15 13.7 13.7 15 12 15Z"
            />
          </svg>
        ),
        value: '55+',
        label: 'Expert Sources',
      },
      {
        icon: (
          <svg viewBox="0 0 24 24" className={styles.icon}>
            <path
              fill="currentColor"
              d="M13 3C16.9 3 20 6.1 20 10C20 12.8 18.4 15.2 16 16.3V21H9V18H8C6.9 18 6 17.1 6 16V13H4V11H6V8H4V6H6V7H8V4H10V7H12V4H13M13 5C11.9 5 11 5.9 11 7V8H15V7C15 5.9 14.1 5 13 5Z"
            />
          </svg>
        ),
        value: '55+',
        label: 'Vedic Services',
      },
      // {
      //   icon: (
      //     <svg viewBox="0 0 24 24" className={styles.icon}>
      //       <path
      //         fill="currentColor"
      //         d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
      //       />
      //     </svg>
      //   ),
      //   value: '50+',
      //   label: 'Countries Connected',
      // },
      {
        icon: (
          <svg viewBox="0 0 24 24" className={styles.icon}>
            <path
              fill="currentColor"
              d="M12,2L1.5,9.64L5.5,22H18.5L22.5,9.64L12,2M17,20H7L3.85,10.4L12,4.47L20.15,10.4L17,20Z"
            />
          </svg>
        ),
        value: '55+',
        label: 'Countries Connected',
      },
    ];
  
    return (
      <div className={styles.achievementWrapper}>
        <div className={styles.container}>
          <div className={styles.statsGrid}>
            {stats.map((stat, index) => (
              <div key={index} className={styles.statCard}>
                <div className={styles.iconWrapper}>
                  {stat.icon}
                  <div className={styles.iconHoverEffect} />
                </div>
                <h3 className={styles.statValue}>{stat.value}</h3>
                <p className={styles.statLabel}>{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default AchievementStats;