import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styles from './style.module.css';
import './carosol.css';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // For tablets
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // For mobile
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const EmpoweringTrainers = () => {
  return (
    <section className={styles.empoweringSection}>
      <div className={styles.sectionHeader}>
        <span className={styles.subTitle}>Our services</span>
        <div className={styles.titleWrapper}>
          <span className={styles.decorDot}></span>
          <h2 className={styles.title}>
            VedicHom – Where Timeless Wisdom Meets Today’s World.
          </h2>
        </div>
      </div>

      <div className={styles.trainersCarousel}>
        <Slider {...sliderSettings}>
          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/person-practicing-tai-chi-indoors_23-2150218266.jpg"
                alt="YOGA"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>YOGA</h3>
              <p className={styles.trainerRole}>Yoga & Meditation</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/woman-yoga-mat-relax-park-mountain-lake-calm-woman-with-closed-eyes-practicing-yoga-sitting-padmasana-pose-mat-lotus-exercise-attractive-sporty-girl-sportswear_1150-44679.jpg?t=st=1733558193~exp=1733561793~hmac=2316c8e32983fb85cfbbccdca7f43323adb9982de70ccbad02270f86679ca59e&w=740"
                alt="Meditation & Well-being"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>MEDITATION AND WELL-BEING</h3>
              <p className={styles.trainerRole}>Meditation & Well-being</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/flat-lay-herbs-spices-medicinal-purpose_23-2148776520.jpg?t=st=1733558322~exp=1733561922~hmac=caf62948bfed54353414b404519d1e467ca3fd6da34dc33be85078d055f1cf6b&w=740"
                alt="Ayurveda & Healing"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>AYURVEDA - WELLNESS AND HEALING PRACTICES</h3>
              <p className={styles.trainerRole}>Ayurveda & Healing</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/calm-woman-with-closed-eyes-practicing-yoga-lotus-position_1150-42537.jpg?t=st=1733558526~exp=1733562126~hmac=8e0e82d3e02ecb142d500789ea9acd990d72b9ebe7b491222d8947d775d42538&w=740"
                alt="Mindfulness & Psychology"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>MINDFULNESS AND PSYCHOLOGY</h3>
              <p className={styles.trainerRole}>Mindfulness & Psychology</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/portrait-indian-man-celebrating-baisakhi-festival_23-2151216935.jpg"
                alt="Art & Music"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>ART AND MUSIC</h3>
              <p className={styles.trainerRole}>Art & Music</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/group-friends-exploring-traditional-marketplace-medium-shot_1258-289111.jpg?t=st=1733559522~exp=1733563122~hmac=6dc87d419c89ee56831c5419c4715a6401019a5fdd6c9550ef45878dbac0f7c1&w=740"
                alt="Cultural Promotion"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>VEDIC CULTURAL PROMOTION AND PRESERVATION</h3>
              <p className={styles.trainerRole}>Cultural Promotion</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/numerology-concept-still-life_23-2150171517.jpg?t=st=1731935659~exp=1731939259~hmac=a2e4653ecbbd0e1dfc0093fa686c95a3c5f99f6a7dc9266523d10f23c0b95ad7&w=360"
                alt="Vastu Shastra & Architecture"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>VEDIC ARCHITECTURE - VASTU SHASTRA</h3>
              <p className={styles.trainerRole}>Vastu Shastra & Architecture</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/thai-style-buddha-sculpture-concept_53876-23387.jpg?t=st=1731936211~exp=1731939811~hmac=c1f7c6aa2cfba5bd6027eb9c936eb5550143275c4c8072adf5eb6ddec201f87b&w=360"
                alt="Spiritual Discourse"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>SANKIRTAN AND KATHA - SPIRITUAL DISCOURSE</h3>
              <p className={styles.trainerRole}>Spiritual Discourse</p>
            </div>
          </div>

          <div className={styles.trainerCard}>
            <div className={styles.imageWrapper}>
              <img
                src="https://img.freepik.com/free-photo/numerology-concept-still-life_23-2150171495.jpg?t=st=1733559370~exp=1733562970~hmac=2dfc86777ba559f630c27f2f4f7c421c9c76b21635163860a57537164f436e89&w=740"
                alt="Vedic Sciences & Education"
                className={styles.trainerImage}
              />
            </div>
            <div className={styles.trainerInfo}>
              <h3 className={styles.trainerName}>VEDIC SCIENCES, STUDY AND EDUCATION</h3>
              <p className={styles.trainerRole}>Vedic Sciences & Education</p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default EmpoweringTrainers;
