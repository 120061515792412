import React, { useEffect, useLayoutEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Modal } from "react-bootstrap";  
import styles from "./MultiStepForm.module.css";
import { useLocation } from "react-router-dom";
import Step1ProfilePicture from "./Step1ProfilePicture";
import Step2Bio from "./Step2Bio";
import Step3Certifications from "./Step3Certifications";
import Step7Completion from "./Step7Completion";
import Step6AccountVerification from "./Step6AccountVerification";
import Step4PostalAddress from "./Step4PostalAddress";
import Step5GeneralInformation from "./Step5GeneralInformation";
import Step3IdDoc from "./Step3IdDoc";
import { boolean } from "yup";
import { IoCheckmarkDoneOutline } from "react-icons/io5";

const CompleteProfileFormModal = ({ showModal, handleClose }: { showModal: boolean, handleClose: () => void }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const [isStepComplete, setIsStepComplete] = useState<Record<string, boolean>>({}); // Step validation state

  const validateStep = (stepKey: string, isValid: boolean) => {
    setIsStepComplete((prev) => ({ ...prev, [stepKey]: isValid }));
  };

  const steps = [
    {
      key: 'profilePicture',
      name: "Profile Picture",
      component: Step1ProfilePicture,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: 'bio',
      name: "Bio",
      component: Step2Bio,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: 'certifications',
      name: "Certifications",
      component: Step3Certifications,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: "identification",
      name: "Identification",
      component: Step3IdDoc,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: 'postalAddress',
      name: "Postal Address",
      component: Step4PostalAddress,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: 'generalInformation',
      name: "General Information",
      component: Step5GeneralInformation,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key: 'accountVerification',
      name: "Account Verification",
      component: Step6AccountVerification,
      props: { onSuccess: setCurrentStep, isComplete: validateStep },
    },
    {
      key:'socialMedia',
      name: "Social Media",
      component: Step7Completion,
      props: { onSuccess: setCurrentStep, handleClose },
    },
  ];
  useLayoutEffect(() => {
    // Initialize validation states for all steps as false
    const initialValidationState = steps.reduce(
      (acc, step) => ({ ...acc, [step.key]: false }),
      {}
    );
    setIsStepComplete(initialValidationState);
  }, []);

  useEffect(() => {
    handleClose();
  }, [location]);

  // Get the component and props for the current step
  const { component: CurrentStepComponent, props } = steps[currentStep - 1];

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Body className="p-0">
          <div className={styles.formContainer}>
            <Modal.Header className={styles.formContainerHeader} closeButton />
            <p className="fs-4 fw-bold text-center text-primary-vedichom">{steps[currentStep - 1].name}</p>

            <div className={styles.stepsIndicator}>
              {steps.map((step, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentStep(index + 1)}
                  className={`${styles.step} ${currentStep > index + 1 ? styles.completed : ""} ${currentStep === index + 1 ? styles.active : ""}`}
                >
                  <div className={styles.stepNumber}>
                    {currentStep > index + 1 ? (isStepComplete[step.key]?<IoCheckmarkDoneOutline className={styles.checkIcon} />:<AiOutlineCheckCircle className={styles.checkIcon} />) : index + 1}
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.form}>
              {/* Dynamically pass the props to the current step component */}
              <CurrentStepComponent {...props} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompleteProfileFormModal;
