import React, { useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import styles from './MultiStepForm.module.css';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { BiSolidFilePdf } from 'react-icons/bi';
import { OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { MdCloudDone } from 'react-icons/md';
import { convertFileSize } from '../../utils/commonFunctions/convertFileSize';
import { toast } from 'react-toastify';
import { useUploadProfileDocumentsMutation } from '../../features/featProviderDashboard/FeatProviderApi';
import { extractFilename } from '../../utils/commonFunctions/getFileDisplayName';
import { useUploadDocumentsMutation } from '../../features/featSeekerDashboard/seekerDashboardApi';
interface CertificationFile {
  name: string
  file: File
  localOnly: boolean
  url?: string
}
interface FormData {
  image: File | null
  bio: string
  certifications: CertificationFile[]
  socialMedia: {
    twitter: string
    linkedin: string
    github: string
  }
}
const Step3Certifications = ({ onSuccess, isComplete, stepKey }: any) => {
  const { user, completion, seekerToken, providerToken, currentUserRole } = useAppSelector(selectCurrentUser)

  const [formData, setFormData] = useState<FormData>({
    image: null,
    bio: "",
    certifications: [],
    socialMedia: {
      twitter: "",
      linkedin: "",
      github: "",
    },
  })
  const [updateDocument,
    { isLoading: loadingDocument, error: documentError }
  ] = providerToken ? useUploadProfileDocumentsMutation() : useUploadDocumentsMutation();
  const [replacedDocs, setReplacedDocs] = useState<string>('')
  const [isUploadingDocs, setIsUploadingDocs] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [fileSize, setFileSize] = useState("");
  function handleDocumentUpdate(): void {

    const payload = new FormData();
    let hasUpdates = false;

    formData.certifications.forEach((cert) => {
      if (cert.localOnly) {
        payload.append('certificate', cert.file);
        hasUpdates = true;
      }
    });
    if (replacedDocs) {
      payload.append('certiUrl', replacedDocs);
      hasUpdates = true;
    }
    if (!isUploadingDocs) {
      onSuccess(4)
      return
    }
    updateDocument({ data: payload }).unwrap()
      .then((res: any) => {
        toast.success("Your documents updated successfully")
        setReplacedDocs('')
        onSuccess(4)
        setIsUploadingDocs(false)
      })
      .catch((err: any) => {
        toast.error("Failed to upload documents")
      });
  }
  const handleCertificationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    if (formData.certifications.length >= 3) {
      setErrorMessage("You can only upload up to 3 certifications.");
      return;
    }

    const files = e.target.files;

    if (files) {
      const currentCertificationsCount = formData.certifications.length;
      const filesArray = Array.from(files);

      // Check for duplicates
      const hasDuplicates = filesArray.some((file) =>
        formData.certifications.some(
          (certification) => certification?.file?.name === filesArray?.[0].name // Adjust if using other properties
        )
      );

      const nonPdfFiles = filesArray.filter((file) => file.type !== "application/pdf");
      const oversizedFiles = filesArray.filter((file) => file.size > MAX_FILE_SIZE);

      // If duplicates found, return
      if (hasDuplicates) {
        setErrorMessage("Duplicate files detected. Aborting upload.");
      }
      else if (currentCertificationsCount + filesArray.length > 3) {
        setErrorMessage("You can only upload up to 3 certifications.");
      }
      else if (nonPdfFiles.length > 0) {
        setErrorMessage("Only PDF files are allowed.");
      }
      else if (oversizedFiles.length > 0) {
        setFileSize(
          `Some files exceed the size limit of ${MAX_FILE_SIZE / (1024 * 1024)} MB.`
        );
      }
      else {
        setErrorMessage('')
        setFileSize("");

        const newCertifications = filesArray.map((file) => ({
          name: "",
          file,
          localOnly: true
        }));

        setFormData({
          ...formData,
          certifications: [...formData.certifications, ...newCertifications].slice(0, 3),
        });
        setIsUploadingDocs(true);
      }

    }
  };
  useEffect(() => {
    if (user?.certification && user?.certification?.length > 0) {
      const certifications = user?.certification.map((certificate: string) => {
        return {
          name: extractFilename(certificate, user?._id),
          file: certificate,
          url: certificate,
          localOnly: false
        };
      });
      setFormData((prev) => ({ ...prev, certifications }))
    }
  }, [user])
  return (
    <div className={styles.formStep}>
      <h2>Upload Your Certifications</h2>
      <p className={styles.subtitle}>
        Add your professional certifications and achievements
      </p>
      <div className={`${styles.certificationsContainer}`}>
        <div className={`${styles.pdfContainer} custom-scrollbar`}>

          {formData.certifications.map((cert: any, index) => (
            <div key={index} className={styles.certificationItem}>
              <BiSolidFilePdf

                className={`${styles.fileIcon} `}
              />
              <div className={`${styles.certDetails} d-flex flex-row justify-content-center align-items-center`}>
                {cert.localOnly
                  ? <span className={styles.pill_text}><FaUpload /></span>
                  : <span className={styles.pill_text_done}><MdCloudDone /></span>}
                {cert.localOnly ?
                  <span className={styles.fileName}>
                    <OverlayTrigger placement="top" trigger={'hover'} overlay={<Popover><PopoverBody>{cert.file.name}</PopoverBody></Popover>} >
                      <span>{cert.file.name}</span></OverlayTrigger>
                    <br />
                    <span className="px-1">{convertFileSize(cert.file.size, "Bytes", "KB")}<span className="text-dark fw-bold">{' KB'}</span></span>
                  </span>
                  :
                  <OverlayTrigger placement="top" trigger={'hover'} overlay={<Popover><PopoverBody>{cert.name as any as string}</PopoverBody></Popover>} >
                    <span className={styles.fileName}>
                      {cert.name as any as string}
                    </span>
                  </OverlayTrigger>}
              </div>
              <button
                type="button"
                title={cert.localOnly ? 'Remove' : 'Delete from our record'}
                onClick={() => {
                  if (!cert.localOnly) {
                    setIsUploadingDocs(true)
                    setReplacedDocs((prev) => {
                      const newRemovedDocs = prev ? [prev, cert.url].join(',') : cert.url;
                      return newRemovedDocs || '';
                    })
                  }
                  const newCerts = formData.certifications.filter(
                    (_, i) => i !== index,
                  )
                  setFormData({
                    ...formData,
                    certifications: newCerts,
                  })
                  setErrorMessage("")
                }}
                className={styles.removeCertButton}
              >
                <IoMdClose />
              </button>
            </div>
          ))}
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {fileSize && <p style={{ color: "red" }}>{fileSize}</p>}
        <div className={styles.uploadCertification}>
          <label className={styles.uploadLabel}>
            <AiOutlineCloudUpload className={styles.uploadIcon} />
            <span>Add Certification</span>
            <input
              type="file"
              accept=".pdf"
              onChange={handleCertificationUpload}
              className={`${styles.fileInput} d-none`}
              multiple={true}
            />
          </label>
          <p className={styles.fileHint}>
            Supported formats: PDF
          </p>
        </div>
      </div>


      <div className={styles.buttonGroup}>
        <button
          type="button"
          onClick={() => onSuccess(2)}
          className={styles.backButton}
        >
          Back
        </button>
        <button
          type="button"
          onClick={
            handleDocumentUpdate
          }
          className={styles.nextButton}
        >
          {isUploadingDocs ? 'Save & Continue' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default Step3Certifications;
