import React from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

interface Props {
    path?: string;
    class_name?: string
    onClick?: any
}

const CustomBackNavButton: React.FC<Props> = ({ path, class_name, onClick }) => {
    const navigate = useNavigate();
    
    const handleNavigate = () => {
        if (typeof path === 'string') {
            navigate(path);
        } else {
            navigate(-1);
        }
        if(onClick) onClick()
    };
    
    return (
        <span className={`${styles['custom-back-icon']} ${styles['custom-bg-yellow']} ${class_name ? class_name : 'mb-2 mb-md-3'}`} onClick={handleNavigate}>
            <IoArrowBackOutline />
        </span>
    );
};

export default CustomBackNavButton;
